<template>
    <t-select v-model="tag" :options="tags" options-label="label" options-key="id"
        :options-value="x => (x ? { id: x.id } : undefined)" multiple nullable inline :placeholder="$t('page.settings_activity.section_tags.select.placeholder')">

        <template v-slot:input="{ value }">
            <span class="my-1">
                <t-tag :tag="value" class="-my-0.5 pr-2" />
            </span>
        </template>

        <template v-slot="{ opt }">
            <div class="py-1 bg-white cursor-pointer">
                <t-tag :tag="opt" class="-my-0.5 pr-2" hover />
            </div>
        </template>

        <template v-slot:end="{ active }">
            <div class="w-full block py-2 px-3 cursor-pointer"
                :class="{ 'bg-ocean text-white': active, 'text-ocean': !active }" @click="addNew()">
                <t-icon-plus class="h-4 w-4 inline-block" /> {{ $t('page.settings_activity.section_tags.select.add') }}
            </div>
        </template>

    </t-select>
</template>

<script lang="ts">
import { defineComponent, computed, ref, PropType } from 'vue';

import { ICompanyLabel, IContactLabel, IProjectLabel } from '@tolemac/grpc_web_api/tolemac/pub/model';

import plugins from '@/plugins';

type Label = ICompanyLabel | IContactLabel | IProjectLabel

export default defineComponent({
    name: 't-tag-select',
    props: {
        modelValue: Array as PropType<Label[]>,
        type: { type: String as PropType<'contact' | 'company' | 'project'>, required: true }
    },
    mixins: [] as unknown as [GlobalComponentsMixin<'TSelect'>],
    setup(props, { emit }) {
        const { $api, util } = plugins

        const tags = ref<Label[]>([])

        async function load() {
            tags.value = await (
                props.type === 'company' ? $api.companyLabel.find({}) :
                    props.type === 'contact' ? $api.contactLabel.find({}) :
                        $api.projectLabel.find({})
            ).then(v => v.values)
        }

        load()

        return {
            tag: computed({
                get() { return props.modelValue },
                set(_tag?: Label[]) { emit('update:modelValue', _tag) }
            }),
            tags,
            async addNew() {
                const newIds = await util.tags.openModalAdd(props.type)
                if (newIds) {
                    await load()
                    // tags without deleted tags 
                    const _tags = (props.modelValue || []).filter(t => tags.value.find(_t => _t.id === t.id))

                    // add new tags 
                    if (Array.isArray(newIds) && newIds.length) {
                        const newTags = newIds
                            .map(newId => tags.value.find(t => t.id === newId))
                            .filter(l => !!l) as Label[]
                        emit('update:modelValue', _tags.concat(...newTags))
                    } else if ((props.modelValue || []).length !== _tags.length) {
                        emit('update:modelValue', _tags)
                    }
                }
            }
        }
    }

})
</script>