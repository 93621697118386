<template>
  <span :class="['px-2 py-0.5 text-center inline-block t-bg-right-chevron whitespace-nowrap', color]">
    {{ $enum.InvoiceStatus.translate($props.status) }}
  </span>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

import { Invoice } from '@tolemac/grpc_web_api/tolemac/pub/model'

export default defineComponent({
  name: 't-invoice-status',
  props: {
    status: Number as PropType<Invoice.InvoiceStatus>
  },
  computed: {
    color() {
      switch (this.$props.status) {
        case this.$enum.InvoiceStatus.SCHEDULED: return 'bg-purple-100 text-purple-800'
        case this.$enum.InvoiceStatus.DRAFT: return 'bg-yellow-100 text-yellow-800'
        case this.$enum.InvoiceStatus.DELIVERED: return 'bg-green-100 text-green-800'
        case this.$enum.InvoiceStatus.DELIVERED__LATE: return 'bg-red-100 text-red-800'
        //case this.$enum.InvoiceStatus.ARCHIVED: return ''
        default: return 'bg-gray-100'
      }
    }
  }
})
</script>