<template>
  <div class="m-auto p-2 w-full md:w-2/3 2xl:w-1/2 bg-white rounded-md overflow-y-auto">
    <template v-if="loaded">
      <div class="px-2 md:px-5">
        <h2 class="text-lg font-medium">
          {{
      isIndividual ?
        company.id ? $t('section.company.title_edit_ind') : $t('section.company.title_add_ind')
        :
        company.id ? $t('section.company.title_edit_pro') : $t('section.company.title_add_pro')
    }}
        </h2>
        <p class="text-gray-500 pb-5">
          {{ $t('section.company.description') }}
        </p>

        <form action="" class="grid grid-cols-1 md:grid-cols-2 gap-2">

          <template v-if="isIndividual">
            <t-input v-model="company.firstname" :validation="company$.firstname" type="text"
              :placeholder="$t('model.company.firstname')" />
            <t-input v-model="company.lastname" :validation="company$.lastname" type="text"
              :placeholder="$t('model.company.lastname')" />
          </template>

          <template v-else>
            <t-input v-model="company.name" :validation="company$.name" type="text"
              :placeholder="$t('model.company.name')" />
            <t-select class="col-start-1" v-model="company.activitySector" :validation="company$.activitySector"
              :options="$enum.CompanyActivitySector.values" :options-label="$enum.CompanyActivitySector.translate"
              nullable :nullable-value="0" :placeholder="$t('model.company.activitySector')" />
            <t-input v-model="company.legalForm" :validation="company$.legalForm" type="text"
              :placeholder="$t('model.company.legalForm')" />
          </template>

          <div v-if="!company.id" class="flex">
            <t-switch v-model="company.prospect" :title="$t('actions.prospect')"
              :on="$enum.CompanyProspect.LEAD_TARGETED" :off="$enum.CompanyProspect.UNDEFINED_CompanyProspection"
              size="s" />
          </div>

          <t-input-email v-model="company.email" :validation="company$.email" :placeholder="$t('model.company.email')"
            class="col-start-1" />
          <t-input-tel v-model="company.phone" :validation="company$.phone" :placeholder="$t('model.company.phone')" />

          <t-input-url v-model="company.website" :validation="company$.website"
            :placeholder="$t('model.company.website')" />

          <t-select v-if="isIndividual" class="col-start-1" v-model="company.activitySector"
            :validation="company$.activitySector" :options="$enum.CompanyActivitySector.values"
            :options-label="$enum.CompanyActivitySector.translate" nullable :nullable-value="0"
            :placeholder="$t('model.company.activitySector')" />

          <TagSelect class="col-start-1 col-span-full" type="company" v-model="company.labels" />

          <hr class="col-span-full mt-4" />
          <div class="col-span-full py-2">
            {{ $t('model.company.part_coordinates') }}
          </div>

          <t-input v-model="company.addressLine1" :validation="company$.addressLine1" type="text"
            :placeholder="$t('model.company.addressLine1')" />
          <t-input v-model="company.addressLine2" :validation="company$.addressLine2" type="text"
            :placeholder="$t('model.company.addressLine2')" />

          <t-input v-model="company.postalCode" :validation="company$.postalCode" type="text"
            :placeholder="$t('model.company.postalCode')" />
          <t-input v-model="company.city" :validation="company$.city" type="text"
            :placeholder="$t('model.company.city')" />

          <t-input v-model="company.state" :validation="company$.state" type="text"
            :placeholder="$t('model.company.state')" />
          <t-input v-model="company.country" :validation="company$.country" type="text"
            :placeholder="$t('model.company.country')" />

          <hr class="col-span-full mt-4" />
          <div class="col-span-full py-2">
            {{ $t('model.company.part_settings') }}
          </div>

          <t-input v-model="currentPaymentDeadline.deadline" min="0" type="number" :disabled="paymentDeadline.default"
            :end="$formatter.unit.plural('day')" :placeholder="$t('model.company.deadline')" />

          <div class="flex justify-between gap-x-4">
            <div class="flex items-center justify-end md:justify-start pt-2">
              <input id="end_of_month" type="checkbox" v-model="currentPaymentDeadline.endOfMonth"
                :disabled="paymentDeadline.default" />
              <label for="end_of_month" class="pl-2">{{ $t('model.company.end_of_month') }}</label>
            </div>

            <div class="flex items-center justify-end md:justify-start pt-2">
              <input id="deadline_default" type="checkbox" v-model="paymentDeadline.default" />
              <label for="deadline_default" class="pl-2 cursor-pointer">
                {{ $t('model.company.use_default') }}
              </label>
            </div>
          </div>

          <TaxSelect v-model="taxRate" :placeholder="$t('section.company.tax')" :disabled="taxeRateDefault"
            :options-value="(x: ITaxRate) => ({ id: x.id })" />
          <div class="flex items-center justify-end pt-2">
            <input id="taxRate_default" type="checkbox" v-model="taxeRateDefault" />
            <label for="taxRate_default" class="pl-2 cursor-pointer">
              {{ $t('model.company.use_default') }}
            </label>
          </div>

        </form>

      </div>
      <div class="flex flex-row flex-grow-0 gap-2 px-5 pt-5">
        <t-button v-vuelidate="() => ({ click: save, validation: company$, unchanged: company?.id === undefined })">
          {{ $t('actions.save') }}
        </t-button>
        <t-button color="white" @click="forceDismiss()">
          {{ $t('actions.cancel') }}
        </t-button>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, reactive, computed, watch, PropType, Ref } from 'vue'

import { Company, CompanyLabel, ICompany, ITaxRate, TaxRate } from '@tolemac/grpc_web_api/tolemac/pub/model';

import plugins from '@/plugins'
import { useValidation } from '@/composition/vuelidate'
import { handle404ErrorForModal } from '@/composition/captureError404'

import TagSelect from '@/components/tags/TagSelect.vue'
import TaxSelect from '@/components/taxes/TaxSelect.vue'

export default defineComponent({
  name: 't-company-edit-modal',
  components: { TagSelect, TaxSelect },
  props: {
    companyId: Number,
    company: Object as PropType<ICompany>
  },
  async setup(props) {
    const { $api, toast, $store, $t, $modal, util: { validators: { company: companyValidator } } } = plugins;

    const isIndividual = ref(props.company?.type === Company.CompanyType.INDIVIDUAL)

    const labels = ref<CompanyLabel[]>([])
    $api.companyLabel.find({}).then(v => labels.value = v.values)

    const taxes = ref<(TaxRate)[]>([])
    $api.taxRate.find({}).then(v => taxes.value = v.values)

    const company$args = ref(companyValidator.init({ isIndividual: isIndividual.value }))
    const { refObj: company, validationObj: company$, setVal: company$set } = useValidation(
      null as ICompany | null,
      company$args
    )

    $modal.addLock(company$.value.$uid)

    const paymentDeadline = reactive({
      orga: {
        deadline: $store.account.user!.orga.invoiceParam.deadline,
        endOfMonth: $store.account.user!.orga.invoiceParam.deadlineEndMonth
      },
      company: {
        deadline: undefined as number | undefined,
        endOfMonth: undefined as boolean | undefined
      },
      default: true
    })

    const taxeRateDefault = ref(false)

    const companyName = ref()
    const loadCompany = handle404ErrorForModal(async (companyId?: number) => {
      const c = companyId ?
        await $api.company.get({ id: props.companyId, relations: ['@mainContact', '@labels', '@taxRate'] })
        :
        {
          ...(props.company || {}),
          prospect: props.company?.prospect || Company.CompanyProspection.UNDEFINED_CompanyProspection,
          type: props.company?.type || Company.CompanyType.INDIVIDUAL,
          labels: props.company?.labels || []
        }
      companyValidator.update(company$args.value, { isIndividual: c.type === Company.CompanyType.INDIVIDUAL })
      if (c.type === Company.CompanyType.INDIVIDUAL) {
        isIndividual.value = true
      }
      paymentDeadline.company.deadline = c.paymentDeadline || paymentDeadline.orga.deadline
      paymentDeadline.company.endOfMonth = c.paymentDeadlineEndMonth
      paymentDeadline.default = c.id === undefined || c.paymentDeadline === 0

      taxeRateDefault.value = !c.taxRate

      companyName.value = c.name

      company$set(c)
    })


    watch(paymentDeadline, _pDeadline => {
      if (!company.value) return
      if (_pDeadline.default) {
        company.value.paymentDeadline = 0;
        company.value.paymentDeadlineEndMonth = false;
      } else {
        company.value.paymentDeadline = _pDeadline.company.deadline || _pDeadline.orga.deadline
        company.value.paymentDeadlineEndMonth = _pDeadline.company.endOfMonth
      }
    })

    watch(taxeRateDefault, _tDefault => {
      if (!company.value) return

      if (_tDefault) {
        company.value.taxRate = undefined
      } else {
        company.value.taxRate = taxes.value.find(_t => _t.default)
      }
    })

    return {
      loaded: await loadCompany(props.companyId),

      company: company as Ref<ICompany>,
      company$,

      paymentDeadline,
      taxeRateDefault,
      isIndividual,
      companyName,

      taxRate: computed({
        get() {
          return taxeRateDefault.value ? taxes.value.find(_t => _t.default) : company.value?.taxRate
        },
        set(t?: TaxRate) {
          if (company.value && t && !taxeRateDefault.value)
            company.value.taxRate = t
        }
      }),

      currentPaymentDeadline: computed(() =>
        paymentDeadline.default ? paymentDeadline.orga : paymentDeadline.company
      ),


      labels,
      taxes,

      forceDismiss: $modal.forceDismiss,

      async save() {
        let c = company.value!
        const isNew = c.id === undefined
        if (c.id === undefined)
          c = await $api.company.add(c);
        else
          await $api.company.update(c)
        toast.info(isNew ? $t('section.company.toast.saved') : $t('section.company.toast.updated'))
        $modal.forceDismiss(await $api.company.get({ id: c.id! }))
      },
    }
  }
})
</script>