<template>
  <IonButton v-bind="ionArgs" ref="button">
    <!-- <div class="relative w-full"
      :class="{ 'px-6': !round, 'px-2': round, 't-button-loading': clicking || clickingHandle }"> -->
    <span class="flex items-center justify-center"
      :class="{ 'px-6': !round, 'px-2': round, 't-button-loading': clicking || clickingHandle }">
      <slot />
      <t-icon-arrow-path v-if="!round" class="t-button-loading-icon" aria-hidden="true" />
    </span>
  </IonButton>
</template>

<script lang="ts">
import { defineComponent, ref, computed, PropType, onMounted, watch } from 'vue';
import { RouteLocationRaw } from 'vue-router';
import { IonButton } from '@ionic/vue';

import plugins from '@/plugins';
import { autoFocus } from "@/composition/autoFocus";

type IonButtonProps = Writeable<InstanceType<typeof IonButton>["$props"] & { onClick?: any; class: string[] }>

export default defineComponent({
  name: 't-button',
  inheritAttrs: false,
  components: { IonButton },
  // mixins: [] as unknown as [typeof IonButton],
  // mixins: [] as unknown as [DefineComponent<ButtonHTMLAttributes>],
  props: {
    color: String as PropType<'primary' | 'secondary' | 'tertiary' | 'white' | 'red' | 'dashed' | 'gray'>,

    href: [String, Object] as PropType<string | RouteLocationRaw>,
    /** if href use router, skip dirty locks */
    hrefUnlock: Boolean,

    /** hide and disabled */
    invisible: Boolean,

    round: Boolean,

    onClick: { type: Function, default: () => undefined },

    clicking: Boolean,

    disabled: Boolean,

    size: String as PropType<'small' | 'default' | 'large'>,

    autofocus: Boolean

    // // as
    // buttonType: string;
    // type: 'submit' | 'reset' | 'button';

    // // download a URL 
    // download: string | undefined;
    // target: string | undefined;
    // // Specifies the relationship of the target object to the link object. The value is a space-separated list of [link types](https://developer.mozilla.org/en-US/docs/Web/HTML/Link_types).
    // rel: string | undefined;
    // routerAnimation: AnimationBuilder | undefined;
    // routerDirection: RouterDirection;

    // expand: String as PropType<'full' | 'block'>
    // fill: 'clear' | 'outline' | 'solid' | 'default';

    // strong: boolean;

    // onIonBlur: (event: IonButtonCustomEvent<void>) => void;
    // onIonFocus: (event: IonButtonCustomEvent<void>) => void;
  },
  emits: ['click'],
  setup(props, { attrs }) {
    const { $router } = plugins

    const clickingHandle = ref(false)
    const button = ref<{ $el: HTMLElement }>()

    function _autoFocus() {
      autoFocus(button.value?.$el, elt => {
        elt.classList.add('ion-focused')
        elt.addEventListener('blur', () => elt.classList.remove('ion-focused'), { once: true })
      })
    }

    if (props.autofocus === true)
      onMounted(_autoFocus)
    else if (props.autofocus === false) {
      const unwatchAutofocus = watch(
        () => props.autofocus,
        a => { if (a) { _autoFocus(); unwatchAutofocus() } }
      )
    }

    return {
      button,
      ionArgs: computed<IonButtonProps>(() => {
        const { class: clazz, ...others } = attrs as { class?: string }

        const ionArgs = {
          color: props.color,
          class: ['relative', 't-button'],
          style: attrs.style,
          ...others
        } as IonButtonProps

        // if (!props.size)
        //   ionArgs.size = 'default'

        if (clazz)
          Array.isArray(clazz) ? ionArgs.class.push(...(clazz as any[])) : ionArgs.class.push(clazz as any)

        if (props.invisible) {
          ionArgs.class.push('invisible')
          ionArgs.disabled = true
        }
        if (props.round) {
          ionArgs.shape = 'round';
          // ionArgs.style += '--padding-bottom: 0px; --padding-end: 0px; --padding-start: 0px; --padding-top: 0px'
        }

        ionArgs.disabled =
          props.disabled || clickingHandle.value || props.clicking

        const href = props.href
        const isH = typeof href === 'string' && (
          href.startsWith('mailto:') ||
          href.startsWith('tel:') ||
          href.startsWith('file/')
        )

        if (isH)
          ionArgs.href = href

        ionArgs.onClick = async (event: Event) => {

          if (href) {
            event.preventDefault()
            event.stopPropagation()
          }

          if (props.onClick) try {
            clickingHandle.value = true
            await props.onClick()
          } finally {
              clickingHandle.value = false
            }

          if (href)
            if (isH) {
              window.open(href, '_blank')
            } else if (props.hrefUnlock) {
              $router.push2(href, { unlock: true })
            } else {
              $router.push(href)
            }

        }

        return ionArgs
      }),
      clickingHandle
    }
  }
})
</script>


<style lang="postcss">
.t-button {
  .t-button-loading-icon {
    @apply hidden absolute right-0 top-0 h-full py-2 text-white animate-spin;
  }
}

.t-button-loading,
.t-button-loading-vuelidate {
  @apply opacity-50;

  .t-button-loading-icon {
    @apply block;
  }
}
</style>