import { Plugin, } from 'vue'
import { toastController as ionicToastController, ToastOptions } from '@ionic/vue';
import { close } from 'ionicons/icons'

import plugins from '@/plugins'

const version = 'beta'

export function mailtoSupport(email = '', error: any = '') {

    let link = `mailto:Support - Tolemac<contact-project+tolemac-app-support@incoming.gitlab.com>?subject=Support - Tolemac - Issue&body=- description du problème:
    <veuillez préciser>
    
- adresse email (celle de votre compte tolemac):
    ${email || '<veuillez préciser>'}

- heure: ${new Date().toString()}
- timezone: ${Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone} 
- navigateur: ${navigator?.userAgent}
- lang: ${navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language}
- url: ${window?.location?.href}
- version: ${version || ''}
- écran: ${window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth}w / ${window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight}h`

    // add error
    if (error)
        link += `
- erreur: ${error}`

    //     // add logs
    //     link += `
    // - logs:
    // `

    //     for (const log of issueReport.logs.reverse()) {
    //         if ((link.length + log.length) > 2000) {
    //             link += `    ...
    // `
    //             break
    //         }
    //         link += `    ${log}
    // `
    //     }

    return link.replaceAll('\n', '%0A').replaceAll(' ', '%20')
}

class ToastControllerImpl {

    constructor(private globalOptions: ToastOptions = {}) { }

    async toast(opts: ToastOptions) {
        // remove backend uid
        const errorId = /^\[([0-9]*)\]/.exec(opts.header!)
        if (errorId) opts.header = opts.header!.replace(`[${errorId[1]}] `, '')

        await ionicToastController
            .create({ ...this.globalOptions, ...opts })
            .then(t => t.present())
    }

    /** default duration: 1s */
    async info(options: ToastOptions | string) {
        if (typeof options === "string") options = { header: options }
        await this.toast({ duration: 1000, color: "success", ...options })
    }

    async warn(options: ToastOptions | string, error?: any) {
        await this.warnOrError(true, options, error)
    }

    async error(options: ToastOptions | string, error?: any) {
        await this.warnOrError(false, options, error)
    }

    private async warnOrError(warn: boolean, options: ToastOptions | string, error?: any) {
        if (typeof error === 'object') {
            if (error.isDisplay === true) return
            error.isDisplay = true
        }

        if (typeof options === "string") options = { header: options }
        if (error) options.buttons = [{
            text: 'Signaler un problème',
            handler: () => {
                window.open(mailtoSupport(plugins.$store?.account?.user?.email, error), '_blank')
            }
        }]

        await this.toast({
            duration: warn ? 2000 : 5000,
            color: warn ? "warning" : "danger",
            ...options
        })
    }
}

const toastController = new ToastControllerImpl({
    keyboardClose: true,
    buttons: [{
        icon: close,
        role: 'cancel'
    }]
});

const toastPlugin: Plugin = {
    install: (app) => {
        app.config.globalProperties.$toast = toastController
    }
}

type ToastController = typeof toastController

export {
    toastPlugin,
    toastController,
    ToastController
}