import { Plugin, App } from 'vue'
import { createPinia, Store as StorePinia } from 'pinia'

import { debounce } from '@/composition/debounce';
import { ApiStore } from '@/plugins/api'

import { useStoreDisplay, StoreDisplay, ID_STORE_DISPLAY } from "@/store/store.display";
import { useStoreAccount, StoreAccount, ID_STORE_ACCOUNT } from '@/store/store.account';
import { useStoreNav, ID_STORE_NAV } from '@/store/store.nav';


const saveState = (s: any, key: string) => localStorage.setItem(key, JSON.stringify(s))

const store = {
    get account() {
        return useStoreAccount()
    },
    get display() {
        return useStoreDisplay()
    },
    get nav() {
        return useStoreNav()
    }
}

function MyPiniaPlugin({ store: _store, app }: { store: StorePinia; app: App }) {

    const $api = app.config.globalProperties.$api as ApiStore
    const $router = app.config.globalProperties.$router
    const $cookies = app.config.globalProperties.$cookies

    // inject $router / $api in store
    if (!$api || !$router || !$cookies) throw new Error('$router or $api or $cookies undefined')

    if (_store.$id !== ID_STORE_DISPLAY)
        _store.$subscribe((mutation, _s) => {
            if (import.meta.env.DEV)
                if (!mutation.events) {
                    console.info(`[STORE] Mutate ${mutation.storeId}`)
                } else {
                    const evts = (Array.isArray(mutation.events) ? mutation.events : [mutation.events])
                        .map(e => ([`${e.type} ${e.key}`, e.newValue === undefined ? 'undefined' : JSON.parse(JSON.stringify(e.newValue))]))
                    console.groupCollapsed(`[STORE] Mutate ${mutation.storeId}:`, evts.map(_e => _e[0]).join(','))
                    for (const _e of evts) console.log('\t', _e[0] + ':', _e[1])
                    console.groupEnd()
                }
            // sync state with localstorage
            saveState(_store.$state, _store.$id)
        })

    if (_store.$id === ID_STORE_ACCOUNT) {
        const storeA = _store as StoreAccount
        $api.setStore(storeA)
    }

    else if (_store.$id === ID_STORE_DISPLAY) {
        const storeD = _store as StoreDisplay
        window.addEventListener(
            'resize',
            debounce(() => storeD.resize({
                width: window.innerWidth,
                height: window.innerHeight,
            }), 200),
            false
        )

    }

    return {
        $api,
        $router,
        $cookies,
        reset() {
            localStorage.removeItem(ID_STORE_ACCOUNT)
            localStorage.removeItem(ID_STORE_NAV)
            store.account.$reset()
            store.nav.$reset()

        }
    }
}



export type PiniaCustomProp = ReturnType<typeof MyPiniaPlugin>

const pinia: Plugin = {
    install: (app) => {
        const _pinia = createPinia()
        _pinia.use(MyPiniaPlugin)
        _pinia.install(app)

        app.config.globalProperties.$store = store
        app.provide('store', store)
    }
}

type Store = typeof store

export {
    pinia,
    Store,
    useStoreDisplay,
    useStoreAccount,
    useStoreNav
}