<template>
  <ion-menu content-id="main-content" type="overlay" :disabled="$route.meta.public !== undefined">
    <ion-content>
      <ion-list id="inbox-list">

        <ion-menu-toggle :auto-hide="false" v-for="(p, i) in appPages" :key="i">

          <ion-item v-if="i == 0" lines="none" />

          <template v-if="p.subPages">
            <ion-item router-direction="root" lines="none" :detail="false">
              <ion-label>
                {{ p.title }}
              </ion-label>
            </ion-item>
          </template>
          <template v-else>
            <router-link :to="p.url!" v-slot="{ navigate, isActive }">
              <ion-item router-direction="root" lines="inset" @click="navigate" :detail="false">
                <ion-icon class="mr-3 ionic-force-color-contrast" size="large" v-if="p.icon" :icon="p.icon" />
                <ion-label :class="{ 'font-bold': isActive }">
                  {{ p.title }}
                </ion-label>
              </ion-item>
            </router-link>
          </template>

          <router-link v-for="(s, j) in (p.subPages || [])" :key="j" :to="s.url!"
            v-slot="{ navigate: navigateSub, isActive: isActiveSub }">
            <ion-item router-direction="root" @click="navigateSub"
              :lines="j === (p.subPages || []).length - 1 ? 'inset' : 'none'" :detail="false"
              class="cursor-pointer hydrated pl-2">
              <ion-icon class="mr-3 ionic-force-color-contrast" v-if="s.icon" :icon="s.icon" />
              <ion-label :class="{ 'font-bold': isActiveSub }">
                {{ $t(s.title) }}
              </ion-label>
            </ion-item>
          </router-link>

        </ion-menu-toggle>

        <div class="p-2 text-sm text-right" v-if="$store.account.isAdmin">
          version: {{ version }}
        </div>
      </ion-list>
    </ion-content>
  </ion-menu>
</template>


<script lang="ts">
import { defineComponent, computed } from 'vue'
import { IonMenu, IonList, IonMenuToggle, IonItem, IonLabel, IonIcon } from '@ionic/vue';
import { home, settingsOutline, clipboardOutline, megaphoneOutline, idCardOutline, appsOutline, archiveOutline, languageOutline, peopleOutline } from 'ionicons/icons';

import plugins from '@/plugins';
import { RouteLocationRaw } from 'vue-router';

export default defineComponent({
  name: 't-app-sidebar',
  components: {
    IonMenu, IonList, IonMenuToggle, IonItem, IonLabel, IonIcon
  },
  setup() {
    const { $store, $t, $env } = plugins

    return {
      appPages: computed<MenuItem[]>(() => {
        const appPages: MenuItem[] = []

        if (!$store.account.isAdmin)
          appPages.push({
            title: $t('sitemap.dashboard'),
            url: { name: 'dashboard' },
            icon: home
          }, {
            title: $t('sitemap.business.root'),
            subPages: [{
              title: $t('sitemap.business.prospecting'),
              url: { name: 'prospect' },
              icon: megaphoneOutline
            }, {
              title: $t('sitemap.business.directory.root'),
              url: { name: 'directory' },
              icon: idCardOutline
            }]
          }, {
            title: $t('sitemap.management.root'),
            subPages: [{
              title: $t('sitemap.management.projects'),
              url: { name: 'projects' },
              icon: settingsOutline
            }, {
              title: $t('sitemap.management.archive'),
              url: { name: 'archive' },
              icon: archiveOutline
            }, {
              title: $t('sitemap.management.invoices.root'),
              url: { name: 'invoices' },
              icon: clipboardOutline
            }]
          })

        if ($store.account.isDevelopper)
          appPages.push({
            title: 'Admin',
            subPages: [{
              title: 'Composants',
              url: { name: 'admin_components' },
              icon: appsOutline
            }, {
              title: 'Documentation',
              url: { name: 'admin_doc' },
              icon: appsOutline
            }, {
              title: 'Traduction',
              url: { name: 'admin_i18n' },
              icon: languageOutline
            }, {
              title: 'Utilisateurs',
              url: { name: 'admin_users' },
              icon: peopleOutline
            }]
          })

        return appPages
      }),
      version: computed(() => $env.FRONTEND_RUNTIME_GIT_VERSION ? `${$env.FRONTEND_RUNTIME_GIT_VERSION}.${$env.FRONTEND_RUNTIME_GIT_COMMIT}` : 'dev')
    }
  }
})

type MenuItem = {
  title: string;
  icon?: string;
  url?: RouteLocationRaw;
  subPages?: MenuItem[];
}

</script>

<style lang="postcss">
ion-menu {
  a:focus-visible {
    @apply outline-none;

    >*>ion-label {
      @apply border rounded;
    }
  }

  ion-item {
    --ion-item-border-color: var(--tw-color-ocean-700);
  }
}
</style>