<template>
  <ErrorTooltip :class="[$attrs.class, 'my-2 flex']" :style="$attrs.style" :validation="validation">

    <Listbox as="div" v-model="model" :disabled="disabled" :multiple="multiple" :by="compare" v-slot="{ open }"
      class="w-full">
      <div class="relative">
        <ListboxLabel v-if="!noValue || (validation && validation.required)" class="t-input-label"
          :class="{ 't-input-hide-label': !$props.placeholder }">
          <span v-if="!noValue">{{ $props.placeholder || '' }}</span>
          <span class="text-gray-400 text-xs" v-if="validation && validation.required">&nbsp;&#8727;&nbsp;</span>
        </ListboxLabel>

        <ListboxButton :class="clazz">

          <template v-if="$slots.inputs">
            <slot name="inputs" :value="model" />
          </template>

          <span v-else-if="noValue" class="block truncate text-gray-400">{{ $props.placeholder || '&nbsp;' }}</span>

          <template v-else-if="$slots.input && multiple">
            <div class="flex flex-wrap gap-0.5 -my-1">
              <slot name="input" v-for="(m, mi) in model" :key="mi" :value="m" :remove="() => remove(mi)" />
            </div>
          </template>

          <template v-else-if="$slots.input && !multiple">
            <slot name="input" />
          </template>

          <template v-else>
            <span v-if="!multiple" class="block truncate pl-3">{{ optToLabel(model) }}&nbsp;</span>
            <template v-else v-for="(m, mi) in model" :key="mi">
              <div class="inline-block text-sm whitespace-nowrap p-1">
                <span class="bg-gray-100 rounded-l-full px-2 py-1">{{ optToLabel(m) }}</span>
                <span class="bg-gray-200 rounded-r-full px-2 py-1 cursor-pointer" @click.stop="remove(mi)">x</span>
              </div>
            </template>
          </template>

          <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <t-icon-chevron-up v-if="open" class="h-5 w-5 text-gray-400" aria-hidden="true" />
            <t-icon-chevron-down v-else class="h-5 w-5 text-gray-400" aria-hidden="true" />
          </span>

          <button v-if="$props.nullable && !noValue" class="absolute inset-y-0 right-5 flex items-center pr-2"
            @click.prevent="nullify">
            <t-icon-x-mark class="h-4 w-4 text-gray-400" aria-hidden="true" />
          </button>

        </ListboxButton>

        <transition name="transition-opacity">
          <ListboxOptions
            class="absolute z-20 mt-1 bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm w-full"
            :class="[{ 'flex flex-wrap': inline }, $props.dropdownClass]">
            <ListboxOption as="template" v-for="(opt, optIdx) in opts" :key="optsKey[optIdx]" :value="opt"
              :class="{ 'opacity-80': $props.optionsKeyDisabled && $props.optionsKeyDisabled.indexOf(optsKey[optIdx]) !== -1 }"
              :disabled="$props.optionsKeyDisabled && $props.optionsKeyDisabled.indexOf(optsKey[optIdx]) !== -1">
              <template v-slot="{ active, selected, disabled }">
                <li :class="[
    active ? 'text-white bg-ocean' : 'text-gray-900',
    !$slots.default ? 'pl-8 pr-4 py-2' : '',
    'flex cursor-default select-none relative'
  ]">
                  <template v-if="!$slots.default">
                    <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate whitespace-pre']">{{
    optToLabel(opt)
  }}</span>
                    <span v-if="selected"
                      :class="[active ? 'text-white' : 'text-ocean', 'absolute inset-y-0 left-0 flex items-center pl-1.5']">
                      <t-icon-check class="h-5 w-5" aria-hidden="true" />
                    </span>
                  </template>
                  <slot v-else :opt="opt" :selected="selected" :active="active" :disabled="disabled" />
                </li>
              </template>
            </ListboxOption>

            <ListboxOption v-if="$slots.end" v-slot="{ active }" as="template" key="slotend">
              <li class="select-none">
                <slot name="end" :active="active" />
              </li>
            </ListboxOption>

          </ListboxOptions>
        </transition>

      </div>

    </Listbox>
  </ErrorTooltip>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch, toRaw, PropType, Ref } from "vue"
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'

import ErrorTooltip from './error/ErrorTooltip.vue'

import { ValidationOf } from "@/lib/vuelidate.type";


// function defineGenericComponent<T, D>() {
//   return defineComponent({ ... })
// }

export default defineComponent({
  name: "t-select",
  components: {
    ErrorTooltip,
    Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions
  },
  props: {
    modelValue: [Object, String, Boolean, Array, Number] as PropType<object | string | boolean | Array<any> | number | null>,

    options: [Array<any>, Function] as PropType<any[] | (() => any[]) | (() => Promise<any[]>)>,

    /** transform value */
    optionsValue: {
      type: Function as PropType<(opt: any) => any>,
      default: (a: any) => a
    },

    /** String attribut or Function to display options */
    optionsLabel: [String, Function] as PropType<string> | PropType<(opt: any) => string>,

    /** String attribut or Function to compare options */
    optionsKey: {
      type: [String, Function] as PropType<string> | PropType<(opt: any) => string>
    },

    optionsKeyDisabled: { type: Array as PropType<string[] | readonly string[]> },

    multiple: Boolean,
    placeholder: String,
    disabled: Boolean,

    nullable: Boolean,
    nullableValue: [Number, Object], // any
    nullableUndefined: Boolean,

    selectClass: String,
    dropdownClass: String,
    inline: Boolean,

    validation: Object as PropType<ValidationOf<unknown, { required?: any }>>
  },
  emits: ['update:modelValue', 'change'],
  setup(props, { emit }) {

    function optToKey(a: any) {
      if (typeof props.optionsKey === 'string')
        return defaultOptTokey(a?.[props.optionsKey])
      // optionsKey must return a string, call defaultOptTokey just in case
      return props.optionsKey ? defaultOptTokey(props.optionsKey(a), props.placeholder) : defaultOptTokey(a, props.placeholder)
    }

    function optToLabel(a: any) {
      if (!props.optionsLabel)
        return `${a}`
      if (typeof props.optionsLabel === 'string')
        if (a !== null && typeof a === 'object')
          return `${a[props.optionsLabel]}`
        else
          return `${a}`
      else
        return props.optionsLabel(a)
    }

    const opts = ref([]) as Ref<any[]>
    const optsKey = ref([]) as Ref<any[]>

    watch(() => props.options, async o => {
      (opts as any).value = typeof o === 'function' ? (await o()) : (o || [])
      optsKey.value = opts.value.map(_o => optToKey(props.optionsValue(_o)))
    }, { immediate: true })

    function setModel(val: any) {
      if (props.multiple)
        val = (val as Array<any>)
          ?.map(a => props.optionsValue(a))
          ?.filter(ii => ii !== undefined)
      else
        val = props.optionsValue(val)

      emit('update:modelValue', val);
      emit('change', val);
    }

    function getModel() {
      if (props.multiple) {
        const modelMultiple = (props.modelValue as Array<any> || []).map(optToKey)
        return optsKey.value
          .reduce((result, curr, idx) => {
            if (modelMultiple.indexOf(curr) !== -1)
              result.push(opts.value[idx])
            return result
          }, [])
      } else {
        const ii = optsKey.value.indexOf(optToKey(props.modelValue))
        return ii !== -1 ? opts.value[ii] : props.modelValue
      }
    }

    const model = computed({
      get() { return getModel() },
      set(val: any) { setModel(val) }
    })

    return {
      model,

      opts,
      optsKey,

      optToLabel,

      compare(a: any, z: any) {
        return (a && optToKey(props.optionsValue(a))) === (z && optToKey(props.optionsValue(z)))
      },

      noValue: computed(() =>
        props.multiple ?
          (model.value as Array<any>).length === 0
          :
          !model.value
      ),

      clazz: computed(() => {
        const _clazz = ["overflow-x-auto relative w-full bg-white border-2 border-gray-200 rounded-md pl-3 pr-10 text-left py-2"]
        if (props.disabled)
          _clazz.push('bg-gray-50 cursor-default')
        else
          _clazz.push('cursor-pointer')
        if (props.validation && props.validation.$errors.length)
          _clazz.push('pr-8 t-border-error')
        if (props.selectClass)
          _clazz.push(props.selectClass)
        return _clazz
      }),

      remove(idx: number) {
        const modelMultiple = model.value as Array<any>
        modelMultiple.splice(idx, 1)
        setModel(model.value)
      },

      nullify() {
        if (props.nullableValue !== undefined)
          model.value = props.nullableValue
        else if (props.nullableUndefined === true)
          model.value = undefined
        else if (props.multiple)
          model.value = []
        else
          model.value = undefined
      }
    }
  }
});


/**
 * @param a value 
 * @param placeholder help to identify select with warning
 */
function defaultOptTokey(a: any, placeholder?: string) {
  if (a !== null && typeof a === 'object') {
    const id = placeholder ? `(with placeholder: '${placeholder}')` : '(without placeholder)'
    console.warn(`select ${id} have complex key for his options. Define better options-key or options-value`, toRaw(a))
    return JSON.stringify(a)
  }
  return `${a}`
}
</script>
