<template>
  <span class="flex items-center">
    <span class="rounded-lg inline-flex p-1 items-center"
      :title="!withTitle ? $enum.ServiceUnit.translate($props.unit) : ''"
      :class="`service-logo-${$props.disabled ? 'disabled' : $props.unit}`">
      <component v-if="icon" :is="icon" :class="$attrs.class" />
    </span>
    <span v-if="withTitle" class="t-fcapitalize inline-block pl-2 text-center">
      {{ $enum.ServiceUnit.translate($props.unit) }}
    </span>
  </span>
</template>


<script lang="ts">
import { defineComponent, PropType, computed } from 'vue'
import { TableCellsIcon, Bars3CenterLeftIcon, ClockIcon, CalendarIcon, TagIcon, SwatchIcon } from '@heroicons/vue/24/outline';
import { Service } from '@tolemac/grpc_web_api/tolemac/pub/model';

export default defineComponent({
  name: 't-service-logo',
  inheritAttrs: false,
  props: {
    unit: Number as PropType<Service.ServiceUnit>,
    disabled: Boolean,
    withTitle: Boolean
  },
  setup(props) {
    return {

      icon: computed(() => {
        switch (props.unit) {
          case Service.ServiceUnit.PRICE_GRID: return TableCellsIcon
          case Service.ServiceUnit.HOUR: return ClockIcon
          case Service.ServiceUnit.DAY: return CalendarIcon
          case Service.ServiceUnit.PACKAGE: return TagIcon
          case Service.ServiceUnit.WORD: return Bars3CenterLeftIcon
          case Service.ServiceUnit.GENERIC: return SwatchIcon
          default: return undefined
        }
      })
    }
  }

})
</script>

<style lang="postcss">
.service-logo-disabled {
  @apply bg-gray-50;

  >svg {
    @apply text-gray-500;
  }
}

.service-logo-bg-1 {
  @apply bg-yellow-50;
}

.service-logo-bg-2 {
  @apply bg-blue-50;
}

.service-logo-bg-3 {
  @apply bg-red-50;
}

.service-logo-bg-4 {
  @apply bg-purple-50;
}

.service-logo-bg-5 {
  @apply bg-green-50;
}

.service-logo-bg-6 {
  @apply bg-fluo-50;
}

.service-logo-1 {
  @apply bg-yellow-50 border border-yellow-700;

  >svg {
    @apply text-yellow-700;
  }
}

.service-logo-2 {
  @apply bg-blue-50 border border-blue-700;

  >svg {
    @apply text-blue-700;
  }
}

.service-logo-3 {
  @apply bg-red-50 border border-red-700;

  >svg {
    @apply text-red-700;
  }
}

.service-logo-4 {
  @apply bg-purple-50 border border-purple-700;

  >svg {
    @apply text-purple-700;
  }
}

.service-logo-5 {
  @apply bg-green-50 border border-green-700;

  >svg {
    @apply text-green-700;
  }
}

.service-logo-6 {
  @apply bg-fluo-50 border border-fluo-700;

  >svg {
    @apply text-fluo-700;
  }
}
</style>