import { useRoute, useRouter } from 'vue-router';
import { Ref } from 'vue';

import { i18n, useLanguages, useEnum, useFormatter } from './i18n'
import { useApi } from './api'
import { useRootEmitter, emitter } from './emitter'
import { modalController, useModal } from './modal'
import { toastController } from './toast'
import { useEnv } from './env'

import { util } from './util'

import { useStoreAccount } from '@/store/store.account'
import { useStoreDisplay } from '@/store/store.display'


export function refIsNotNull<T>(c: Ref<T | null | undefined>): asserts c is Ref<NotNull<T>> {
    if (c.value === null || c.value === undefined)
        throw new Error('')
}

/**
 * prefix with $.. or use...()  
 *   => vue composition  
 *   => call in setup function before return and await (if async setup)  
 */
export default {
    get $api() { return useApi() },

    get $emitter() { return useRootEmitter() },

    get $i18n() { return i18n },
    get $t() { return i18n.t },
    get $tm() { return i18n.tm },
    get $enum() { return useEnum() },
    get $formatter() { return useFormatter() },
    get $languages() { return useLanguages() },

    $store: {
        get account() { return useStoreAccount() },
        get display() { return useStoreDisplay() }
    },

    get $route() { return useRoute() },

    get $router() { return useRouter() },

    get $env() { return useEnv() },

    emitter: emitter,

    /**
     * global modal controller
     */
    modalCtl: modalController,

    /**
     * current modal instance.
     * ! Only use in modal !
     */
    get $modal() {
        return useModal()
    },

    toast: toastController,

    util: util
}
