import { ref } from 'vue'

export function useFocus(callbackFocusOff?: () => void) {
    const focused = ref(false)

    let focusTimeout: null | number | NodeJS.Timeout = null

    function onFocusChange(e: Event) {
        if (focusTimeout)
            clearInterval(focusTimeout)
        if (e.type === "focus")
            focused.value = true
        else
            focusTimeout = setTimeout(() => {
                focused.value = false;
                if (callbackFocusOff) callbackFocusOff()
            }, 100)
    }

    return {
        focused,
        onFocusChange
    }
}