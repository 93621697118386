<template>
  <t-input-icon :icon="MagnifyingGlassIcon" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { MagnifyingGlassIcon } from '@heroicons/vue/20/solid'

export default defineComponent({
  name: "t-input-search",
  mixins: [] as unknown as [GlobalComponentsMixin<'TInputIcon'>],
  setup: () => ({ MagnifyingGlassIcon })
});
</script>
