
/**
 * Example:
 *  * `setup() { return { debounce: createDebounce() } }`
 *  * `@input="debounce(() => { state.filterText = $event.target.value })"`
 */
export function debounce<T extends (...args: Parameters<T>) => ReturnType<T>>(fnc: T, wait = 300, notDebounceFirst = false, fncReturn?: (r: ReturnType<T>) => void) {
    let timeout: number | NodeJS.Timeout | null = null;
    return function (...args: Parameters<T>) {
        if (timeout) clearTimeout(timeout);
        if (notDebounceFirst) {
            notDebounceFirst = false
            if (fncReturn) fncReturn(fnc(...args))
            else fnc(...args)

        } else {
            timeout = setTimeout(() => {
                if (fncReturn) fncReturn(fnc(...args))
                else fnc(...args)
            }, wait);
        }
    };
}
