<template>
  <div class="m-auto p-4 sm:pb-0 w-full sm:w-1/2 bg-white rounded-md" v-if="args">
    <button v-if="args.closeButton !== false" class="float-right border p-1 rounded-md" @click="dismiss()">
      <t-icon-x-mark class="w-3 h-3" />
    </button>
    <div class="flex items-start mb-4">
      <div class="flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10"
        :class="iconColors.bgColor">
        <component :is="icon" class="h-6 w-6" :class="iconColors.color" aria-hidden="true" />
      </div>
      <div class="self-center text-center sm:text-left sm:ml-2 w-full">
        <span class="text-lg leading-6 font-medium text-gray-900">{{ args.title || '' }}</span>
      </div>
    </div>
    <div class="mt-3 text-justify sm:mt-0 sm:pl-4 sm:text-left">
      <p v-for="(m, mi) of message" class="text-sm text-gray-500 whitespace-pre-line" :key="mi">
        {{ (!m || m === '<br>') ? '&nbsp;' : m }}
      </p>
      <slot />
    </div>
    <div class="mt-4 sm:mt-4 mr-0 sm:mr-2 mb-2 sm:flex sm:flex-row flex-wrap gap-2">
      <template v-if="!$slots.buttons">
        <t-button v-for="(button, buttonI) in buttons" :key="$.uid + '_' + buttonI" :color="button.color || 'white'"
          @click="dismiss(button.value)" :href="button.href" :autofocus="buttonI === 0 ? loaded : undefined">
          {{ button.title }}
        </t-button>
      </template>
      <slot v-else name="buttons" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, FunctionalComponent } from 'vue'
import { ExclamationTriangleIcon, ArchiveBoxArrowDownIcon, CheckIcon, TrashIcon } from '@heroicons/vue/24/outline'

import plugins from '@/plugins'

export default defineComponent({
  name: 't-modal',
  props: {
    args: Object as PropType<ModalArgs>,
    onDismiss: Function as PropType<(val: any) => any>,
  },
  emits: {
    dismiss: (val: any) => true
  },
  components: {
    ExclamationTriangleIcon, ArchiveBoxArrowDownIcon
  },
  setup(props) {

    const { $modal, $t } = plugins

    const buttons: ModalArgs['buttons'] = props.args?.buttons || [{
      title: $t('actions.continue'),
      color: 'red',
      value: true
    }, {
      title: $t('actions.cancel'),
      value: false
    }]

    const message = Array.isArray(props.args?.message) ? props.args?.message || [''] : [props.args?.message || '']

    const ICON_COLORS: Record<NonNullable<ModalArgs['iconColor']>, { color: string; bgColor: string }> = {
      danger: { color: 'text-red-600', bgColor: 'bg-red-100' },
      warn: { color: 'text-yellow-700', bgColor: 'bg-yellow-50' },
      green: { color: 'text-green-500', bgColor: 'bg-green-100' }
    }

    let icon: FunctionalComponent
    switch (props.args?.icon) {
      case 'check': icon = CheckIcon; break;
      case 'archive': icon = ArchiveBoxArrowDownIcon; break;
      case 'trash': icon = TrashIcon; break;
      case 'exclamation': icon = ExclamationTriangleIcon; break;
      case undefined: icon = ExclamationTriangleIcon; break;
      default: icon = props.args?.icon ? props.args?.icon : ExclamationTriangleIcon; break;
    }


    if (props.onDismiss) $modal.setOnDismiss(props.onDismiss)

    return {
      dismiss: $modal.dismiss,
      loaded: $modal.loaded,

      buttons,
      message,
      iconColors: props.args?.iconColor ? ICON_COLORS[props.args.iconColor] : ICON_COLORS.danger,
      icon
    }
  }
})

</script>