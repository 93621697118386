// Object

function objectRemoveKey(root: any, key: string, withId = false) {
    key.split('.').reduce((o, k, idx, arr) => {
        if (typeof o === 'object' && o) {
            if (idx === arr.length - 1)
                if (withId) o[k] = { id: o[k]['id'] }
                else delete o[k]
            else if (Array.isArray(o[k])) for (const rootA of o[k])
                objectRemoveKey(rootA, arr.slice(idx + 1, arr.length).join('.'), withId)
            else return o[k]
        } else {
            return undefined
        }
    }, root)
}

/**
 * { a: { b: 1, c: 2 } } with ['a.b']
 * => { a: { c: 2 } }
 * 
 * array ??
 */
export function objectRemoveKeys(root: any, ...keys: string[]) {
    for (const key of keys) objectRemoveKey(root, key)
}

/**
 * { a: { id: 1, c: 2, ... } } with ['a']
 * => { a: { id: 1 } }
 * 
 * array ??
 */
export function objectReplaceId(root: any, ...keys: string[]) {
    for (const key of keys) objectRemoveKey(root, key, true)
}

/**
 * 
 */
export function objectClone<T = any>(p: T, excludeKeys: string[] = []): T {
    return JSON.parse(JSON.stringify(p, (key, value) => excludeKeys.indexOf(key) !== -1 ? undefined : value))
}

// Date

/** ms in one day */
export const DAY = 86400000;

/**
 * local year, month, day => utc timestamp
 *  rem: utc hour / minutes / ... = 0
 */
export function today() {
    const n = new Date();
    return Date.UTC(n.getFullYear(), n.getMonth(), n.getDate(), 0, 0, 0, 0)
}

export function startOfMonthUTC(timestamp?: number) {
    const n = timestamp ? new Date(timestamp) : new Date();
    return Date.UTC(n.getFullYear(), n.getMonth(), 1)
}

export function monthBetween(start?: number, end?: number) {
    const s = start ? new Date(start) : new Date();
    const e = end ? new Date(end) : new Date();
    return (e.getFullYear() - s.getFullYear()) * 12 + (e.getMonth() - s.getMonth())
}

export function addOneMonth(time?: number) {
    if (!time) return undefined
    const dateTime = new Date(time)
    const month = (dateTime.getMonth() + 1) % 12
    const year = dateTime.getMonth() === 11 ? dateTime.getFullYear() + 1 : dateTime.getFullYear()
    return Date.UTC(year, month, dateTime.getDate())
}


// Vue

/** PropType<null> */
interface NullConstructor {
    new(value?: any): null;
    <T>(value?: T): null;
    readonly prototype: null;
}

/** fake factory. use only for type of PropType */
export const Null = function factory<A>(ctor: { new(): A }): A { return new ctor(); } as NullConstructor

export class ErrorToast extends Error implements IErrorToast {
    errorType = 'ErrorToast' as const
    name = 'ErrorToast'
    constructor(public level: IErrorToast['level'], public message: string) {
        super(message)
    }
}