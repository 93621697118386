import { Company, ICompany, ICompanyStats } from '@tolemac/grpc_web_api/tolemac/pub/model'

import { modalController } from '../modal'
import { useApi } from '../api'

import CompanyModal from '@/components/company/CompanyModal.vue'

export const company = {
    openModal(company: Partial<ICompany> | number = {}): Promise<Company | undefined> {
        return modalController.openCustom({
            component: CompanyModal,
            componentProps: typeof company === 'number' ? { companyId: company } : { company }
        })
    },

    get $haveProspectable() {
        const $api = useApi()

        return async function () {
            const _companies = await $api.company.find({
                relations: ['none'],
                criterias: [
                    { field: 'type', value: `${Company.CompanyType.PROFESSIONAL}` },
                    { field: 'prospect', value: `${Company.CompanyProspection.UNDEFINED_CompanyProspection}` }
                ]
            })

            return _companies.count! > 0
        }
    },

    get $stats() {
        const $api = useApi()

        return {
            async useLoadAllStats(companyId?: number) {

                const sum = {
                    global: {
                        hourlyRate: 0,
                        hourlyRateEstimated: 0,
                        pricePerWord: 0,
                        paymentTime: 0
                    } as ICompanyStats,
                    company: {
                        hourlyRate: 0,
                        hourlyRateEstimated: 0,
                        pricePerWord: 0,
                        paymentTime: 0
                    } as ICompanyStats
                }

                // const [global, company] = await Promise.all([
                //     $api.orgaStats.getmine({}),
                //     $api.companyStats.find({
                //         criterias: [{ field: 'id', value: `${companyId}` }]
                //     }).then(v => v.values[0])
                // ])
                // sum.global = global
                // sum.company = company

                const _stats = await $api.companyStats.find({})

                const _statCompany = _stats.values.find(s => s.id === companyId)
                if (_statCompany) {
                    sum.company.id = _statCompany.id
                    sum.company.hourlyRate = _statCompany.hourlyRate || 0
                    sum.company.hourlyRateEstimated = _statCompany.hourlyRateEstimated || 0
                    sum.company.pricePerWord = _statCompany.pricePerWord || 0
                    sum.company.countProjects = _statCompany.countProjects
                    sum.company.paymentTime = _statCompany.paymentTime
                }

                const _sumGlobal = _stats.values.reduce((tot, s) => {
                    if (s.hourlyRate) {
                        tot.hourlyRateSum += s.hourlyRate
                        tot.hourlyRateCount++
                    }
                    if (s.hourlyRateEstimated) {
                        tot.hourlyRateEstimatedSum += s.hourlyRateEstimated
                        tot.hourlyRateEstimatedCount++
                    }
                    if (s.pricePerWord) {
                        tot.wordsRateSum += s.pricePerWord
                        tot.wordsRateCount++
                    }
                    if (s.paymentTime) {
                        tot.paymentTime += s.paymentTime
                        tot.paymentTimeCount++
                    }
                    tot.countProjects += s.countProjects
                    return tot
                }, {
                    hourlyRateSum: 0,
                    hourlyRateCount: 0,
                    hourlyRateEstimatedSum: 0,
                    hourlyRateEstimatedCount: 0,
                    wordsRateSum: 0,
                    wordsRateCount: 0,
                    countProjects: 0,
                    paymentTime: 0,
                    paymentTimeCount: 0
                })

                sum.global.hourlyRate = _sumGlobal.hourlyRateCount ? _sumGlobal.hourlyRateSum / _sumGlobal.hourlyRateCount : 0
                sum.global.hourlyRateEstimated = _sumGlobal.hourlyRateEstimatedCount ? _sumGlobal.hourlyRateEstimatedSum / _sumGlobal.hourlyRateEstimatedCount : 0
                sum.global.pricePerWord = _sumGlobal.wordsRateCount ? _sumGlobal.wordsRateSum / _sumGlobal.wordsRateCount : 0
                sum.global.countProjects = _sumGlobal.countProjects
                sum.global.paymentTime = _sumGlobal.paymentTimeCount ? _sumGlobal.paymentTime / _sumGlobal.paymentTimeCount : 0

                return sum
            }
        }
    }
}