import { ref, Ref } from 'vue'

import { ICompanyLabel, IContactLabel, IProjectLabel } from "@tolemac/grpc_web_api/tolemac/pub/model"

import { ValidationOf } from '@/lib/vuelidate.type'
import { syncLazyResources, useValidation } from "@/composition/vuelidate"
import { validators } from './util.validators'

import { useApi } from '../api'
import { modalController } from '../modal'

type TagType = 'company' | 'contact' | 'project'


function useTagsProject() {
    const $api = useApi()

    const valid = useValidation([] as IProjectLabel[], validators.tags)

    const count = ref<number>()
    const sync = syncLazyResources(
        $api.projectLabel.add.bind($api.projectLabel),
        $api.projectLabel.update.bind($api.projectLabel),
        $api.projectLabel.delete.bind($api.projectLabel),

        valid,

        () => $api.projectLabel.find({}),
        data => count.value = data.count,
        data => count.value = data.count
    )

    return {
        ...valid,
        ...sync,
        count
    }
}

function useTagsContact() {
    const $api = useApi()

    const valid = useValidation([] as IContactLabel[], validators.tags)

    const count = ref<number>()
    const sync = syncLazyResources(
        $api.contactLabel.add.bind($api.contactLabel),
        $api.contactLabel.update.bind($api.contactLabel),
        $api.contactLabel.delete.bind($api.contactLabel),

        valid,

        () => $api.contactLabel.find({}),
        data => count.value = data.count,
        data => count.value = data.count
    )

    return {
        ...valid,
        ...sync,
        count
    }
}

function useTagsCompany() {
    const $api = useApi()

    const valid = useValidation([] as ICompanyLabel[], validators.tags)

    const count = ref<number>()
    const sync = syncLazyResources(
        $api.companyLabel.add.bind($api.companyLabel),
        $api.companyLabel.update.bind($api.companyLabel),
        $api.companyLabel.delete.bind($api.companyLabel),

        valid,

        () => $api.companyLabel.find({}),
        data => count.value = data.count,
        data => count.value = data.count
    )

    return {
        ...valid,
        ...sync,
        count
    }
}

export const tags = {
    useTags(tagOf: TagType) {

        return tagOf === 'company' ? useTagsCompany() :
            tagOf === 'contact' ? useTagsContact() :
                useTagsProject()
    },

    async openModalTags<T extends { id?: number }>(tagOf: TagType, tags: Ref<T[]>, tags$: Ref<ValidationOf<T[]>>): Promise<void> {
        return modalController.openCustom({
            component: (await import('@/components/tags/ModalTags.vue')).default,

            componentProps: {
                modelValue: tags.value,
                validation: tags$.value as any,
                tagOf,

                onUpdate(data: T) {
                    const t = tags.value.findIndex(a => a.id === data.id)
                    if (t !== -1) {
                        // update tag
                        tags.value.splice(t, 1, data)
                    } else if (data.id! < 0) {
                        // new tag
                        tags.value.unshift(data)
                    }
                },

                onRemove(index: number) {
                    tags.value.splice(index, 1)
                }
            }
        })
    },


    /**
     * @returns list of added ids
     */
    async openModalAdd(tagOf: TagType): Promise<undefined | number[]> {
        return modalController.openCustom({
            component: (await import('@/components/tags/ModalTags.vue')).default,
            componentProps: { tagOf }
        })
    }

}