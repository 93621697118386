import { Plugin, inject } from 'vue'
import { Composer, createI18n, Locale } from 'vue-i18n'

import { ILocalization } from '@tolemac/grpc_web_api/tolemac/pub/model'

import { Formatter } from './i18n/i18n.format'
import { Languages } from './i18n/i18n.languages'
import { EnumDisplay } from './i18n/i18n.enum'
import { DEFAULT_LANG, i18nConf } from './i18n/conf'

const browserI18nConf = i18nConf()

export const i18n = createI18n({
  legacy: false, // use Composition API
  fallbackLocale: 'fr',
  missingWarn: false,
  fallbackWarn: false,
}).global as Composer

const formatter = new Formatter(browserI18nConf)
const languages = new Languages(i18n)
const enumm = new EnumDisplay(i18n)

export async function changeLocal(opt: Partial<ILocalization>) {
  const optNormalized = i18nConf(opt) // TODO: lazy ??
  if (opt.currency || opt.date || opt.number || opt.time || opt.timezone)
    await formatter.changeConf(optNormalized)
  if (opt.lang)
    await loadLocalJson(optNormalized.lang)
}

const modules = Object.entries(import.meta.glob('@/locales/*.json5', { import: 'default' })).reduce((t, [k, m]) => {
  const mm = /locales\/(.*)\.json5/.exec(k)
  if (!mm || !mm[1]) throw new Error('')
  t[mm[1]] = m
  return t
}, {} as Record<string, () => Promise<any>>)


export async function loadLocalJson(lang: Locale, composer?: Composer) {
  if (!composer) composer = i18n

  if (Object.keys(composer.messages.value).indexOf(lang) !== -1)
    return composer.locale.value = lang

  const l = lang.split('-')[0]
  if (Object.keys(composer.messages.value).indexOf(l) !== -1)
    return composer.locale.value = l

  try {
    composer.setLocaleMessage(l, await modules[l]());
    composer.locale.value = l
  } catch (e: any) {
    console.warn(`Error loading language: '${l}'. Use fallback language.`, e)
    if (lang !== DEFAULT_LANG)
      await loadLocalJson(DEFAULT_LANG, composer)
  }
}

let ready: () => void
let _loading = new Promise<void>(r => ready = r)

export const i18nPlugin = {
  install: async app => {

    changeLocal(browserI18nConf)
      .then(() => ready())

    app.config.globalProperties.$t = i18n.t
    app.provide('t', i18n.t)

    app.config.globalProperties.$tm = i18n.tm as any
    app.provide('tm', i18n.tm)

    app.config.globalProperties.$formatter = formatter
    app.provide('formatter', formatter)

    app.config.globalProperties.$enum = enumm
    app.provide('enum', enumm)

    app.provide('languages', languages)
  },
  isReady() {
    return _loading
  }
} satisfies Plugin & { isReady: () => Promise<void> }

export function useFormatter() {
  return inject<Formatter>('formatter')!;
}

export function useEnum() {
  return inject<EnumDisplay>('enum')!;
}

export function useLanguages() {
  return inject<Languages>('languages')!;
}