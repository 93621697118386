<template>
  <t-input>

    <template v-if="icon || startIcon" v-slot:start>
      <component :is="icon || startIcon" class="w-7 text-ocean z-10 px-1" />
    </template>

    <template v-if="$slots.end || endIcon" v-slot:end>
      <component v-if="endIcon" :is="endIcon" class="w-7 text-ocean z-10 px-1" />
      <slot v-else name="end" />
    </template>
  </t-input>
</template>

<script lang="ts">
import { defineComponent, PropType, RenderFunction, FunctionalComponent } from "vue";

export default defineComponent({
  name: "t-input-icon",
  mixins: [] as unknown as [GlobalComponentsMixin<'TInput'>],
  props: {
    icon: Function as PropType<RenderFunction | FunctionalComponent>,

    startIcon: Function as PropType<RenderFunction | FunctionalComponent>,
    endIcon: Function as PropType<RenderFunction | FunctionalComponent>
  }
});
</script>
