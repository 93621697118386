import { Plugin, Ref } from 'vue'

import { company } from './util/util.company'
import { contact } from './util/util.contact'
import { exchange } from './util/util.exchange'
import { invoice } from './util/util.invoice'
import { project } from './util/util.project'
import { service } from './util/util.service'
import { charges } from './util/util.charges'
import { taxes } from './util/util.taxes'
import { tags } from './util/util.tags'

import { validators } from './util/util.validators'

const util = {

    validators,

    company,
    contact,
    exchange,
    invoice,
    project,
    service,
    charges,
    taxes,
    tags
}

const utilPlugin: Plugin = {
    install: (app) => {
        app.config.globalProperties.$util = util
    }
}

type Util = typeof util

export {
    utilPlugin,
    util,
    Util
}