<template>
  <t-input-icon v-if="hidden" type="password" :icon="LockClosedIcon">
    <template v-slot:end>
      <span class="h-full flex items-center px-1 bg-gray-50 text-gray-500 rounded-r-lg">
        <EyeIcon class="w-4 cursor-pointer" @click="hidden = false" />
      </span>
    </template>
  </t-input-icon>
  <t-input-icon v-else type="text" :icon="LockClosedIcon">
    <template v-slot:end>
      <span class="h-full flex items-center px-1 bg-gray-50 text-gray-500 rounded-r-lg">
        <EyeSlashIcon class="w-4 cursor-pointer" @click="hidden = true" />
      </span>
    </template>
  </t-input-icon>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { LockClosedIcon, EyeIcon, EyeSlashIcon } from '@heroicons/vue/20/solid'

export default defineComponent({
  name: "t-input-password",
  components: { EyeIcon, EyeSlashIcon },
  mixins: [] as unknown as [GlobalComponentsMixin<'TInputIcon'>],
  setup: () => {
    const hidden = ref(true)
    return {
      hidden,

      LockClosedIcon
    }
  }
});
</script>
