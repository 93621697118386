import { modalController } from '../modal'

import ExchangeModal from '@/components/exchanges/ExchangeModal.vue'

export const exchange = {
    openModalFor(receipe: { companyId?: number, contactId?: number, projectId?: number }): Promise<boolean | undefined> {
        return modalController.openCustom({
            component: ExchangeModal,
            componentProps: receipe
        })
    },

    openModal(id: number): Promise<boolean | undefined> {
        return modalController.openCustom({
            component: ExchangeModal,
            componentProps: { id }
        })
    }

}