<template>
    <router-view v-slot="{ Component, route: _route }" :route="route">
        <Suspense :timeout="500" @resolve="afterEachResolved(_route, Component?.type)">
            <template #default>
                <component v-if="Component" :is="Component" :key="route?.fullPath" v-bind="$attrs" />
                <div v-else></div>
            </template>
            <template #fallback>
                <div class="h-full flex items-center">
                    <t-icon-arrow-path class="w-20 mx-auto animate-spin text-gray-200" />
                </div>
            </template>
        </Suspense>
    </router-view>
</template>

<script lang="ts">
import { defineComponent, PropType, VNodeTypes } from 'vue';
import { RouteLocationNormalized } from 'vue-router';

export default defineComponent({
    name: 't-router-view',
    inheritAttrs: false,
    props: {
        route: { type: Object as PropType<RouteLocationNormalized>, required: true }
    },
    methods: {
        afterEachResolved(routeLocation: RouteLocationNormalized, a?: VNodeTypes) {
            if (!a) return
            const routeRecord = routeLocation.matched.find(x => x.name === routeLocation.name)
            if (routeRecord?.components?.default && routeRecord?.components?.default === a)
                this.$router.afterEachResolved()
        }
    }
})

</script>