import { Ref } from 'vue';
import { BookmarkIcon } from '@heroicons/vue/24/outline'

import { IProject, Project, User } from "@tolemac/grpc_web_api/tolemac/pub/model";

import { totalServices } from './util.service';

import { today } from '@/util';
import { modalController } from '../modal';
import { toastController } from '../toast';
import { useApi } from '../api';
import { useStoreAccount } from '../store';
import { i18n } from '../i18n'

const Status = Project.ProjectStatus

export const project = {

    /**
     * @param compute don't use project.stats, force compute services
     */
    summarizeProject(project?: IProject | null, compute = false) {
        const result = {
            amount: 0,
            hourlyRate: 0,
            hourlyRateEstimated: 0,
            pricePerWord: 0
        }
        if (!compute && project?.stats) {
            result.amount = project.stats.amount || 0
            result.hourlyRateEstimated = project.estimatedWorkTime ? result.amount / (project.estimatedWorkTime / 60) : 0
            result.pricePerWord = project.stats.pricePerWord || 0
            if (project.status && project.status >= Status.DELIVERED)
                result.hourlyRate = project.workTime ? result.amount / (project.workTime / 60) : 0
        } else if (project && project.services) {
            const { weightedWords, amount, amountWords } = totalServices(project.services)
            result.amount = amount
            result.hourlyRateEstimated = project.estimatedWorkTime ? result.amount / (project.estimatedWorkTime / 60) : 0
            result.pricePerWord = weightedWords ? amountWords / weightedWords : 0
            if (project.status && project.status >= Status.DELIVERED)
                result.hourlyRate = project.workTime ? result.amount / (project.workTime / 60) : 0
        }
        return result
    },

    useActionsProject(opt: ActionsOpts = {}, refProject?: Ref<IProject | null>) {
        const $api = useApi()
        const $store = useStoreAccount()
        const $t = i18n.t

        async function lockStartProject() {
            if (($store.token?.accessDecoded.rights || []).indexOf(User.UserRight.PREMIUM) !== -1)
                return false

            const wip = await $api.project.find({
                criterias: [{ field: 'status', values: [`${Status.WIP__NEXT}`, `${Status.WIP}`] }],
                relations: ['none']
            })

            if (wip.count === 0) return false

            await modalController.open({
                iconColor: 'warn',
                icon: BookmarkIcon,
                title: "Abonnement",
                message: "Votre abonnement actuel vous permet d'accepter une offre à la fois. Pour suivre plusieurs projets à la fois, vous devez vous abonner à Tolemac.",
                buttons: [{
                    title: 'Annuler'
                }, {
                    title: 'Voir les forfaits',
                    color: 'primary',
                    href: '/settings/subscription'
                }]
            })

            return true
        }

        function argOrGlobal(_project?: IProject, clone = false): IProject {
            if (_project instanceof Event) _project = undefined

            if (!_project && refProject?.value) _project = refProject.value
            if (!_project) throw new Error('invoice undefined for useActionsInvoice')

            if (!clone) return _project
            return JSON.parse(JSON.stringify(_project)) satisfies IProject
        }

        function cleanProject(_project: IProject) {
            // reindex services before save
            if (_project.services)
                for (let i = 0; i < _project.services.length; i++) _project.services[i].index = (i + 1);

            if (_project.company)
                _project.company = { id: _project.company.id }
        }

        async function save(project?: IProject) {
            project = argOrGlobal(project, true)
            cleanProject(project)

            let projectId = project.id
            if (!projectId)
                projectId = (await $api.project.add(project)).id!
            else
                await $api.project.update(project)



            await toastController.info(
                project.id ? $t('page.project.toast.updated') : $t('page.project.toast.saved')
            )
            if (opt.onSave) opt.onSave(projectId)
            if (opt.onActions) opt.onActions({ id: projectId })
        }

        return {
            save,

            /** valiation modal and update status to WIP and orderNumber (if defined) and startingDate (if not defined) */
            async start(project?: Pick<IProject, 'id' | 'startingDate'>) {
                project = argOrGlobal(project)

                if (await lockStartProject()) return

                const confirmOrOrderNumber: boolean | string = await modalController.openCustom({
                    component: (await import('@/components/project/ProjectModalAccept.vue')).default
                })
                if (!confirmOrOrderNumber) return

                await $api.project.update({
                    id: project.id,
                    status: Status.WIP,
                    orderNumber: typeof confirmOrOrderNumber === 'string' ? confirmOrOrderNumber : undefined,
                    startingDate: project.startingDate || today()
                })

                if (opt.onStart) opt.onStart(project.id!)
                if (opt.onActions) opt.onActions({ id: project.id!, status: Status.WIP })
            },

            /**
             * valiation modal and update status to DELIVERED and workTime (if defined)
             * 
             * @param project (with stats)
             */
            async deliver(project?: IProject) {
                project = argOrGlobal(project)

                const projectStats = project.stats ?? await $api.projectStats.get({ id: project.id! })

                const confirm: boolean | Pick<IProject, 'workTime' | 'deliveryDate'> = await modalController.openCustom({
                    component: (await import('@/components/project/ProjectModalDeliver.vue')).default,
                    componentProps: {
                        project,
                        toArchive: projectStats.amount === projectStats.amountPaid
                    }
                })
                if (!confirm) return

                const workTime = typeof confirm === 'object' ? confirm.workTime : project.workTime
                const deliveryDate = typeof confirm === 'object' ? confirm.deliveryDate : project.deliveryDate

                await $api.project.update({
                    id: project.id,
                    status: Status.DELIVERED,
                    workTime,
                    deliveryDate
                })

                if (opt.onDeliver) opt.onDeliver(project.id!)
                if (opt.onActions) opt.onActions({ id: project.id!, status: Status.DELIVERED })
            },

            /** valiation modal and update status to CANCELED and archivedComment (if defined) */
            async cancel(project?: Pick<IProject, 'id' | 'status' | 'stats'>) {
                project = argOrGlobal(project)

                const projectStats = project.stats ?? await $api.projectStats.get({ id: project.id! })

                const confirmOrComment: boolean | string = await modalController.openCustom({
                    component: (await import('@/components/project/ProjectModalClose.vue')).default,
                    componentProps: {
                        isLock: (projectStats.amountDelivered || projectStats.amountLate || projectStats.amountPaid) !== 0,
                        isDraft: project.status === Status.DRAFT
                    }
                })

                if (!confirmOrComment) return

                await $api.project.update({
                    id: project.id,
                    status: Status.CANCELED,
                    archivedComment: typeof confirmOrComment === 'string' ? confirmOrComment : undefined
                })

                if (opt.onCancel) opt.onCancel(project.id!)
                if (opt.onActions) opt.onActions({ id: project.id!, status: Status.CANCELED })
            }
        }
    }
}

type ActionsOpts = {
    onSave?: (project: number) => void;
    onStart?: (projectId: number) => void;
    onDeliver?: (projectId: number) => void;
    onCancel?: (projectId: number) => void;

    /**
     * !warning! status depend of action, not status of project
     * status undefined for save
     */
    onActions?: (project: { id: number, status?: Project.ProjectStatus.WIP | Project.ProjectStatus.DELIVERED | Project.ProjectStatus.CANCELED }) => void;
}