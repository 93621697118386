import { unref, Ref } from 'vue'

const OTHERS_FOCUSABLE_TAGS = ['INPUT', 'SELECT', 'BUTTON']

const FOCUS_DELAY = 100;
const FOCUS_MAX_RETRY = 8;

type Elt = HTMLElement & { focus?(options?: FocusOptions): void; }

export async function autoFocus<T extends Elt = Elt>(i: Ref<T | undefined> | T | undefined, onAutofocus?: (elt: T) => void) {
    let focusRetry = 0

    while (focusRetry < FOCUS_MAX_RETRY) {
        let el = unref(i)
        if (el) {
            const activeElement = document.activeElement
            if (activeElement) {
                // element is already focused
                if (activeElement === el) break;

                const rect = activeElement.getBoundingClientRect()
                const recIsVsible = rect &&
                    rect.top >= 0 &&
                    rect.left >= 0 &&
                    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
                // an other element is already focused and visible
                if (OTHERS_FOCUSABLE_TAGS.indexOf(activeElement.tagName) !== -1 && recIsVsible)
                    break
            }

            if (el.shadowRoot?.firstElementChild && "focus" in el.shadowRoot?.firstElementChild) {
                (el.shadowRoot.firstElementChild as any).focus()
            }
            if (el && "focus" in el) {
                el.focus()
                if (onAutofocus) onAutofocus(el)
            }
        }
        focusRetry += 1;
        await new Promise(delay => setTimeout(delay, FOCUS_DELAY));
    }
}
