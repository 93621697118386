import { defineStore } from "pinia";

export interface StoreNavState {
    history: string[];
    goBack: boolean;
}

export const ID_STORE_NAV = 'nav'

export const useStoreNav = defineStore(ID_STORE_NAV, {

    state: (): StoreNavState => {
        const savedState = localStorage.getItem(ID_STORE_NAV)
        if (savedState) return JSON.parse(savedState)
        return {
            history: [] as string[],
            goBack: false
        }
    },

    actions: {
        mutAddHistory(url: string) {
            if (url.startsWith('/404')) return
            if (url === this.$state.history[this.$state.history.length - 1]) return
            if (this.$state.history.length === 10)
                this.$state.history = [...this.$state.history.slice(1, 10), url]
            else
                this.$state.history.push(url)
        },

        mutPopHistory(count = 1) {
            let _history = JSON.parse(JSON.stringify(this.$state.history)) as string[]
            for (let i = 0; i < count; i++) {
                if (!_history.length) break
                const _removed = _history.pop()
                _history = _history.filter(_x => _x !== _removed)
            }
            this.$state.history = _history
        }

    },
    getters: {
        lastHistory(state) {
            return state.history[state.history.length - 1]
        }
    }
})

export type StoreNav = ReturnType<typeof useStoreNav>