<template>
  <span :class="['relative', $attrs.class, haveError ? 't-error t-has-tooltip' : '']" :style="($attrs.style as any)"
    :id="validation?.$path ? `v-${validation?.$path}` : ''">

    <slot />

    <template v-if="haveError">

      <!-- exclamation icon -->
      <div class="absolute z-20 -top-2 -right-2 pointer-events-none">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor"
          :class="[focused ? 'text-red-500' : 'text-red-300', iconClass]">
          <rect style="fill:#ffffff" width="135" height="300" x="190" y="115" />
          <path
            d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z" />
        </svg>
      </div>

      <!-- tooltip -->
      <div class="relative t-tooltip right-0 shadow rounded-lg">
        <div class="border border-gray-300 bg-white text-xs rounded-lg py-0.5 px-2 right-0 bottom-full">
          <p v-for="error in errors" class="text-sm text-red-600 align-top" :key="error.$uid" :id="error.$uid">
            {{ error.$message }}
          </p>
        </div>
        <svg class="absolute h-4 top-full right-3 text-gray-200" x="0px" y="0px" viewBox="0 0 255 255"
          xml:space="preserve">
          <polygon class="fill-current" points="0,0 127.5,127.5 255,0" />
        </svg>
      </div>
    </template>

  </span>
</template>

<script lang="ts">
import { BaseValidationKey } from "@/lib/vuelidate.type";
import { defineComponent, computed, PropType } from "vue";

export default defineComponent({
  inheritAttrs: false,
  props: {
    validation: Object as PropType<BaseValidationKey<any>>,
    validationSilentError: Boolean,
    /**
     * only include error from matched rules.
     * regexp execute on rule.$uid = <property path>-<rule key>
     * ex: "a.b.0.c-required"
     */
    validationIncludeRule: Array as PropType<RegExp[]>,

    focused: Boolean,
    iconClass: { type: String, default: () => 'h-4 w-4' },
    hide: Boolean
  },
  setup(props) {
    const errors = computed(() => {
      if (!props.validation) return []
      const _errors = props.validationSilentError ? props.validation.$silentErrors : props.validation.$errors
      if (!props.validationIncludeRule?.length) return _errors
      return _errors.filter(x => props.validationIncludeRule!.some(r => r.test(x.$uid)))
    })

    const haveError = computed(() => !props.hide && !!errors.value.length)

    return { errors, haveError }
  }
})
</script>

<style lang="postcss">
.t-tooltip {
  @apply absolute hidden bottom-0;
  transform: translateY(-200%);
}

.t-has-tooltip:hover {
  .t-tooltip {
    @apply block z-50;
  }
}
</style>