import { Contact } from '@tolemac/grpc_web_api/tolemac/pub/model';

import { modalController } from '../modal'
import ContactModal from '@/components/contact/ContactModal.vue'

export const contact = {
    openModal(contact: { contactId?: number; companyId?: number } = {}): Promise<Contact | undefined> {
        return modalController.openCustom({
            component: ContactModal,
            componentProps: contact
        })
    }
}
