import { Plugin, inject } from 'vue'
import { TinyEmitter as TinyEmitterImpl } from 'tiny-emitter'

class Emitter extends TinyEmitterImpl {

    EVT_SCROLL_TOP = 'scrollTop'

    // debounce for lock -> unlock < 100ms
    private timeoutSetDirty = new Map<number, NodeJS.Timeout>()

    scrollTop() {
        this.emit(this.EVT_SCROLL_TOP)
    }

    lock(uid: number) {
        this.timeoutSetDirty.set(uid, setTimeout(() => {
            this.timeoutSetDirty.delete(uid)
            // if (import.meta.env.DEV) console.log('lock', uid)
            emitter.emit('lock', uid)
        }, 100))
    }

    unlock(uid: number) {
        if (this.timeoutSetDirty.has(uid)) {
            clearTimeout(this.timeoutSetDirty.get(uid))
            this.timeoutSetDirty.delete(uid)
        } else {
            // if (import.meta.env.DEV) console.log('unlock', uid)
            emitter.emit('unlock', uid)
        }
    }
}

export const emitter = new Emitter()

export const emitterPlugin: Plugin = {
    install: (app) => {
        app.config.globalProperties.$emitter = emitter
        app.provide('emitter', emitter)
    }
}

export function useRootEmitter() {
    return inject<Emitter>('emitter') as Emitter;
}


export function buildDirtyLock({ exclude, include }: { exclude?: number[]; include?: number[] } = {}) {
    const locks = new Set<number>()

    function print() {
        if (locks.size === 0) return 'unlocked'
        const inOrEx = (include || exclude) ? `with ${include ? 'include: [' + include.join(',') + ']' : ''} ${exclude ? 'exclude: [' + exclude.join(',') + ']' : ''}] ` : ''
        return `locked by: ${[...locks.keys()].join(',')} ${inOrEx}`
    }

    const onLock = (uid: number) => {
        if (
            (!exclude || exclude.indexOf(uid) === -1)
            &&
            (!include || include.indexOf(uid) !== -1)
            &&
            !locks.has(uid)
        ) {
            locks.add(uid)
            if (import.meta.env.DEV)
                console.log('lock:', uid, 'global locks state:', print())
        }
    }

    const onUnlock = (uid: number) => {
        if (
            (!exclude || exclude.indexOf(uid) === -1)
            &&
            (!include || include.indexOf(uid) !== -1)
            &&
            locks.has(uid)
        ) {
            locks.delete(uid)
            if (import.meta.env.DEV)
                console.log('unlock:', uid, 'global locks state:', print())
        }
    }

    emitter.on('lock', onLock)
    emitter.on('unlock', onUnlock)

    return {
        get isLocked() {
            return locks.size !== 0
        },

        get uids() {
            return [...locks.keys()]
        },

        unlock() {
            locks.clear()
        },

        include(...uid: number[]) {
            if (!include) {
                locks.clear()
                include = []
            }
            include.push(...uid)
        },

        destroy() {
            emitter.off('lock', onLock)
            emitter.off('unlock', onUnlock)
        },

        print
    }
}

export type TinyEmitter = Emitter
