<template>
  <div class="m-auto p-2 w-full md:w-2/3 2xl:w-1/2 bg-white rounded-md overflow-y-auto" v-if="contact">
    <div class="px-2 md:px-5">
      <h2 class="text-lg font-medium">
        {{ contact.id ? $t('section.contact.title_edit') : $t('section.contact.title_add') }}
      </h2>
      <p class="text-gray-500 pb-5">
        {{ $t('section.contact.description') }}
      </p>

      <form action="" class="grid grid-cols-1 md:grid-cols-2 gap-2 py-4">

        <t-input v-model="contact.lastname" :validation="contact$.lastname" autocomplete="given-name" type="text"
          :placeholder="$t('model.contact.lastname')" />

        <t-input v-model="contact.firstname" :validation="contact$.firstname" autocomplete="family-name" type="text"
          :placeholder="$t('model.contact.firstname')" />

        <t-input v-model="contact.job" :validation="contact$.job" type="text" :placeholder="$t('model.contact.job')" />

        <ComboboxCompany v-model="contact.company" :validation="contact$.company" nullable
          :placeholder="$t('model.contact.company')"
          :disabled="$props.companyId !== undefined || contact.isMainContact || contact.isRefContact" :limit="3" />

        <t-input-email v-model="contact.email" :validation="contact$.email" :placeholder="$t('model.contact.email')" />

        <t-input-tel v-model="contact.phone" :validation="contact$.phone" type="tel"
          :placeholder="$t('model.contact.phone')" />

        <TagSelect class="col-span-full" type="contact" v-model="contact.labels" />

      </form>
    </div>
    <div class="flex flex-row flex-grow-0 gap-2 px-5 pt-5">
      <t-button v-vuelidate="() => ({ click: save, validation: contact$, unchanged: contact?.id === undefined })">
        {{ $t('actions.save') }}
      </t-button>
      <t-button color="white" @click="forceDismiss()">
        {{ $t('actions.cancel') }}
      </t-button>
    </div>

  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import { IContact } from '@tolemac/grpc_web_api/tolemac/pub/model'
import { CriteriaOperator } from '@tolemac/grpc_web_api/tolemac/pub/common';

import plugins from '@/plugins'

import { useValidation } from '@/composition/vuelidate'

import TagSelect from '@/components/tags/TagSelect.vue'
import ComboboxCompany from '@/components/company/ComboboxCompany.vue'

export default defineComponent({
  name: 't-contact-modal',
  components: { TagSelect, ComboboxCompany },
  props: {
    contactId: Number,
    companyId: Number
  },
  async setup(props) {
    const { $api, toast, $modal, $t, $enum, util } = plugins;

    const { refObj: contact, validationObj: contact$, setVal: contact$set } = useValidation(
      null as IContact | null,
      util.validators.contact.contactVArgs
    )

    $modal.addLock(contact$.value.$uid)

    if (props.contactId)
      await $api.contact.get({ id: props.contactId, relations: ['company', '@labels', 'isMainContact', 'isRefContact'] })
        .then(_contact => contact$set(_contact))
    else if (props.companyId)
      await $api.company.get({ id: props.companyId })
        .then(_company => contact$set({ company: _company, labels: [] }))
    else
      contact$set({ labels: [] })

    return {
      contact,
      contact$,

      forceDismiss: $modal.forceDismiss,

      async save() {
        if (!contact.value) return

        contact.value.id ? await $api.contact.update(contact.value) : await $api.contact.add(contact.value);
        toast.info(contact.value.id ? $t('section.contact.toast.updated') : $t('section.contact.toast.saved'))
        $modal.forceDismiss(contact.value)
      },

      searchCompany(name: string) {
        return $api.company.find({
          criterias: [{
            field: 'type',
            value: `${$enum.CompanyType.PROFESSIONAL}`
          }, {
            field: 'name',
            operator: CriteriaOperator.I_LIKE,
            value: `%${name}%`
          }],
          limit: 10
        }).then(v => v.values)
      }
    }
  }
})
</script>