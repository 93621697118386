import { ErrorCode } from "@tolemac/grpc_web_api/tolemac/pub/common";

import plugins from "@/plugins";

export function handle404Error<T extends (...args: Parameters<T>) => Promise<void>>(call: T) {
    const { $router } = plugins

    return async function (...args: Parameters<T>) {
        try {
            await call(...args)
            return true
        } catch (e) {
            const err = e as ErrorApi
            if (typeof err === "object" && err.errorType === "ErrorApi") {
                if (err.errorCodeGroup === ErrorCode.NOT_FOUND) {
                    $router.go404()
                    return false
                }
            } else {
                throw e
            }
            return false
        }
    }
}

export function handle404ErrorForModal<T extends (...args: Parameters<T>) => Promise<void>>(call: T) {
    const { modalCtl, toast, $enum } = plugins

    return async function (...args: Parameters<T>) {
        try {
            await call(...args)
            return true
        } catch (e) {
            const err = e as ErrorApi
            if (typeof err === "object" && err.errorType === "ErrorApi") {
                if (err.errorCodeGroup === ErrorCode.NOT_FOUND) {
                    await modalCtl.forceDismiss()
                    toast.warn($enum.ERROR_CODE.translate(ErrorCode.NOT_FOUND), err)
                    return false
                }
            } else {
                throw e
            }
            return false
        }
    }
}
