<template>
    <div class="m-auto p-2 w-full md:w-2/3 2xl:w-1/2 bg-white rounded-md overflow-y-auto">
        <div class="px-5">
            <LangSection v-model="langs" @add="add" @remove="remove" />
        </div>
        <div class="flex flex-row flex-grow-0 gap-2 px-5 pt-5">
            <t-button @click="save">
                {{ $t('actions.apply') }}
            </t-button>
            <t-button color="white" @click="forceDismiss()">
                {{ $t('actions.cancel') }}
            </t-button>
        </div>
    </div>
</template>


<script lang="ts">
import { defineComponent } from 'vue';

import plugins from '@/plugins';
import { useValidation } from '@/composition/vuelidate';

import LangSection from './LangSection.vue'

export default defineComponent({
    name: 't-lang-modal',
    components: { LangSection },
    setup() {
        const { $modal, $store } = plugins

        const { refObj: langs, validationObj: langs$ } = useValidation(
            JSON.parse(JSON.stringify($store.account.user?.orga.langs || [])) as string[],
            { $isArray: true }
        )

        $modal.addLock(langs$.value.$uid)

        const added: string[] = []

        return {
            langs,

            add(l: string) {
                added.push(l)
            },

            remove(l: string) {
                const adIdx = added.indexOf(l)
                if (adIdx !== -1) added.splice(adIdx, 1)
            },

            forceDismiss: $modal.forceDismiss,

            async save() {
                if (langs$.value.$anyDirty)
                    await $store.account.updateOrga({ langs: langs.value })

                $modal.forceDismiss(added.length ? added[added.length - 1] : true)
            }
        }

    }
})
</script>