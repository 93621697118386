<template>
  <SwitchGroup as="div" class="inline-flex items-center justify-between" :class="sizes.centerWrap">
    <span class="flex-grow flex flex-col">
      <SwitchLabel v-if="title" as="span" class="text-sm font-medium text-gray-900 pr-2" passive>{{ title }}
      </SwitchLabel>
      <!-- <SwitchDescription as="span" class="text-sm text-gray-500">Nulla amet tempus sit accumsan. Aliquet turpis sed sit lacinia.</SwitchDescription> -->
    </span>
    <SwitchWrapper v-model="enabled"
      :class="[enabled ? 'bg-ocean' : 'bg-gray-200', sizes.wrap + ' relative inline-flex flex-shrink-0 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ocean']">
      <span aria-hidden="true"
        :class="[enabled ? sizes.translateButton : 'translate-x-0', sizes.button + ' pointer-events-none inline-block rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
    </SwitchWrapper>
  </SwitchGroup>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'
import { Switch as SwitchWrapper, SwitchLabel, SwitchGroup } from '@headlessui/vue'

export default defineComponent({
  name: 't-switch',
  components: {
    SwitchWrapper, SwitchLabel, SwitchGroup
  },
  props: {
    modelValue: [String, Number, Boolean, Array, Object],
    title: String,
    size: {
      type: String as PropType<'s' | 'm'>,
      default: () => 'm'
    },
    on: {
      type: [String, Number, Boolean, Array, Object],
      default: () => true
    },
    onUndefined: Boolean,
    off: {
      type: [String, Number, Boolean, Array, Object],
      default: () => false
    },
    offUndefined: Boolean
  },
  setup(props, { emit }) {

    return {
      enabled: computed({
        get() {
          if (props.onUndefined && props.modelValue === undefined)
            return true
          if (props.offUndefined && props.modelValue === undefined)
            return false
          return JSON.stringify(props.modelValue) === JSON.stringify(props.on)
        },
        set(val: boolean) {
          emit('update:modelValue', val ? (props.onUndefined ? undefined : props.on) : (props.offUndefined ? undefined : props.off))
        }
      }),

      sizes: computed(() => (props.size === 'm') ? {
        wrap: 'h-6 w-11',
        centerWrap: 'mb-3 mt-1',
        button: 'h-5 w-5',
        translateButton: 'translate-x-5',
      } : {
        wrap: 'h-4 w-7',
        centerWrap: 'mb-2 mt-1',
        button: 'h-3 w-3',
        translateButton: 'translate-x-3',
      })

    }
  }
})
</script>