import { defineStore } from "pinia";

// cf: tailwind.config.js theme.extend.screens
const screens = {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xl2: 1536
}

export interface StoreDisplayState {
    breakpoint: ReturnType<typeof getBreakpoint>;
    windowWidth: number;
    windowHeight: number;
}

export const ID_STORE_DISPLAY = 'display'

export const useStoreDisplay = defineStore(ID_STORE_DISPLAY, {

    state: (): StoreDisplayState => ({
        breakpoint: getBreakpoint(window.innerWidth),
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight
    }),

    actions: {
        resize(win: { width: number, height: number }) {
            this.breakpoint = getBreakpoint(win.width)
            this.windowWidth = win.width
            this.windowHeight = win.height
        },

    }
})

export type StoreDisplay = ReturnType<typeof useStoreDisplay>

const getBreakpoint = (w = screens.lg) => ({
    sm: w <= screens.sm,

    md: w > screens.sm && w <= screens.md,
    mdAndUp: w > screens.sm,
    mdAndDown: w <= screens.md,

    lg: w > screens.md && w <= screens.lg,
    lgAndUp: w > screens.md,
    lgAndDown: w <= screens.lg,

    xl: w > screens.lg && w <= screens.xl,
    xlAndUp: w > screens.lg,
    xlAndDown: w <= screens.xl,

    xl2: w > screens.xl
})