<template>
  <t-input-icon type="email" :icon="EnvelopeIcon" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { EnvelopeIcon } from '@heroicons/vue/20/solid'

export default defineComponent({
  name: "t-input-email",
  mixins: [] as unknown as [GlobalComponentsMixin<'TInputIcon'>],
  setup: () => ({ EnvelopeIcon })
});
</script>
