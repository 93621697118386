<template>
    <t-button color="tertiary" round size="small"
        :class="iconSize === 'xl' ? 'h-16 w-16' : iconSize === 'md' ? 'h-12 w-12' : 'h-8 w-8'" v-bind="$attrs">
        <span class="text-gray-400" :class="iconSize === 'xl' ? 'p-1 w-12' : iconSize === 'md' ? 'p-2 w-10' : 'p-2 w-8'">
            <slot />
        </span>
    </t-button>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

export default defineComponent({
    name: 't-button-icon',
    props: {
        iconSize: {
            type: String as PropType<'sm' | 'md' | 'xl'>,
            default: () => 'sm'
        }
    },
    mixins: [] as unknown as [GlobalComponentsMixin<'TButton'>],
})

</script>
