import { inject, Plugin } from 'vue';

export interface EnvConfig {
    BASE_URL: string;
    DEV: boolean;
    PROD: boolean;

    FRONTEND_RUNTIME_STRIPE_KEY: string;
    FRONTEND_RUNTIME_GIT_COMMIT: string;
    FRONTEND_RUNTIME_GIT_VERSION: string;
}

export const envPlugin: Plugin = {
    async install(app) {
        const env = {}
        app.provide('env', env)
        app.config.globalProperties.$env = env as EnvConfig;
        const fetchEnv = await fetch('/env.json')
        Object.assign(env, { ...await fetchEnv.json(), ...(import.meta.env || {}) })
    }
}

export function useEnv() {
    return inject<EnvConfig>('env')!;
}