<template>
  <Suspense>
    <ion-app @click="($event: any) => $emitter.emit('app:click', $event)">
      <ion-split-pane :disabled="$route.meta.public !== undefined" content-id="main-content">
        <Sidebar />
        <ion-router-outlet id="main-content" />
      </ion-split-pane>
    </ion-app>
  </Suspense>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { IonApp, IonRouterOutlet, IonSplitPane } from '@ionic/vue'

import Sidebar from './AppSidebar.vue'

export default defineComponent({
  name: 't-app',
  components: {
    IonApp, IonRouterOutlet, IonSplitPane,
    Sidebar
  }
})
</script>

<style lang="postcss">
.t-table {

  table {

    @apply rounded-xl table-auto w-full;

    border-spacing: 0;
    border-collapse: separate;
    overflow: hidden;

    &>thead>tr>th {
      @apply border-y;
    }

    &>thead>tr>th:first-child {
      @apply border-l rounded-tl-xl;
    }

    &>thead>tr>th:last-child {
      @apply border-r rounded-tr-xl;
    }

    &>tbody>tr>td:first-child {
      @apply border-l;
    }

    &>tbody>tr>td:last-child {
      @apply border-r;
    }

    &>tbody>tr:last-child>td {
      @apply border-b;
    }

    &>tbody>tr:last-child>td:first-child {
      @apply border-l rounded-bl-xl;
    }

    &>tbody>tr:last-child>td:last-child {
      @apply border-r rounded-br-xl;
    }

    @apply relative mx-auto bg-white;

    thead {
      @apply sticky top-0 z-20 bg-white;

      >tr>th {
        @apply whitespace-nowrap align-top z-30 bg-gray-50 text-xs font-medium text-gray-500 uppercase tracking-wider p-2;
      }
    }

    tbody>tr>td {
      @apply px-1 md:px-2 py-2 whitespace-nowrap;

      >button {
        @apply p-1 hover:bg-gray-50 rounded-full;

        >svg {
          @apply h-5 w-5;
        }
      }
    }
  }
}

.t-table-bg {
  table>tbody>tr {
    &:nth-child(2n) {
      @apply bg-gray-50;
    }
  }
}

.t-table-resize {
  @apply w-full lg:w-3/4 xl:w-1/2;
}

.t-table-divide {
  table>tbody>tr {
    @apply border-b border-gray-200;
  }
}

.menu-dropdown {
  @apply md:invisible;
}

.menu-dropdown-visible-hover:hover,
.menu-dropdown-visible-hover:focus-within {
  .menu-dropdown {
    @apply visible;
  }
}
</style>