import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw, Router, RouteLocationRaw, RouteLocationNormalizedLoaded } from 'vue-router';
import { watch } from 'vue';

import { StoreAccount, useStoreAccount } from '@/store/store.account'
import { useStoreNav, StoreNav } from '@/store/store.nav'
import { buildDirtyLock } from '@/plugins/emitter'
import { modalController } from '@/plugins/modal'
import { i18n } from '@/plugins/i18n'

import { ErrorToast } from './util';

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    redirect: '/dashboard'
  }, {
    path: '/cgu',
    component: () => import('@/components/CGU.vue'),
    meta: { public: true },
  }, {
    path: '/cgs',
    component: () => import('@/components/CGS.vue'),
    meta: { public: true },
  }, {
    path: '/auth',
    component: () => import('@/views/auth/index.vue'),
    meta: { public: true },
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import('@/views/auth/Login.vue'),
        alias: "/login"
      }, {
        path: 'forgotpassword',
        name: 'forgotpassword',
        component: () => import('@/views/auth/ForgotPassword.vue'),
        alias: "/forgotpassword"
      }, {
        path: 'signup',
        name: 'signup',
        component: () => import('@/views/auth/Signup.vue'),
        alias: "/signup"
      }, {
        path: 'changepassword',
        name: 'changepassword',
        component: () => import('@/views/auth/ChangePassword.vue'),
        alias: "/changepassword"
      }, {
        path: 'welcome',
        name: 'welcome',
        component: () => import('@/views/auth/Welcome.vue'),
        alias: "/welcome"
      }
    ]
  }, {
    path: '/',
    component: () => import('@/views/main/index.vue'),
    children: [

      {
        path: '404',
        name: '404',
        component: () => import('@/views/main/404.vue'),
      },

      // settings
      {
        path: 'settings',
        component: () => import('@/views/main/settings/index.vue'),
        meta: {
          get breadcrumb() { return i18n.t('sitemap.settings.root') }
        },
        children: [
          {
            path: 'account',
            name: 'settings_account',
            component: () => import('@/views/main/settings/Account.vue'),
            meta: {
              get breadcrumb() { return i18n.t('sitemap.settings.account') }
            }
          }, {
            path: 'business',
            name: 'settings_activity',
            component: () => import('@/views/main/settings/Activity.vue'),
            meta: {
              get breadcrumb() { return i18n.t('sitemap.settings.activity') }
            }
          }, {
            path: 'catalog',
            name: 'settings_catalog',
            component: () => import('@/views/main/settings/Catalog.vue'),
            meta: {
              get breadcrumb() { return i18n.t('sitemap.settings.catalog') }
            }
          }, {
            path: 'billing',
            name: 'settings_billing',
            component: () => import('@/views/main/settings/Billing.vue'),
            meta: {
              get breadcrumb() { return i18n.t('sitemap.settings.billing') }
            }
          }, {
            path: 'subscription',
            name: 'settings_subscription',
            component: () => import('@/views/main/settings/Subscription.vue'),
            meta: {
              get breadcrumb() { return i18n.t('sitemap.settings.subscription') }
            }
          }, {
            path: 'cgu',
            name: 'settings_cgu',
            component: () => import('@/components/CGU.vue'),
          }, {
            path: 'cgs',
            name: 'settings_cgs',
            component: () => import('@/components/CGS.vue'),
          }, {
            path: '',
            redirect: '/settings/account'
          }, {
            path: ':pathMatch(.*)',
            name: 'settings',
            redirect: '/settings/account'
          }
        ]
      },

      // directory
      {
        path: 'directory',
        component: () => import('@/views/main/directory/index.vue'),
        meta: {
          get breadcrumb() { return { label: i18n.t('sitemap.business.directory.root'), path: '/directory' } }
        },
        children: [{
          path: 'companies',
          name: 'companies',
          meta: {
            get breadcrumb() { return { label: i18n.t('sitemap.business.directory.companies'), path: '/directory/companies' } }
          },
          component: () => import('@/views/main/directory/Directory.vue'),
          props: { of: 'companies' }
        }, {
          path: 'contacts',
          name: 'contacts',
          meta: {
            get breadcrumb() { return { label: i18n.t('sitemap.business.directory.contacts'), path: '/directory/contacts' } }
          },
          component: () => import('@/views/main/directory/Directory.vue'),
          props: { of: 'contacts' }
        }, {
          path: 'contact/:id(\\d+)',
          name: 'contact',
          meta: {
            get breadcrumb() { return { label: i18n.t('sitemap.business.directory.contacts'), path: '/directory/contacts' } }
          },
          component: () => import('@/views/main/directory/Contact.vue')
        }, {
          path: 'company/:id(\\d+)',
          name: 'company',
          meta: {
            get breadcrumb() { return { label: i18n.t('sitemap.business.directory.companies'), path: '/directory/companies' } }
          },
          component: () => import('@/views/main/directory/Company.vue')
        }, {
          path: '',
          name: 'directory',
          redirect: '/directory/companies'
        }, {
          path: '/directory/:pathMatch(.*)',
          redirect: '/directory/companies'
        }]
      },

      // prospect
      {
        path: 'prospect',
        name: 'prospect',
        component: () => import('@/views/main/prospect/index.vue')
      },

      // project
      {
        path: 'projects',
        meta: {
          get breadcrumb() { return { label: i18n.t('sitemap.management.projects'), path: '/projects' } }
        },
        children: [{
          path: '',
          name: 'projects',
          component: () => import('@/views/main/project/Projects.vue'),
        }, {
          path: '/project/:id(\\d+)',
          name: 'project',
          component: () => import('@/views/main/project/Project.vue'),
          props: { edit: false }
        }, {
          path: '/project/edit',
          name: 'project_new',
          component: () => import('@/views/main/project/Project.vue'),
          props: { edit: true }
        }, {
          path: '/project/:id(\\d+)/edit',
          name: 'project_edit',
          component: () => import('@/views/main/project/Project.vue'),
          props: { edit: true }
        }]
      },

      // archive
      {
        path: 'archive',
        children: [{
          path: '',
          name: 'archive',
          component: () => import('@/views/main/project/Archived.vue'),
          meta: {
            get breadcrumb() { return i18n.t('sitemap.management.archive') }
          },
        }, {
          path: '/archive/project/:id(\\d+)',
          name: 'archive_project',
          component: () => import('@/views/main/project/Project.vue'),
          props: { edit: false }
        }]
      },

      // invoice
      {
        path: 'invoices',
        meta: {
          get breadcrumb() { return { label: i18n.t('sitemap.management.invoices.root'), path: '/invoices' } }
        },
        children: [{
          path: '',
          name: 'invoices',
          redirect: '/invoices/wip'
        }, {
          path: 'wip',
          name: 'invoices_wip',
          meta: {
            get breadcrumb() { return { label: i18n.t('sitemap.management.invoices.wip'), path: '/invoices/wip' } }
          },
          component: () => import('@/views/main/invoice/Invoices.vue'),
          props: { isWip: true }
        }, {
          path: 'sended',
          name: 'invoices_sended',
          meta: {
            get breadcrumb() { return { label: i18n.t('sitemap.management.invoices.sended'), path: '/invoices/sended' } }
          },
          component: () => import('@/views/main/invoice/Invoices.vue'),
          props: { isWip: false }
        }, {
          path: '/invoice/:id(\\d+)/edit',
          name: 'invoice',
          component: () => import('@/views/main/invoice/Invoice.vue')
        }, {
          path: '/invoice/edit',
          name: 'invoice_new',
          component: () => import('@/views/main/invoice/Invoice.vue')
        }]
      },

      // admin

      {
        path: 'admin',
        component: () => import('@/views/main/admin/index.vue'),
        meta: { breadcrumb: ["Administration"], admin: true },
        children: [{
          path: 'components',
          name: 'admin_components',
          component: () => import('@/views/main/admin/components/index.vue'),
          meta: { breadcrumb: ["Composants"] }
        }, {
          path: 'i18n',
          name: 'admin_i18n',
          component: () => import('@/views/main/admin/I18n.vue'),
        }, {
          path: 'users',
          name: 'admin_users',
          component: () => import('@/views/main/admin/Users.vue'),
        }, {
          path: 'doc',
          name: 'admin_doc',
          component: () => import('@/views/main/admin/Doc.vue'),
          meta: { breadcrumb: ["Documentation"] }
        }, {
          path: '',
          name: 'admin',
          redirect: '/admin/components'
        }, {
          path: ':pathMatch(.*)',
          redirect: '/admin/components'
        }]
      },

      // 
      {
        path: 'dashboard',
        name: 'dashboard',
        component: () => import('@/views/main/dashboard/index.vue')
      }
    ]
  }, {
    path: '/:pathMatch(.*)',
    name: 'bad-not-found',
    redirect: to => ({ path: '/404', query: { from: to.fullPath } })
  }
]
const mainHistory = createWebHistory()
const mainRouter = createRouter({
  history: mainHistory,
  routes,
  async scrollBehavior(to, from, savedPosition) {
    if (savedPosition && (!!savedPosition.top || !!savedPosition.left))
      return savedPosition;

    if (to.hash) {
      await mainRouter.isReady()

      for (let i = 0; i < 9; i++) {

        // TODO: better solution waiting app ready
        //  element to.hash not ready ? transition / app loading / ...
        //  pray not to be in the middle of a transition

        const offset: number = document.getElementById(to.hash.replace(/^#/, ''))?.offsetTop || 0;
        if (!offset) {
          await new Promise(r => setTimeout(r, 100));
          continue;
        }

        const child = document.getElementById('scroll-child');
        if (child) {
          child.scrollTo({ top: offset, behavior: "smooth" })
          return { top: 0 }
        } else {
          console.warn('no scroll-child element')
          return { top: offset }
        }

      }
    }

    return { left: 0, top: 0 };
  }
})

function extend(this: Router) {

  const _locks = buildDirtyLock()
  let _init = false
  let _resetGoBack = undefined as NodeJS.Timeout | undefined

  let storeAccount: null | StoreAccount = null
  let storeNav: null | StoreNav = null

  const init = () => {

    this.beforeEach(async function guard(to, from, next) {

      function nextlog(_to?: string | false) {
        if (typeof _to === 'string') {
          if (import.meta.env.DEV)
            console.debug('[ROUTER]', from.fullPath || from.path, '=>', to.fullPath || to.path, '=>', _to)
          if (storeNav)
            storeNav.mutAddHistory(_to)
          if (_to === '/login' && to.fullPath && !to.fullPath.startsWith('/404') && !to.fullPath.startsWith('/login')) {
            next(`/login?from=${to.fullPath}`)
          } else {
            next(_to)
          }

        } else if (_to === false) {
          if (import.meta.env.DEV)
            console.debug('[ROUTER]', from.fullPath || from.path, '=>', to.fullPath || to.path, 'LOCKED')
          next(false)
        } else {
          if (import.meta.env.DEV)
            console.debug('[ROUTER]', from.fullPath || from.path, '=>', to.fullPath || to.path)
          if (storeNav)
            storeNav.mutAddHistory(to.fullPath)
          next()
        }

      }

      if (!storeAccount) storeAccount = useStoreAccount()
      if (!storeNav) storeNav = useStoreNav()


      if (!_init) {
        _init = true
        // wait store initialized
        if (!await storeAccount.init() && !to.meta.public)
          return nextlog('/login')

        // page 404 reload 
        if (to.path === '/404' && to.query.from) {
          storeNav.$state.goBack = true
          return nextlog(to.query.from as string)
        }
      }

      if (!to.meta.public) {
        if (!storeAccount.$state.user)
          return nextlog('/login')
        else if (!storeAccount.$state.user.lastname || !storeAccount.$state.user.firstname)
          return nextlog('/welcome')
        else if (storeAccount.isAdmin && !to.meta.admin && to.name !== 'settings_account')
          return nextlog('/settings/account')
        else if (!storeAccount.isAdmin && to.meta.admin)
          return nextlog('/')
      } else {
        if (storeAccount.$state.user && storeAccount.$state.user.lastname && storeAccount.$state.user.firstname)
          return nextlog('/')
      }


      if (!(await modalController.closeAllModals()))
        return nextlog(false)

      if (_locks.isLocked) {
        const cancelModification = await modalController.open({
          title: 'Modification non enregistrée',
          message: 'Vous avez des modifications non enregistrées. Voulez-vous continuer et perdre ces modifications ?'
        })
        if (cancelModification === true) {
          _locks.unlock()
          return nextlog()
        }
        return nextlog(false)
      }

      nextlog()
    })

    this.resolve
  }

  init()

  return {
    /** Tool to parse params or query */
    parser: generateParser(this),

    /**
     * Watch current current route.
     * Skip changes of path. Trigger only on changes of params / query / ...
     */
    watchRoute: (call: (r: RouteLocationNormalizedLoaded) => void) => {
      const currentPath = this.currentRoute.value.path
      watch(
        this.currentRoute,
        r => { if (r.path === currentPath) call(r) },
        { flush: 'post' }
      )
    },

    go404: () => {
      if (_resetGoBack) {
        clearTimeout(_resetGoBack)
        _resetGoBack = undefined
      }

      if (storeNav && storeNav.$state.goBack) {
        if (storeNav.$state.history.length > 2) {
          storeNav.mutPopHistory(2)
          if (storeNav.lastHistory)
            return this.replace(storeNav.lastHistory)
        }

        storeNav.$state.goBack = false
        return this.replace('/')
      }

      return this.replace({ path: '/404', query: { from: window.location.pathname }, force: true })
    },

    afterEachResolved: () => {
      if (storeNav?.$state.goBack)
        _resetGoBack = setTimeout(
          () => { if (storeNav) storeNav.$state.goBack = false },
          500
        )
    },

    /**
     * router.push +:
     *  * unlock
     */
    push2: (to: RouteLocationRaw, { unlock }: { unlock?: true } = {}) => {
      if (unlock) _locks.unlock()
      return this.push(to)
    },

    /**
     * router.go +:
     *  * unlock 
     *  * callback
     *  * skip all 404
     */
    go2: (delta: number, { unlock, callback }: { unlock?: true; callback?: (() => void) } = {}) => {
      if (unlock) _locks.unlock()
      if (storeNav) {
        if (delta < 0) storeNav.mutPopHistory(-delta)
        storeNav.$state.goBack = true
      }

      if (callback) {
        const unlisten = mainHistory.listen(() => { unlisten(); callback!(); })
      }
      this.go(delta)
    },

    /**
     * router.replace +:
     *  * unlock
     */
    replace2: (to: RouteLocationRaw, { unlock }: { unlock?: true } = {}) => {
      if (unlock) _locks.unlock()
      return this.replace(to)
    }
  }
}

export type Ext = ReturnType<typeof extend>
const router = Object.assign(mainRouter, extend.call(mainRouter)) as Router & Ext
export default router

function generateParser(_router: Router) {
  function getQueryOrParams<T>(key: string, parser: (value: string) => T | undefined, params: boolean, array?: false, optional?: false): T
  function getQueryOrParams<T>(key: string, parser: (value: string) => T | undefined, params: boolean, array: true, optional?: false): T[]
  function getQueryOrParams<T>(key: string, parser: (value: string) => T | undefined, params: boolean, array: false, optional: true): T | undefined
  function getQueryOrParams<T>(key: string, parser: (value: string) => T | undefined, params: boolean, array: true, optional: true): T[] | undefined
  function getQueryOrParams<T>(key: string, parser: (value: string) => T | undefined, params = false, array = false, optional = false) {
    const valueParent = params ? _router.currentRoute.value.params : _router.currentRoute.value.query

    try {
      if (key in valueParent) {
        const value = valueParent[key] as string | string[]
        if (array) {
          if (Array.isArray(value))
            return value.map(v => parser(v)).filter(v => v !== undefined)
          else
            return [parser(value)]
        } else {
          if (typeof value === 'string')
            return parser(value)
        }
      }

      if (optional)
        return array ? [] : undefined

      _router.go404()
      throw new ErrorToast('warn', `wrong url ${params ? 'params' : 'query'}: ${JSON.stringify(valueParent)}`)
    } catch (e: any) {
      _router.go404()
      throw e
    }
  }

  function parserOptArray<T>(parser: (value: string) => T | undefined, params: boolean) {

    function getOpt(key: string): T | undefined
    function getOpt(key: string, fallback: T): T
    function getOpt(key: string, fallback?: T) {
      const result = getQueryOrParams(key, parser, params, false, true)
      if (result === undefined && fallback !== undefined)
        return fallback
      return result
    }

    function getArrayOpt(key: string): T[] | undefined
    function getArrayOpt(key: string, fallback: T[]): T[]
    function getArrayOpt(key: string, fallback?: T[]) {
      const result = getQueryOrParams(key, parser, params, true, true)
      if (result === undefined && fallback !== undefined)
        return fallback
      return result
    }

    return {
      /** get required value */
      get(key: string) {
        return getQueryOrParams(key, parser, params)
      },
      /** get optional value */
      getOpt,
      /** get required values */
      getArray(key: string) {
        return getQueryOrParams(key, parser, params, true, false)
      },
      /** get optional values */
      getArrayOpt
    }
  }

  function parserQueryOrParams(params: boolean) {
    return {
      int: parserOptArray(
        v => {
          const vv = parseInt(v)
          if (!isNaN(vv)) return vv
        },
        params
      ),
      bool: parserOptArray(
        v => v === '1' || v === 'true',
        params
      ),
      string: parserOptArray(
        v => v,
        params
      )
    }
  }

  return {
    query: parserQueryOrParams(false),
    params: parserQueryOrParams(true)
  }
}