<template>
  <t-input-icon type="url" :icon="GlobeAltIcon" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { GlobeAltIcon } from '@heroicons/vue/20/solid'

export default defineComponent({
  name: "t-input-url",
  mixins: [] as unknown as [GlobalComponentsMixin<'TInputIcon'>],
  setup: () => ({ GlobeAltIcon })
});
</script>
