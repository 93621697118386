import { Plugin, inject } from 'vue'
import { UnaryInterceptor, Request, UnaryResponse, Metadata, RpcError } from "grpc-web"

import { UserServiceClient } from "@tolemac/grpc_web_api/tolemac/pub/user.client"
import { OrgaServiceClient, OrgaStatsServiceClient, ChargeServiceClient, TargetServiceClient, TaxRateServiceClient } from "@tolemac/grpc_web_api/tolemac/pub/orga.client"
import { CompanyLabelServiceClient, CompanyServiceClient, CompanyStatsServiceClient } from "@tolemac/grpc_web_api/tolemac/pub/company.client"
import { ContactLabelServiceClient, ContactServiceClient, ExchangeServiceClient } from "@tolemac/grpc_web_api/tolemac/pub/contact.client"
import { ServiceServiceClient } from "@tolemac/grpc_web_api/tolemac/pub/catalog.client"
import { SubscriptionServiceClient } from "@tolemac/grpc_web_api/tolemac/pub/subscription.client"
import { ProjectServiceClient, ProjectLabelServiceClient, ProjectStatsServiceClient } from "@tolemac/grpc_web_api/tolemac/pub/project.client"
import { InvoiceServiceClient, InvoiceStatsServiceClient } from "@tolemac/grpc_web_api/tolemac/pub/invoice.client"

import { IEventSubscriptionRequest, EventSubscriptionRequest } from "@tolemac/grpc_web_api/tolemac/pub/test"
import { TestServiceClient } from "@tolemac/grpc_web_api/tolemac/pub/test.client"

import { IFileLink } from '@tolemac/grpc_web_api/tolemac/pub/common'

import { Store } from '@/plugins/store'

const backenUrl = window.location.origin

const HEADER_ACCESS = "authorization"
const HEADER_REFRESH = "authorization-refresh"
const HEADER_EMPTY_ARRAY = "tolemac-empty-array"
const HEADER_EMPTY_FIELD = "tolemac-empty-field"

/** Metadata with internal header to pass argument on grpc interceptor */
interface Meta extends Partial<Metadata> {

    /** json serialized: number / number[]  */
    skipErrorCode?: string;

    refreshToken?: 'true';
}

class AuthUnaryInterceptor implements UnaryInterceptor<any, any> {

    private _store: Store['account'] | null = null
    setStore(store: Store['account']) { this._store = store }
    get store() {
        if (!this._store) throw new Error('store not injected in authInterceptor')
        return this._store
    }

    intercept(request: Request<any, any>, invoker: (req: Request<any, any>) => Promise<UnaryResponse<any, any>>) {
        const method = request.getMethodDescriptor().getName();
        const metaReq = request.getMetadata() as Meta
        const message = request.getRequestMessage()

        // add token on request
        const token = this.store.token;
        if (token)
            if (metaReq.refreshToken === 'true') {
                metaReq[HEADER_REFRESH] = token.refresh;
                delete metaReq.refreshToken
            } else if (token.accessDecoded) {
                if (!token.accessDecoded.exp || Date.now() >= (token.accessDecoded.exp * 1000))
                    metaReq[HEADER_REFRESH] = token.refresh;
                else
                    metaReq[HEADER_ACCESS] = token.access;
            }

        let skipErrorCode: null | number[] = null
        if (metaReq.skipErrorCode) {
            skipErrorCode = JSON.parse(metaReq.skipErrorCode)
            if (!Array.isArray(skipErrorCode)) skipErrorCode = [skipErrorCode as any]
        }
        if (skipErrorCode)
            delete metaReq.skipErrorCode

        const { emptyArray, emptyField } = findEmpty(message)
        if (emptyArray.length)
            metaReq[HEADER_EMPTY_ARRAY] = JSON.stringify(emptyArray)
        if (emptyField.length)
            metaReq[HEADER_EMPTY_FIELD] = JSON.stringify(emptyField)

        return invoker(request).then(async (response: UnaryResponse<any, any>) => {

            logRequest(method, request.getRequestMessage(), response.getResponseMessage())

            const metaRep = response.getMetadata();
            if (metaRep[HEADER_ACCESS] && metaRep[HEADER_REFRESH])
                // login
                this.store.mutToken({
                    access: metaRep[HEADER_ACCESS],
                    refresh: metaRep[HEADER_REFRESH]
                })
            else if (metaRep[HEADER_ACCESS] && this.store.token?.refresh) {
                // refresh token with response
                const loginAs = this.store.token?.accessDecoded.userId &&
                    this.store.token?.refreshDecoded.userId &&
                    this.store.token?.accessDecoded.userId !== this.store.token?.refreshDecoded.userId

                this.store.mutToken({ access: metaRep[HEADER_ACCESS] })

                if (loginAs)
                    // "login as" timed out
                    window.location.reload()
            }

            return response;
        }).catch((e: ErrorApi & RpcError) => {
            const stack = e.stack?.split('\n')
                .map(l => l.split('@webpack-internal:///').pop()!)
                .filter(l => l.indexOf('node_modules/') === -1)
                .map(l => l.replace(/https?:\/\/[^\/]*\//, ''))
                .filter(l => l)

            let errorCode: number | undefined = undefined
            let errorDetail = e.metadata && e.metadata['error-detail']
            if (e.metadata && e.metadata['error-code']) {
                const _errorCode = parseInt(e.metadata['error-code'])
                if (!isNaN(_errorCode)) errorCode = _errorCode
                else if (!errorDetail) errorDetail = e.metadata['error-code']
            }
            if (!errorCode) errorCode = 2

            e.errorType = 'ErrorApi'
            e.method = method
            e.errorCode = errorCode
            // first three digit. Example: 40100 => 401
            e.errorCodeGroup = parseInt(`${e.errorCode}`.substring(0, 3))
            e.errorDetail = errorDetail
            e.stackList = stack

            logRequest(method, request.getRequestMessage(), undefined, e)

            throw e;
        });
    }
}

async function logRequest(method: string, req: any, res?: any, err?: any) {
    if (err) {
        console.groupCollapsed(`[API] ${method} throw an error. ${err?.message}`)
        console.log('req: ', JSON.parse(JSON.stringify(ifToObject(req))))
        console.log('terror: ', err)
        console.groupEnd()
    } else {
        if (!import.meta.env.DEV) return
        console.groupCollapsed(`[API] ${method}`)
        console.log('req: ', JSON.parse(JSON.stringify(ifToObject(req))))
        console.log('rep: ', JSON.parse(JSON.stringify(ifToObject(res))))
        console.groupEnd()
    }
}

function ifToObject(obj: any) {
    return (obj && obj.toObject && typeof obj.toObject === 'function') ? obj.toObject() : obj
}

function findEmpty(obj: any): { emptyArray: string[]; emptyField: string[] }
function findEmpty(obj: any, parentKey: string, emptyArray: string[]): void
function findEmpty(obj: any, parentKey = '', emptyArray: string[] = [], emptyField: string[] = []) {
    obj = ifToObject(obj)
    for (const k of Object.keys(obj)) {
        const child = obj[k]
        if (Array.isArray(child)) {
            if (!child.length)
                emptyArray.push(parentKey ? parentKey + '.' + k : k)
            child.forEach((c, cI) => findEmpty(c, parentKey ? parentKey + '.' + cI + '.' + k : k, emptyArray))
        } else if (child === null || child === undefined) {
            emptyField.push(parentKey ? parentKey + '.' + k : k)
        } else if (child && typeof child === 'object') {
            findEmpty(child, parentKey ? parentKey + '.' + k : k, emptyArray)
        }
    }
    if (!parentKey)
        return { emptyArray, emptyField }
}

class ApiHttp {

    private _store: Store['account'] | null = null
    setStore(store: Store['account']) { this._store = store }
    get store() {
        if (!this._store) throw new Error('store not injected in api http')
        return this._store
    }

    fetch(input: RequestInfo, options?: RequestInit) {

        const headers = {} as any;
        const token = this.store.token;
        if (token && token.accessDecoded) {
            if (!token.accessDecoded.exp || Date.now() > (token.accessDecoded.exp * 1000))
                headers[HEADER_REFRESH] = token.refresh;
            else
                headers[HEADER_ACCESS] = token.access;
        }

        return fetch(input, { ...options, ...{ headers } }).then(async rep => {
            if (rep.status >= 300) throw new Error(await rep.text())
            if (headers[HEADER_REFRESH])
                this.store.mutToken({ access: rep.headers.get(HEADER_ACCESS) || undefined })
            return rep
        })
    }

    async upload(f: IFileLink, data: any) {
        if (data instanceof File) {
            const formData = new FormData();
            formData.set('file', data)
            formData.set('link', JSON.stringify(f))
            await this.fetch('file/upload', {
                method: 'POST',
                body: formData
            })
        }
    }
}

class TestServiceClientImpl {

    async subscribe(mes: IEventSubscriptionRequest) {
        const testApi = new TestServiceClient(backenUrl);

        const stream = await testApi.subscribeevents(new EventSubscriptionRequest(mes))
        stream.on('data', response => {
            console.log('data', ifToObject(response));
        });
        stream.on('status', status => {
            console.log('status', status.code, status.details, status.metadata);
        });
        stream.on('end', () => {
            console.log('end') // stream end signal
        });
        stream.on("error", error => {
            console.log('error', error)
        })
        stream.on("metadata", metadata => {
            console.log('metadata', metadata)
        })
    }
}

class ApiImpl {

    user: UserServiceClient<Meta>
    orga: OrgaServiceClient<Meta>
    orgaStats: OrgaStatsServiceClient<Meta>
    charge: ChargeServiceClient<Meta>
    target: TargetServiceClient<Meta>
    taxRate: TaxRateServiceClient<Meta>
    company: CompanyServiceClient<Meta>
    companyLabel: CompanyLabelServiceClient<Meta>
    companyStats: CompanyStatsServiceClient<Meta>
    contact: ContactServiceClient<Meta>
    contactLabel: ContactLabelServiceClient<Meta>
    exchange: ExchangeServiceClient<Meta>
    service: ServiceServiceClient<Meta>
    payment: SubscriptionServiceClient<Meta>
    project: ProjectServiceClient<Meta>
    projectLabel: ProjectLabelServiceClient<Meta>
    projectStats: ProjectStatsServiceClient<Meta>
    invoice: InvoiceServiceClient<Meta>
    invoiceStats: InvoiceStatsServiceClient<Meta>

    http: ApiHttp
    test: TestServiceClientImpl

    private auth = new AuthUnaryInterceptor()

    constructor() {

        const grpcCliOptions = {
            unaryInterceptors: [this.auth]
            // TODO: streamInterceptors
        }

        this.user = new UserServiceClient(backenUrl, grpcCliOptions);
        this.orga = new OrgaServiceClient(backenUrl, grpcCliOptions);
        this.orgaStats = new OrgaStatsServiceClient(backenUrl, grpcCliOptions);
        this.charge = new ChargeServiceClient(backenUrl, grpcCliOptions);

        this.target = new TargetServiceClient(backenUrl, grpcCliOptions),
        // this.target = new Proxy(new TargetServiceClient(backenUrl, grpcCliOptions), {
        //     get(target, prop) {
        //         const origMethod = (target as any)[prop];
        //         if (typeof origMethod == 'function')
        //             return function (...args: any[]) { return origMethod.apply(target, args) }
        //     }
        // });

        this.taxRate = new TaxRateServiceClient(backenUrl, grpcCliOptions);
        this.company = new CompanyServiceClient(backenUrl, grpcCliOptions);
        this.companyLabel = new CompanyLabelServiceClient(backenUrl, grpcCliOptions);
        this.companyStats = new CompanyStatsServiceClient(backenUrl, grpcCliOptions);
        this.contact = new ContactServiceClient(backenUrl, grpcCliOptions);
        this.contactLabel = new ContactLabelServiceClient(backenUrl, grpcCliOptions);
        this.exchange = new ExchangeServiceClient(backenUrl, grpcCliOptions);
        this.service = new ServiceServiceClient(backenUrl, grpcCliOptions);
        this.payment = new SubscriptionServiceClient(backenUrl, grpcCliOptions);

        this.project = new ProjectServiceClient(backenUrl, grpcCliOptions);

        this.projectLabel = new ProjectLabelServiceClient(backenUrl, grpcCliOptions);
        this.projectStats = new ProjectStatsServiceClient(backenUrl, grpcCliOptions);
        this.invoice = new InvoiceServiceClient(backenUrl, grpcCliOptions);
        this.invoiceStats = new InvoiceStatsServiceClient(backenUrl, grpcCliOptions);

        this.http = new ApiHttp();
        this.test = new TestServiceClientImpl();
    }

    setStore(store: Store['account']) {
        this.auth.setStore(store)
        this.http.setStore(store)
    }
}


export type Api = Omit<ApiImpl, 'user' | 'orga'>
    & { user: Omit<UserServiceClient<Meta>, 'update' | 'getme' | 'login' | 'signup'> }
    & { orga: Omit<OrgaServiceClient<Meta>, 'update' | 'getmine'> };

export type ApiStore = ApiImpl;

export const apiPlugin: Plugin = {
    install: (app) => {
        const api = new ApiImpl()
        app.config.globalProperties.$api = api
        app.provide('api', api)
    }
}

export function useApi() {
    return inject<ApiImpl>('api') as Api;
}