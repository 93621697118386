import dateFnsFormat from 'date-fns/esm/format'
import { Locale as DateFnsLocale } from 'date-fns'

import { DateFnsFormatDate, DateFnsFormatNumber, DEFAULT_DATEFNS_LOCALE } from './cst.datefns.locales'
import { TLocalization, TOLEMAC_LANGS } from './conf'

const modules = import.meta.glob('../../../node_modules/date-fns/esm/locale/*/index.js', { import: 'default' })
async function loadDateFnsModule(dateFnsLocale: DateFnsFormatDate) {
    const moduleKey = `../../../node_modules/date-fns/esm/locale/${dateFnsLocale}/index.js`
    return modules[moduleKey]() as Promise<DateFnsLocale>
}

export async function importDateFnsLocale(loc: DateFnsFormatDate) {
    return loadDateFnsModule(loc)
        .catch(e => {
            console.warn(`Error loading language for date-fns: '${loc}'. Use fallback language: ${DEFAULT_DATEFNS_LOCALE}`, e)
            return loadDateFnsModule(DEFAULT_DATEFNS_LOCALE)
        })
}

/**
 * ex fr:
 *  * full: lundi 1 janvier 2024
 *  * long: 1 janvier 2024 
 *  * medium: 1 janv. 2024
 *  * short: 01/01/2024
 */
type FORMAT = 'full' | 'long' | 'medium' | 'short' | string

export class Formatter {

    private _locale?: DateFnsLocale
    private _dateLocale?: DateFnsLocale
    private _timeLocale?: DateFnsLocale
    private _currency?: string
    private _unit?: Unit


    constructor(private conf: TLocalization) {}

    get currency() {
        return this._currency!
    }

    get dateLocale() {
        return this._dateLocale!
    }

    get timeLocale() {
        return this._timeLocale!
    }

    ordinals(n: number) {
        return this._locale!.localize ? this._locale!.localize!.ordinalNumber(n) : `${n}`
    }

    quarter(n: number) {
        return this._locale!.localize ? this._locale!.localize!.quarter(n) : `${n}`
    }

     // timezone

    get timezone() {
        return this.conf.timezone
    }

    /** relative timezone offset. browser timezone relative to orga timezone */
    timezoneOffset(date: Date | number) {
        if (typeof date === 'number') date = new Date(date)
        return this.getOffset(date, this.conf.timezone)
    }

    private getOffset(date = new Date(), timeZone = 'UTC') {
        // const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
        const utcDate = new Date(date.toLocaleString('en-US'));
        const tzDate = new Date(date.toLocaleString('en-US', { timeZone }));
        return (tzDate.getTime() - utcDate.getTime()) / 6e4;
    }

    async changeConf(conf: Partial<TLocalization>) {
        if (conf.currency && (!this._currency || conf.currency !== this.conf.currency)) {
            this._currency =
                Intl.NumberFormat('fr', { style: 'currency', currency: conf.currency, currencyDisplay: 'narrowSymbol', maximumFractionDigits: 0 })
                    .formatToParts()
                    .find(p => p.type === "currency")!.value
            this.conf.currency = conf.currency
        }
        if (conf.date && (!this._dateLocale || conf.date !== this.conf.date)) {
            this.conf.date = conf.date as DateFnsFormatDate
            this._dateLocale = await importDateFnsLocale(this.conf.date)
        }
        if (conf.time && (!this._timeLocale || conf.time !== this.conf.time)) {
            this.conf.time = conf.time as DateFnsFormatDate
            this._timeLocale = await importDateFnsLocale(this.conf.time)
        }
        if (conf.number && conf.number !== this.conf.number) {
            this.conf.number = conf.number as DateFnsFormatNumber
        }
        if (conf.timezone)
            this.conf.timezone = conf.timezone

        if (conf.lang && (!this._unit || conf.lang !== this.conf.lang)) {
            this._unit = new Unit(conf.lang)
            this._locale = await importDateFnsLocale(conf.lang)
        }
    }

    get unit() {
        return this._unit!
    }
    
    /**
     * @param timestamp 
     * @param {Object} opt - options.
     * @param {string} opt.format - https://date-fns.org/v2.29.3/docs/format
     * @param {false | undefined} opt.utc - utc by default
     * @param {boolean} opt.withTime - display hour, minutes
     * @returns 
     */
    private _date(timestamp?: number | Date, opt: { format?: FORMAT, utc?: false, withTime?: true } = {}) {
        if (!timestamp) return ''
        if (this._dateLocale && this._dateLocale.formatLong && this._timeLocale && this._timeLocale.formatLong)
            if (!opt.format) {
                if (opt.withTime)
                    opt.format = `${this._dateLocale.formatLong.date({ width: 'short' })} ${this._timeLocale.formatLong.time({ width: 'short' })}`
                else
                    opt.format = this._dateLocale.formatLong.date({ width: 'short' })
            } else if (opt.format === 'full' || opt.format === 'long' || opt.format === 'medium' || opt.format === 'short') {
                if (opt.withTime)
                    opt.format = this._dateLocale?.formatLong?.dateTime({ width: opt.format })
                else
                    opt.format = this._dateLocale?.formatLong?.date({ width: opt.format })
            }


        if (typeof timestamp === 'number')
            timestamp = new Date(timestamp)

        if (opt.utc === undefined)
            timestamp = new Date(timestamp.getTime() + (timestamp.getTimezoneOffset() * 60 * 1000))
        else
            timestamp = new Date(timestamp.toLocaleString('en-US', { timeZone: this.conf.timezone }))

        return opt.format ?
            dateFnsFormat(timestamp, opt.format, { locale: this._dateLocale! })
            :
            opt.withTime ? timestamp.toLocaleString() : timestamp.toLocaleDateString()
    }

    // date

    date(timestamp?: number | Date) {
        return this._date(timestamp)
    }

    dateF(timestamp: undefined | number | Date, format: FORMAT) {
        return this._date(timestamp, { format })
    }

    dateLocal(timestamp?: number | Date) {
        return this._date(timestamp, { utc: false })
    }

    dateLocalF(timestamp: undefined | number | Date, format: FORMAT) {
        return this._date(timestamp, { format, utc: false })
    }

    // datetime

    datetime(timestamp?: number | Date) {
        return this._date(timestamp, { withTime: true })
    }

    datetimeF(timestamp: undefined | number | Date, format: FORMAT) {
        return this._date(timestamp, { format, withTime: true })
    }

    datetimeLocal(timestamp?: number | Date) {
        return this._date(timestamp, { utc: false, withTime: true })
    }

    datetimeLocalF(timestamp: undefined | number | Date, format: FORMAT) {
        return this._date(timestamp, { format, utc: false, withTime: true })
    }

    duration(minutes?: number, hideZero = false) {
        if (!minutes && !(minutes === 0 && hideZero === false)) return ''
        const min = minutes % 60;
        const hour = (minutes - min) / 60
        return `${hour} h ` + `0${min}`.substr(-2)
    }

    // number

    number(value?: number | null, precision = 2, addZero = false, opt: Intl.NumberFormatOptions = {}) {
        if (typeof value !== 'number' || isNaN(value)) return ''
        return new Intl.NumberFormat(this.conf.number, {
            maximumFractionDigits: precision,
            useGrouping: true,
            minimumFractionDigits: addZero ? precision : 0,
            ...opt
        }).format(value)
        // return parseFloat(value.toFixed(precision).toString()).toLocaleString(this.conf.number, {
        //     maximumFractionDigits: precision,
        //     useGrouping: true,
        //     minimumFractionDigits: addZero ? precision : 0,
        //     ...opt
        // })
    }

    /** display number with currency */
    price(value?: number | null, opt: (Intl.NumberFormatOptions & { precision?: number; addZero?: boolean }) = {}) {
        if (!opt.style) opt.style = "currency"
        if (!opt.currency) opt.currency = this.conf.currency
        if (!opt.currencyDisplay) opt.currencyDisplay = 'narrowSymbol'

        return this.number(value, opt.precision ?? 2, opt.addZero ?? true, opt)
    }
}


type UnitType = 'acre' | 'bit' | 'byte' | 'celsius' | 'centimeter' | 'day' | 'degree' | 'fahrenheit' | 'fluid-ounce' | 'foot' | 'gallon' | 'gigabit' | 'gigabyte' | 'gram' | 'hectare' | 'hour' | 'inch' | 'kilobit' | 'kilobyte' | 'kilogram' | 'kilometer' | 'liter' | 'megabit' | 'megabyte' | 'meter' | 'mile' | 'mile-scandinavian' | 'milliliter' | 'millimeter' | 'millisecond' | 'minute' | 'month' | 'ounce' | 'percent' | 'petabyte' | 'pound' | 'second' | 'stone' | 'terabit' | 'terabyte' | 'week' | 'yard' | 'year'

class Unit {

    cache = {
        arrow: {} as Record<UnitType, string>,
        short: {} as Record<UnitType, string>,
        long: {} as Record<UnitType, string>,
        plural: {} as Record<UnitType, string>
    }

    constructor(private local: TOLEMAC_LANGS = 'fr') { }

    private unit(unit: UnitType, unitDisplay: "short" | "long" | "narrow", plural = false) {
        return Intl.NumberFormat(this.local, { style: 'unit', unit, unitDisplay })
            .formatToParts(plural ? 2 : 1)
            .find(p => p.type === 'unit')?.value || ''
    }

    arrow(unit: UnitType) {
        if (!this.cache.arrow[unit])
            this.cache.arrow[unit] = this.unit(unit, 'narrow')
        return this.cache.arrow[unit]
    }

    short(unit: UnitType) {
        if (!this.cache.short[unit])
            this.cache.short[unit] = this.unit(unit, 'short')
        return this.cache.short[unit]
    }

    long(unit: UnitType) {
        if (!this.cache.long[unit])
            this.cache.long[unit] = this.unit(unit, 'long')
        return this.cache.long[unit]
    }

    /** long plural */
    plural(unit: UnitType) {
        if (!this.cache.plural[unit])
            this.cache.plural[unit] = this.unit(unit, 'long', true)
        return this.cache.plural[unit]
    }

}