import { computed, isRef, reactive, ref, watch, nextTick, isReactive, isReadonly, unref, Ref } from 'vue'
import {
    VArgs,
    ValidationOf,
    ValidatorFn,
    ValidationRuleBasic,
    GlobalConfig,
    ErrorObject,
    ValidatorResponse,
    BaseValidationKey,
    ValidationRuleExternal,
    RootValidationOf,
    ValidationRuleObject
} from './vuelidate.type';
import { JSONPath } from 'jsonpath-plus'


let printDiff: ((validationObj: any, refObj: any, name?: string) => void) | undefined = undefined
if (import.meta.env.DEV) {
    import('./vuelidate.dev').then(m => printDiff = m.printDiff)
}
/**
 * Fork vuelidate 2.0.0-alpha.23:
 *  - typescript
 *  - remove external result
 *  - remove vue instance
 *  - fix autodirty on reset with $getRecord $setRecord $record
 *  - add feature isArray
 *  - add context args on call rule to access root / parent / path
 */


/**
 * Sort out the validation object into:
 * – rules = validators for current state tree fragment
 * - nestedValidators = nested state fragments keys that might contain more validators
 * – config = configuration properties that affect this state fragment
 */
function sortValidations<T>(validationsRaw = {} as VArgs<T> | Ref<VArgs<T>>) {
    const validations = unref(validationsRaw)

    const rules: Record<string, ValidationRuleBasic | ValidationRuleExternal> = {}
    const nestedValidators = {} as any;
    const config: GlobalConfig = {}

    for (const key in validations) {
        const v = validations[key] as any

        if (v === undefined) continue

        switch (true) {
            // If it is already normalized, use it
            case v.$external === true:
            case typeof v.$validator === 'function':
                rules[key] = v
                break
            // If it is just a function, normalize it first
            // into { $validator: <Fun> }
            case typeof v === 'function':
                rules[key] = { $validator: v }
                break
            // Catch $-prefixed properties as config
            case key.startsWith('$'):
                config[key as keyof GlobalConfig] = v
                break
            // If it doesn’t match any of the above,
            // treat as nestedValidators state property
            default:
                nestedValidators[key] = v
        }
    }

    return {
        rules,
        nestedValidators: nestedValidators as { [key: string]: VArgs<any> },
        config
    }
}

function getPath(root: any, path: string | string[]) {
    path = Array.isArray(path) ? path : path.split('.')
    return path.reduce(
        (child, key) => Array.isArray(child) ? child[parseInt(key)] : child[key],
        root
    )
}

function isValidationRuleExternal(o: ValidationRuleObject): o is ValidationRuleExternal {
    return '$external' in o && o.$external === true
}

/**
 * Calls a validation rule by unwrapping its value first from a ref.
 */
function callRule<T>(rule: ValidatorFn, root: object, value: Ref<T>, path?: string) {
    return rule.call(
        {},
        unref(value),
        {
            path,
            root,
            get parent() {
                if (path) return getPath(unref(root), path.split('.').slice(0, -1));
                return undefined
            },
            getParent<T>(index: number | null) {
                if (index === null) return unref(root) as any as T
                if (path) return getPath(unref(root), path.split('.').slice(0, index ? -index : undefined)) as T;
                return undefined
            }
        }
    )
}

/**
 * Normalizes the validator result
 * Allows passing a boolean of an object like `{ $valid: Boolean }`
 */
function normalizeValidatorResponse(result: ValidatorResponse | boolean) {
    return (typeof result === 'boolean') ? !result : (result.$valid !== undefined ? !result.$valid : !result)
}

// /**
//  * Returns the result of an async validator.
//  */
// function createAsyncResult<T>(
//     rule: ValidatorFn,
//     getRoot: () => ValidationOf,
//     model: Ref<T>,
//     $pending: Ref<boolean>,
//     $dirty: Ref<boolean>,
//     { $lazy }: GlobalConfig,
//     $response: any,
//     path: Ref<string>,
//     jsonQueries = [] as Ref<any>[]
// ) {
//     const $invalid = ref(!!$dirty.value)
//     const $pendingCounter = ref(0)

//     $pending.value = false

//     const $validate = (...args: any[]) => {
//         // args.length === 0 => not call by watch
//         if ($lazy && !$dirty.value && args.length !== 0) return false

//         let ruleResult
//         // make sure we dont break if a validator throws
//         try {
//             ruleResult = callRule(rule, getRoot().$model as any, model, path.value)
//         } catch (err) {
//             // convert to a promise, so we can handle it async
//             ruleResult = Promise.reject(err)
//         }

//         $pendingCounter.value++
//         $pending.value = !!$pendingCounter.value
//         // ensure $invalid is false, while validator is resolving
//         $invalid.value = false

//         return Promise.resolve(ruleResult)
//             .then(data => {
//                 $pendingCounter.value--
//                 $pending.value = !!$pendingCounter.value
//                 $response.value = data
//                 $invalid.value = normalizeValidatorResponse(data)
//                 if ($invalid.value) $dirty.value = true
//                 return !$invalid.value
//             })
//             .catch((error) => {
//                 $pendingCounter.value--
//                 $pending.value = !!$pendingCounter.value
//                 $response.value = error
//                 $invalid.value = true
//                 $dirty.value = true
//                 return !$invalid.value
//             })
//     }

//     const $unwatch = watch(
//         [model, $dirty].concat(jsonQueries),
//         $validate,
//         { deep: typeof model === 'object' }
//     )

//     return { $invalid, $unwatch, $validate: $validate as () => Promise<boolean> }
// }

/**
 * Returns the result of a sync validator
 */
function createSyncResult<T>(
    rule: ValidatorFn,
    getRoot: () => ValidationOf,
    model: Ref<T>,
    $dirty: Ref<boolean>,
    { $lazy }: GlobalConfig,
    $response: any,
    path: Ref<string>,
    validatorName: string,
    jsonQueries = [] as string[]
) {
    const $invalid = ref(false)

    function $validate() {
        try {
            $response.value = callRule(rule, getRoot().$model as any, model, path.value) as boolean | ValidatorResponse
            $invalid.value = normalizeValidatorResponse($response.value)
        } catch (err) {
            $response.value = err
            $invalid.value = true
        }

        if ($invalid.value) $dirty.value = true

        return !$invalid.value
    }

    function validateJsonQueried() {
        const _root = getRoot()
        if (_root) jsonQueries
            .map(_path => _path.replace('*.', '$each.*.'))
            .map(_path => {
                const queried = JSONPath({ json: _root, path: _path });
                const queriedV = (Array.isArray(queried) ? queried : [queried])
                queriedV.forEach(ttt => {
                    if (!ttt?.$validate) return
                    if (path.value === ttt.$path || path.value + '.' + validatorName === ttt.$path) return
                    ttt.$validate()
                })

            })
    }

    const $validateByWatchDirty = () => {
        if ($lazy && !$dirty.value)
            $invalid.value = false
        else
            $validate()
    }

    const $validateByWatchModel = () => {
        if (jsonQueries.length)
            validateJsonQueried()

        $validateByWatchDirty()
    }

    const unwatchModel = watch(model, $validateByWatchModel)
    const unwatchDirty = watch($dirty, $validateByWatchDirty)

    const $unwatch = () => { unwatchModel(); unwatchDirty; }

    return { $invalid, $unwatch, $validate }
}


function unwrapObj(obj: any) {
    return Object.keys(obj).reduce((o, k) => {
        (o as any)[k] = unref(obj[k])
        return o;
    }, {} as any)
}

/**
 * Returns the validation result.
 * Detects async and sync validators.
 */
function createValidatorResult<T>(
    rule: ValidationRuleBasic | ValidationRuleExternal,
    getRoot: () => ValidationOf,
    model: Ref<T>,
    $dirty: Ref<boolean>,
    config: GlobalConfig,
    validatorName: string,
    propertyKey: Ref<string | number>,
    propertyPath: Ref<string>,
) {
    const $pending = ref(false)
    const $params = rule.$params || {}
    const $response = ref(null)
    let $invalid: Ref<boolean>
    let $unwatch: () => void
    let $validate: () => boolean

    if (isValidationRuleExternal(rule)) {
        $invalid = ref(false)
        $unwatch = () => { /** nop */ }
        $validate = () => !$invalid.value
        // } else if (rule.$async) {
        //     ({ $invalid, $unwatch, $validate } = createAsyncResult(
        //         rule.$validator,
        //         getRoot,
        //         model,
        //         $pending,
        //         $dirty,
        //         config,
        //         $response,
        //         propertyPath,
        //         rule.jsonQueries
        //     ))
    } else {
        ({ $invalid, $unwatch, $validate } = createSyncResult(
            rule.$validator!,
            getRoot,
            model,
            $dirty,
            config,
            $response,
            propertyPath,
            validatorName,
            rule.jsonQueries
        ))
    }

    const $message = computed(() =>
        (rule.$message && typeof rule.$message === 'function') ?
            rule.$message(
                unwrapObj({
                    $pending,
                    $invalid,
                    $params: unwrapObj($params), // $params can hold refs, so we unref them for easy access
                    $model: model,
                    $response,
                    $validator: validatorName,
                    $propertyPath: propertyPath,
                    $property: propertyKey.value
                })
            )
            : rule.$message || ''
    )

    const $path = ref(propertyPath.value + '.' + validatorName)

    return {
        $message,
        $params,
        $pending,
        $invalid,
        $response,
        $unwatch,
        $validate,
        $path
    }
}

type ValidationResult<T = unknown> = {
    $dirty: Ref<boolean>;
    $path: string;
    $touch(): void;
    $reset(): void;

    $error?: Ref<boolean>;
    $errors?: Ref<ErrorObject[]>;
    $silentErrors?: Ref<ErrorObject[]>;
    $invalid?: Ref<boolean>;
    $anyDirty?: Ref<boolean>;
    $pending?: Ref<boolean>;
    $unwatch?(): void;
    $validate?(): boolean;

} & {
        [ruleKey in keyof T]?: ValidationResult<T[ruleKey]>
    }

/**
 * Creates the main Validation Results object for a state tree
 * Walks the tree's top level branches
 */
function createValidationResults<T>(
    rules: Record<string, ValidationRuleObject>,
    getRoot: () => ValidationOf,
    model: Ref<T>,
    key: Ref<string | number>,
    path: Ref<string>,
    config: GlobalConfig,
) {
    // collect the property keys
    const ruleKeys = Object.keys(rules) as (keyof typeof rules)[]

    const $dirty = ref(false)

    const result: ValidationResult = {
        $dirty,
        $path: path.value,
        $touch: () => { if (!$dirty.value) $dirty.value = true },
        $reset: () => { if ($dirty.value) $dirty.value = false }
    }

    const validatorResults = ruleKeys.reduce((prev, ruleKey) => {
        prev[ruleKey] = createValidatorResult(
            rules[ruleKey],
            getRoot,
            model,
            $dirty,
            config,
            ruleKey,
            key,
            path
        )
        return prev
    }, {} as Record<keyof typeof rules, ReturnType<typeof createValidatorResult>>)

    result.$invalid = computed(() =>
        ruleKeys.some(ruleKey => unref(validatorResults[ruleKey].$invalid))
    )

    result.$validate = () =>
        ruleKeys
            .map(ruleKey => unref(validatorResults[ruleKey]).$validate())
            .find(x => x === false) === undefined

    result.$pending = computed(() =>
        ruleKeys.some(ruleKey => unref(validatorResults[ruleKey].$pending))
    )

    result.$error = computed(() =>
        result.$dirty.value ? result.$pending!.value || result.$invalid!.value : false
    )

    result.$silentErrors = computed(() => ruleKeys
        .filter(ruleKey => unref(validatorResults[ruleKey].$invalid))
        .map(ruleKey => {
            const res = validatorResults[ruleKey]
            return reactive({
                $propertyPath: path.value,
                $property: key.value,
                $validator: ruleKey,
                $uid: `${path.value}-${ruleKey}`,
                $message: unref(res.$message),
                $params: res.$params,
                $response: res.$response,
                $pending: res.$pending
            } as ErrorObject)
        })
    )

    result.$errors = computed(() => result.$dirty.value ? result.$silentErrors!.value : [])

    result.$unwatch = () => {
        ruleKeys.forEach(ruleKey => validatorResults[ruleKey].$unwatch())
    }

    return { ...result, ...validatorResults }
}

/** Generates the Meta fields from the results */
function createMetaFields(results: ValidationResult, allResults: Ref<BaseValidationKey<unknown>[]>) {

    // returns `$dirty` as true, if all children are dirty
    const $dirty = computed({
        get() { return results.$dirty.value },
        set(v: boolean) { results.$dirty.value = v }
    })

    const $anyDirty = computed(() => $dirty.value || allResults.value.some(r => r.$dirty || r.$anyDirty))

    const $silentErrors = computed(() => {
        // current state level errors, fallback to empty array if root
        const modelErrors: ErrorObject[] = results.$silentErrors?.value?.map(unref) || []

        // collect all nested and child $silentErrors and merge the $silentErrors
        allResults.value
            .map(result => unref(result.$silentErrors))
            .forEach(errors => {
                if (errors && errors.length) modelErrors.push(...errors)
            })

        return modelErrors
    })

    const $errors = computed(() => $anyDirty.value ? $silentErrors.value : [])

    const $invalid = computed({
        get() {
            // if any of the nested values is invalid
            return allResults.value.some(r => r.$invalid) ||
                // or if the current state is invalid
                unref(results.$invalid) ||
                // fallback to false if is root
                false
        },
        set(_invalid: boolean) {
            if (results.$invalid) results.$invalid.value = _invalid
        }
    })

    const $pending = computed(() =>
        // if any of the nested values is pending
        allResults.value.some(r => unref(r.$pending)) ||
        // if any of the current state validators is pending
        unref(results.$pending) ||
        // fallback to false if is root
        false
    )

    const $error = computed(() => $dirty.value ? $pending.value || $invalid.value : false)

    const $touch = (deep = true) => {
        // call the root $touch
        results.$touch()
        // call all nested level $touch
        if (deep)
            allResults.value.forEach(result => result.$touch())
    }

    const $reset = () => {
        // reset the root $dirty state
        results.$reset()
        // reset all the children $dirty states
        allResults.value.forEach(result => result.$reset())
    }

    // Ensure that if all child and nested results are $dirty, this also becomes $dirty
    if (allResults.value.length && allResults.value.every(nr => nr.$dirty)) $touch()

    return {
        $dirty,
        $errors,
        $invalid,
        $anyDirty,
        $error,
        $pending,
        $touch,
        $reset,
        $silentErrors
    }
}

/**
 * Main Vuelidate bootstrap function.
 * Used both for Composition API in `setup` and for Global App usage.
 * Used to collect validation state, when walking recursively down the state tree
 */
function setValidations<T = any>({
    validations,
    parentState,
    getRoot,
    key,
    parentKey,
    globalConfig = {},
    skipGetRecord = false
}: {
    validations?: VArgs<T>;
    parentState: Ref<T>;
    getRoot: () => ValidationOf;
    key: Ref<string | number>;
    parentKey?: Ref<string>;
    globalConfig: GlobalConfig;
    skipGetRecord?: boolean;
}) {
    const path = computed(() => (parentKey && parentKey.value !== '') ? `${parentKey.value}.${key.value}` : `${key.value}`)

    const { rules, nestedValidators, config } = sortValidations(validations)

    const mergedConfig = { ...globalConfig, ...config }

    const nestedState = key.value !== '' ?
        computed<T[keyof T]>(() => parentState.value ? (parentState.value as any)[key.value] : undefined)
        :
        parentState as unknown as Ref<T[keyof T]>

    let results: ValidationResult<unknown>

    const nestedResults = {} as { [key: string]: ValidationOf }
    const arrayResults = {} as { $each?: Ref<ValidationOf[]> }

    const _record = ref<null | { val: any, isType: boolean }>(null)
    let _recordArray: null | any[] = null

    function recordVal(val: any) {
        switch (typeof val) {
            case 'string':
            case 'bigint':
            case 'boolean':
            case 'number':
            case 'undefined':
                return { val, isType: false }
            case 'object':
                return { val: Array.isArray(val) ? 'array' : 'object', isType: true }
            // case 'function':
            // case 'symbol':
            default:
                return { val: typeof val, isType: true };
        }
    }

    /** record current state */
    const $record = (deep = true, root = true) => {
        _record.value = recordVal(nestedState.value)
        if (deep) {
            const _recordChild = (d: boolean) => {
                allResults.value.forEach(v => (v.$record as typeof $record)(d, false))
                $reset()
                if (_recordArray)
                    _recordArray = arrayResults.$each!.value.map(x => x.$getRecord())
            }
            if (root) nextTick(() => _recordChild(deep))
            else _recordChild(deep)
        } else if (_recordArray) {
            _recordArray = arrayResults.$each!.value.map(x => x.$getRecord())
        }

    }

    /** record set in params */
    const $setRecord = (v: any, deep = false) => {
        _record.value = recordVal(v)
        if (deep && v)
            if (isArray) for (let jj = 0; jj < arrayResults.$each!.value.length; jj += 1)
                (arrayResults.$each!.value[jj].$setRecord as typeof $setRecord)(v[jj], true)
            else for (const key of Object.keys(nestedResults))
                (nestedResults[key].$setRecord as typeof $setRecord)(v[key], true)
    }

    const $getRecord = (parent?: any) => {
        if (!_record.value || skipGetRecord) return

        let result: any

        if (_record.value.isType && _record.value.val === 'object')
            result = allResults.value.reduce((r, v) => { (v.$getRecord as typeof $getRecord)(r); return r }, {})
        else if (_record.value.isType && _record.value.val === 'array')
            result = _recordArray
        else
            result = _record.value.val

        if (typeof parent === 'object' && result != undefined)
            parent[key.value] = result

        return result
    }

    let unwatchAutoDirty: () => void
    if (key.value !== '' && mergedConfig.$autoDirty) {
        unwatchAutoDirty = watch([nestedState, _record], ([a, r]) => {
            if (!r) {
                if (a !== undefined || a !== null) $touch();
            } else {
                const _isDirty = mergedConfig.$autoDirtyCompare ?
                    !mergedConfig.$autoDirtyCompare(recordVal(a).val, r.val)
                    :
                    recordVal(a).val !== r.val
                if (!$dirty.value && _isDirty) $touch()
                else if ($dirty.value && !_isDirty) $reset()
            }
        }, { flush: 'post' })
    }

    const isArray = mergedConfig.$isArray
    delete mergedConfig.$isArray;

    let unwatchArray: () => void

    if (!isArray) {
        // Use rules for the current state fragment and validate it
        results = createValidationResults(rules, getRoot, nestedState, key, path, mergedConfig);

        // Use nested keys to repeat the process *WARN*: This is recursive
        for (const nestedKey in nestedValidators) {
            nestedResults[nestedKey] = setValidations<any>({
                validations: nestedValidators[nestedKey],
                parentState: nestedState,
                getRoot,
                key: ref(nestedKey),
                parentKey: path,
                globalConfig: mergedConfig
            })
        }

    } else {
        // Use rules for the current state fragment and validate it
        results = createValidationResults({}, getRoot, nestedState, key, path, mergedConfig);

        arrayResults.$each = ref<ReturnType<typeof setValidations>[]>([])
        _recordArray = []

        unwatchArray = watch(() => [...((nestedState as unknown as Ref<any[]>).value || [])], (currentState, oldState) => {
            if (currentState.length !== arrayResults.$each!.value.length) {
                const indexesAdd = [] as number[]
                const indexesRemove = [] as number[]

                if (oldState) {
                    const oldStateString = oldState.map(x => JSON.stringify(x));
                    const currentStateString = currentState.map(x => JSON.stringify(x));

                    currentStateString
                        .forEach((val, index) => {
                            const indexAddOrRemove = oldStateString.indexOf(val)
                            if (indexAddOrRemove === -1) indexesAdd.push(index)
                            else oldStateString.splice(indexAddOrRemove, 1)
                        })
                    oldState.map(x => JSON.stringify(x))
                        .forEach((val, index) => {
                            const indexAddOrRemove = currentStateString.indexOf(val)
                            if (indexAddOrRemove === -1) indexesRemove.push(index)
                            else currentStateString.splice(indexAddOrRemove, 1)
                        })
                } else {
                    indexesAdd.push(...Array(currentState.length).keys());
                }

                // console.log(path.value, indexesAdd, indexesRemove, indexesAdd.length - indexesRemove.length, currentState.length - (oldState?.length || 0))

                for (const index of indexesRemove.reverse()) {
                    const removed = arrayResults.$each!.value.splice(index, 1)[0] as any
                    if (removed && removed.$unwatch) removed.$unwatch()
                }

                for (const index of indexesAdd) {
                    const added = setValidations<unknown>({
                        validations: {
                            ...nestedValidators,
                            ...rules
                        },
                        parentState: nestedState,
                        getRoot,
                        key: ref(index),
                        parentKey: path,
                        globalConfig: mergedConfig
                    });
                    // (added as any).$touch(false)
                    arrayResults.$each!.value.splice(index, 0, added);
                }

                // console.log(path.value, currentState.length, arrayResults.$each?.value.length)

                // sync key + validate invalid
                for (let i = 0; i < arrayResults.$each!.value.length; i++) {
                    const arrayResultsItem = arrayResults.$each!.value[i] as any // ReturnType<typeof setValidations>
                    if (i !== arrayResultsItem.$key) arrayResultsItem.$key = i;
                    (arrayResultsItem.$setRecord as typeof $setRecord)(_recordArray![i], true)
                    if (arrayResultsItem.$invalid && arrayResultsItem.$validate) arrayResultsItem.$validate()
                }

            }
        }, { immediate: true })

        nestedResults['length'] = setValidations<any>({
            validations: nestedValidators['length'],
            parentState: nestedState,
            getRoot,
            key: ref('length'),
            parentKey: path,
            globalConfig: mergedConfig,
            skipGetRecord: true
        })
    }

    const allResults = computed(() =>
        (Object.values(unref(nestedResults as any)!) as ValidationOf[])
            .concat(arrayResults.$each ? arrayResults.$each.value : [])
    )

    const {
        $dirty,
        $errors,
        $invalid,
        $anyDirty,
        $error,
        $pending,
        $touch,
        $reset,
        $silentErrors
    } = createMetaFields(
        results,
        // Collect and merge this level validation results with all nested validation results
        allResults
    )

    /** Executes the validators and returns the result. */
    function $validate(): boolean {
        return [
            results.$validate ? results.$validate() : true,
            ...allResults.value.map(v => v.$validate ? v.$validate() : true)
        ].find(x => x === false) === undefined
    }

    function $unwatch() {
        if (results.$unwatch) results.$unwatch()
        if (unwatchAutoDirty) unwatchAutoDirty()
        if (unwatchArray) unwatchArray()
        allResults.value.forEach((v: any) => { if (v.$unwatch) v.$unwatch() })
    }


    return reactive({
        ...results,
        // NOTE: The order here is very important, since we want to override
        // some of the *results* meta fields with the collective version of it
        // that includes the results of nested state validation results
        $model: nestedState,
        $dirty,
        $error,
        $errors,
        $silentErrors,
        $invalid,
        $anyDirty,
        $pending,
        $touch,
        $reset,
        $record,
        $setRecord,
        $getRecord,
        $validate,
        $key: key,
        $path: path,
        $unwatch,

        // add each nested property's state
        ...nestedResults,
        ...arrayResults
    }) as unknown as ValidationOf<T> & { $key: Ref<string | number> }
}

export function useVuelidate<T, V extends VArgs<T>>(validations: Ref<V> | V, state: Ref<T> | Ref<T[]>, globalConfig: GlobalConfig = {}) {
    const validationResults = ref({} as any) // ValidationOf<T, V>)

    const uid = crypto.getRandomValues(new Uint32Array(1))[0]

    const result = computed(() => {
        if (import.meta.env.DEV && printDiff)
            return { ...validationResults.value, $uid: uid, $printDiff: (name?: string) => printDiff!(validationResults, state, name) }
        return { ...validationResults.value, $uid: uid }
    }) as Ref<RootValidationOf<T, V>>

    const isR = isRef(validations) || isReactive(validations) || isReadonly(validations)
    const validationsWatchTarget = isR ?
        { validations }
        // wrap plain objects in a reactive, so we can track changes if they have computed in them.
        : { validations: reactive(validations || {}) }

    let initialized = false
    watch(validationsWatchTarget.validations, newValidationRules => {
        let recorded;

        if (initialized) {
            validationResults.value.$unwatch()
            recorded = validationResults.value.$getRecord()
        }

        validationResults.value = setValidations<any>({
            validations: newValidationRules,
            getRoot: () => result.value,
            parentState: state,
            key: ref(''),
            globalConfig
        })

        if (!initialized && state.value)
            validationResults.value.$record();

        if (recorded)
            validationResults.value.$setRecord(recorded, true)

        initialized = true
    }, {
        immediate: true,
        deep: isR
    })

    // TODO: Change into reactive + watch
    return result
}