<template>
    <div :class="{ 'relative': $props.absolute }">
        <component v-if="svg" :is="svg" :style="styleSVG" preserveAspectRatio="xMidYMid meet"
            :class="{ 'svg-animated': animate }" class="w-full h-full" />
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, defineAsyncComponent, PropType } from 'vue';

const modules = import.meta.glob('../assets/**/*', { import: 'default' })

async function importLocale(x: string): Promise<any> {
    const moduleKey = `../assets/${x}`
    return modules[moduleKey]()
}

// Promise.all(
//     Object.keys(modules).map(async m => {
//         const _m = await modules[m]() as any
//         const _viewBox = _m.render().props?.viewBox
//         if (!_viewBox) { console.log(m); return }
//         const [x, y, width, height] = _viewBox.split(' ').map((_x: string) => parseInt(_x))
//         return { x, y, width, height, m }
//     })
// ).then(_all => {
//     console.log(_all.length)
//     _all = _all.filter(a => a !== undefined)
//     console.log(_all.length)
//     _all.sort((a, b) => a!.width - b!.width)
//     console.log(_all.length)
//     _all.forEach(m => {
//         console.log('x', m!.x, 'y', m!.y, 'width', m!.width, 'height', m!.height, (m!.width / m!.height).toPrecision(3), m!.m)
//     })
//     // x 0 y 0 width 325 height 469 0.693 ../assets/icon8/matey-pie-chart-pattern.svg SVG.vue:33:16
//     // x 0 y 0 width 390 height 194 2.01 ../assets/icon8/matey-sleeping-cat.svg SVG.vue:33:16
//     // x 0 y 0 width 520 height 404 1.29 ../assets/icon8/matey-board-with-stickers.svg SVG.vue:33:16
//     // x 0 y 0 width 2000 height 1400 1.43 ../assets/icon8/matey-man-and-woman-work-in-support-service.svg SVG.vue:33:16
//     // x 0 y 0 width 2000 height 1400 1.43 ../assets/icon8/matey-welcome.svg SVG.vue:33:16
//     // x 0 y 0 width 2000 height 1400 1.43 ../assets/icon8/matey-woman-communicates-at-zoom-meeting.svg SVG.vue:33:16
//     // x 0 y 0 width 2200 height 1800 1.22 ../assets/icon8/matey-home-yoga.svg SVG.vue:33:16
//     // x 0 y 0 width 2500 height 2000 1.25 ../assets/icon8/matey-colleagues-discussing-cryptocurrency-growth.svg SVG.vue:33:16
//     // x 0 y 0 width 2500 height 2000 1.25 ../assets/icon8/matey-courier-girls-on-scooters-with-backpacks-in-the-city-1.svg SVG.vue:33:16
//     // x 0 y 0 width 2500 height 2000 1.25 ../assets/icon8/matey-friends-gathered-to-surf-on-the-sea.svg SVG.vue:33:16
//     // x 0 y 0 width 2500 height 2000 1.25 ../assets/icon8/matey-girl-on-bicycle-and-guy-on-skateboard-against-backdrop-of-urban-landscape.svg SVG.vue:33:16
//     // x 0 y 0 width 2500 height 2000 1.25 ../assets/icon8/matey-people-recording-podcast-in-sound-studio.svg SVG.vue:33:16
//     // x 0 y 0 width 2500 height 2000 1.25 ../assets/icon8/matey-three-architects-discuss-project-against-background-of-construction.svg SVG.vue:33:16
//     // x 0 y 0 width 2500 height 2000 1.25 ../assets/icon8/matey-woman-and-man-working-remotely-on-the-street.svg SVG.vue:33:16
//     // x 0 y 0 width 2500 height 2000 1.25 ../assets/icon8/matey-women-discussing-work-at-an-online-meeting-in-the-office.svg SVG.vue:33:16
//     // x 0 y 0 width 3000 height 2400 1.25 ../assets/icon8/matey-backpacker-with-open-arms-ready-for-a-journey.svg SVG.vue:33:16
//     // x 0 y 0 width 3000 height 2400 1.25 ../assets/icon8/matey-business-success.svg SVG.vue:33:16
//     // x 0 y 0 width 3000 height 2400 1.25 ../assets/icon8/matey-business-trophy.svg SVG.vue:33:16
//     // x 0 y 0 width 3000 height 2100 1.43 ../assets/icon8/matey-marketing-1 (copier 1).svg SVG.vue:33:16
//     // x 0 y 0 width 3000 height 2100 1.43 ../assets/icon8/matey-marketing-1.svg SVG.vue:33:16
//     // x 0 y 0 width 3000 height 2100 1.43 ../assets/icon8/matey-marketing-2.svg SVG.vue:33:16
//     // x 0 y 0 width 3000 height 2100 1.43 ../assets/icon8/matey-marketing-statistics-on-computer-screen.svg SVG.vue:33:16
//     // x 0 y 0 width 3000 height 1833 1.64 ../assets/icon8/matey-online-preschool-education-at-home.svg SVG.vue:33:16
//     // x 0 y 0 width 3000 height 1800 1.67 ../assets/icon8/matey-shopping-in-an-online-store.svg SVG.vue:33:16
// })


export default defineComponent({
    name: 't-svg',
    props: {
        src: { type: String, required: true },
        colors: {
            type: Array as PropType<string[]>,
            default: () => ([
                'var(--tw-color-ocean)',
                'var(--tw-color-fluo)'
            ])
        },
        animate: { type: Boolean, default: () => (true) },
        absolute: Boolean
    },
    async setup(props) {

        // modify svg to variabilize color
        // fill="#1967ff" => fill="var(--svg-color-1, #1967ff)"
        // fill="#ff89d8" => fill="var(--svg-color-2, #ff89d8)"
        // viewbox ratio 1.5 ex: width 3000 height 2000

        const svg = defineAsyncComponent(() => importLocale(props.src))

        // const width = ref<string | null>(null)
        // const height = ref<string | null>(null)

        const styleSVG = computed(() => {
            const _style = props.colors.map(
                (color, colorIdx) => `--svg-color-${colorIdx + 1}: ${color};`)
            if (props.absolute)
                _style.push('position: absolute;')
            // if (height.value)
            //     _style.push(`height: ${height.value};`)
            // if (width.value)
            //     _style.push(`width: ${width.value};`)
            return _style
        })

        // const svgcontainer = ref<HTMLDivElement>()

        // if (props.resize) {
        //     let observer: ResizeObserver | null = null
        //     onMounted(() => {
        //         if (svgcontainer.value) {
        //             observer = new ResizeObserver(debounce((x: ResizeObserverEntry[]) => {
        //                 if (x.length) {
        //                     const { width: w, height: h } = x[0].contentRect
        //                     width.value = w ? `${w}px` : ''
        //                     height.value = h ? `${h}px` : ''
        //                 }
        //             }, 200, true))
        //             observer.observe(svgcontainer.value as any)
        //         }
        //     })
        //     onUnmounted(() => {
        //         if (observer)
        //             observer.disconnect()
        //     })

        // }
        return {
            svg,
            styleSVG,
            // svgcontainer
        }
    }

})
</script>

<style lang="postcss">
.svg-animated {
    @apply transition-all duration-200 ease-in-out;

    path {
        @apply scale-95 origin-bottom transition-all duration-200 ease-in-out;
    }

    &:hover {
        path {
            @apply drop-shadow-lg scale-100;
        }
    }
}
</style>