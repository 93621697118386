import { ref, Ref, Component, Plugin, inject } from 'vue'
import { createAnimation, modalController as iModalController, ModalOptions as iModalOptions } from '@ionic/vue';

import Modal from "@/components/Modal.vue"
import { buildDirtyLock } from './emitter';
import { toastController } from './toast';

type ModalOptions<T extends Component> = Omit<iModalOptions, 'component' | 'componentProps'> & {
    component: T,
    componentProps?: ComponentProps<T>
}

type IonModal = Omit<HTMLIonModalElement, 'canDismiss'> & {
    locks?: ReturnType<typeof buildDirtyLock>;
    dismissValue?: any;
    canDismiss: (data?: any) => Promise<boolean>;
    onDismiss?: (data?: any) => void;
    presenting?: Promise<void>;
    /** if multiple modal, loaded true after the opening animation */
    loaded: Ref<boolean>
}

class ModalController {

    modals: IonModal[] = []

    /**
     * @returns
     *  * if args.buttons => buttons.*.value | undefined
     *  * else boolean | undefined
     */
    async open(args: ModalArgs) {
        return this.openCustom({
            component: Modal,
            componentProps: { args }
        })
    }

    async openCustom<T extends Component>(opts: ModalOptions<T>) {
        const _prevModalIdx = this.modals.length - 1
        const _prevModal = this.modals[_prevModalIdx]

        // normalize cssClass
        if (!opts.cssClass) opts.cssClass = []
        if (typeof opts.cssClass === 'string') opts.cssClass = [opts.cssClass]

        // disable ionic behavior: scroll top on focus 
        opts.cssClass.push('ion-disable-focus-trap')

        const loaded = ref(false)
        if (_prevModal) {
            opts.enterAnimation = (baseEl) => {
                // hide background
                baseEl.style.setProperty('--background', '#fffff')

                // force opacity 
                baseEl.shadowRoot.querySelector('.modal-wrapper').style.setProperty('opacity', '1')

                return createAnimation()
                    .addElement(baseEl)
                    .addAnimation([
                        createAnimation()
                            .addElement(baseEl.children[0])
                            .duration(300)
                            .easing('ease-in')
                            .fromTo('transform', 'translateX(100vw)', 'translateX(0px)'),
                        createAnimation()
                            .addElement(_prevModal.children[0])
                            .duration(300)
                            .easing('ease-in')
                            .fromTo('transform', 'translateX(0)', 'translateX(-100vw)')
                            .onFinish(() => loaded.value = true)
                    ]);
            }

            opts.leaveAnimation = (baseEl) =>
                createAnimation()
                    .addElement(baseEl)
                    .addAnimation([
                        createAnimation()
                            .addElement(baseEl.children[0])
                            .duration(300)
                            .easing('ease-in')
                            .fromTo('transform', 'translateX(0px)', 'translateX(100vw)'),
                        createAnimation()
                            .addElement(_prevModal.children[0])
                            .duration(300)
                            .easing('ease-in')
                            .fromTo('transform', 'translateX(-100vw)', 'translateX(0px)')
                    ]);

        } else {
            loaded.value = true
        }

        const _currModal = await iModalController.create({
            ...opts,
            // componentProps: {
            //     ...(opts.componentProps || {}),
            //     modalId: `modal${this.modals.length}`
            // },
            id: `modal${this.modals.length}`,
        } as iModalOptions) as IonModal;

        _currModal.loaded = loaded

        // check lockDirty to disable close
        _currModal.canDismiss = async (data?: any) => {
            // no locks OR locks is unlocked
            if (!_currModal.locks || !_currModal.locks.isLocked) {
                _currModal.dismissValue = _currModal.onDismiss ?
                    _currModal.onDismiss(data)
                    :
                    data
                if (_currModal.locks && !_currModal.locks.isLocked)
                    _currModal.locks.destroy()
                return true
            }

            // locks is locked
            toastController.warn({ header: 'Modification non enregistrée', duration: 1000 })
            return false
        }

        this.modals.push(_currModal)
        _currModal.presenting = _currModal.present()
        await _currModal.presenting

        if (_currModal.firstChild) {
            // _currModal.firstChild.parentElement?.firstElementChild?.firstElementChild?.classList.add('inline-table')
            _currModal.firstChild.addEventListener('click', (evt) => {
                if (evt.target === evt.currentTarget) {
                    if (this.modals) _currModal.dismiss();
                }
            })
        }

        // wait close
        await _currModal.onWillDismiss()
        const dismissValue = _currModal.dismissValue
        this.modals.pop()

        return dismissValue
    }

    getCurrent(modalId?: string) {
        const _currModal = !modalId ?
            this.modals[this.modals.length - 1]
            :
            this.modals.find(m => m.id === modalId)

        return {

            // setDismissValue(v: any) {
            //     _currModal.dismissValue = v
            // },

            setOnDismiss(onDismiss: (data?: any) => any) {
                if (_currModal)
                    _currModal.onDismiss = onDismiss
            },

            addLock(...uid: number[]) {
                if (_currModal)
                    if (!_currModal.locks)
                        _currModal.locks = buildDirtyLock({ include: uid })
                    else
                        _currModal.locks.include(...uid)
            },

            /** modal is loaded and animation is ended */
            loaded: _currModal?.loaded || ref(false),

            forceDismiss: (closedVal?: any) => { this.forceDismiss(closedVal, _currModal) },
            dismiss: (closedVal?: any) => { this.dismiss(closedVal, _currModal) },
        }
    }

    /** dismiss last modal */
    async dismiss(closedVal?: any, _currModal?: IonModal) {
        if (!_currModal)
            _currModal = this.modals[this.modals.length - 1]

        if (_currModal) {
            await _currModal.presenting
            await _currModal.dismiss(closedVal)
        } else {
            await iModalController.dismiss(closedVal)
        }
    }

    /** skip ckeck locks and dismiss last modal */
    async forceDismiss(closedVal?: any, _currModal?: IonModal) {
        if (!_currModal)
            _currModal = this.modals[this.modals.length - 1]

        if (_currModal) {
            if (_currModal.locks) _currModal.locks.unlock()
            await _currModal.presenting
            await _currModal.dismiss(closedVal === undefined ? _currModal.dismissValue : closedVal)
        } else {
            await iModalController.dismiss(closedVal)
        }
    }

    async closeAllModals() {
        for (let i = this.modals.length - 1; i >= 0; i--) {
            const m = this.modals[i]
            if (m.locks && m.locks.isLocked) return false
            m.dismiss()
        }
        this.modals = []
        return true
    }
}

const modalController = new ModalController();

const modalPlugin: Plugin = {
    install: (app) => {
        app.config.globalProperties.$modalCtl = modalController
        app.provide('modalCtl', modalController)
    }
}

export function useModal() {
    const mCtl = inject('modalCtl') as ModalController
    return inject('modal', mCtl.getCurrent());
}

export {
    modalPlugin,
    modalController,
    ModalController
}