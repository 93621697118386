<template>
  <div class="m-auto p-2 w-full md:w-2/3 2xl:w-1/2 bg-white rounded-md overflow-y-auto">
    <div class="px-5">
      <div class="pb-5">
        <ChatBubbleLeftRightIcon class="h-8 text-gray-500 inline-block mr-3" />
        <h2 class="inline-block">
          {{ exchange.id ? $t('section.exchange.title_edit') : $t('section.exchange.title_add') }}
        </h2>
      </div>

      <form action="" class="grid grid-cols-6 gap-4">

        <t-combobox class="col-span-6 sm:col-span-3" :options-search="searchContactOrCompany"
          :options-key="(c: Contact | Company) => c ? isContact(c) ? `con${c.id}` : `com${c.id}` : 'null'"
          :options-label="(c: Contact | Company) => c ? isContact(c) ? `${c.firstname} ${c.lastname}` : c.name_! : ''"
          :options-disabled="(c: Contact | Company) => c.id === -1" v-model="recipient" :validation="recipient$"
          :placeholder="$t('model.exchange.recipient')" :disabled="preSelectRecipient">
          <template v-slot="{ opt, selected, active }">
            <div v-if="opt.id === -1" class="h-1 w-full border-b"></div>
            <div v-else-if="isContact(opt)" class="flex flex-nowrap items-center">
              <t-avatar class="w-9 h-9 mr-3" :contact="opt" />
              <span class="block truncate" :class="{ 'font-medium': selected, 'font-normal': !selected }">
                <div>{{ opt.firstname }} {{ opt.lastname }}</div>
                <div :class="{ 'text-white': active, 'text-gray-400': !active }">{{ opt.email }}</div>
              </span>
              <span v-if="selected" class="ml-auto self-center pr-3">
                <t-icon-check class="h-5 w-5" aria-hidden="true" />
              </span>
            </div>
            <div v-else class="flex flex-nowrap items-center">
              <t-avatar class="w-9 h-9 mr-3" :company="opt" />
              <span class="block truncate" :class="{ 'font-medium': selected, 'font-normal': !selected }">
                {{ opt.name_ }}
              </span>
              <span v-if="selected" class="ml-auto self-center pr-3">
                <t-icon-check class="h-5 w-5" aria-hidden="true" />
              </span>
            </div>
          </template>
        </t-combobox>

        <t-combobox class="col-span-6 sm:col-span-3" :options-search="searchProject" options-label="name"
          v-model="exchange.project" :validation="exchange$.project" :placeholder="$t('model.exchange.project')"
          :disabled="!!preSelectProject || !recipient" nullable />

        <span class="col-span-6 text-lg font-medium">
          {{ $t('section.exchange.info') }}
        </span>

        <div class="col-span-6">
          {{ $t('section.exchange.types') }}:
          <div class="flex flex-wrap justify-between px-8">
            <span v-for="type in $enum.ExchangeType.values" :key="type" @click="exchange.type = type">
              <input :id="`exct${type}`" type="radio" class="py-4 px-1 border-b-2 font-medium text-sm cursor-pointer"
                :class="exchange.type === type ? 'border-ocean text-ocean' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'"
                :checked="exchange.type === type" :name="$enum.ExchangeType.translate(type)" />
              <label :for="`exct${type}`" class="px-2 text-sm t-fcapitalize inline-block cursor-pointer">
                {{ $enum.ExchangeType.translate(type) }}
              </label>
            </span>
          </div>
        </div>

        <t-datetime v-model="exchange.date" :validation="exchange$.date" :placeholder="$t('model.exchange.date')"
          class="col-span-6 sm:col-span-3" />

        <t-input v-model="exchange.title" :validation="exchange$.title" type="text"
          :placeholder="$t('model.exchange.title')" :default="`(${$t('model.exchange.title_by_default')})`" class="col-start-1 col-end-7 sm:col-end-4" />

        <t-input v-model="exchange.description" :validation="exchange$.description" type="text"
          :placeholder="$t('model.exchange.description')" multiline rows="8" class="col-span-6" />

      </form>
    </div>
    <div class="flex flex-wrap gap-2 px-5 pt-5">
      <t-button
        v-vuelidate="() => ({ click: save, validation: [exchange$, recipient$], unchanged: exchange.id === undefined })">
        {{ $t('actions.save') }}
      </t-button>
      <t-button color="white" @click="forceDismiss()">
        {{ $t('actions.cancel') }}
      </t-button>
    </div>
  </div>
</template>


<script lang="ts">
import { defineComponent, ref, watch, Ref } from 'vue'
import { ChatBubbleLeftRightIcon } from '@heroicons/vue/24/outline'

import { Contact, Exchange, Company, Project, IExchange } from '@tolemac/grpc_web_api/tolemac/pub/model'
import { CriteriaOperator, ICriteria } from '@tolemac/grpc_web_api/tolemac/pub/common'

import { today } from '@/util'
import { useValidation } from '@/composition/vuelidate'
import { required } from '@/composition/vuelidate.validator'

import plugins from '@/plugins'

export default defineComponent({
  components: {
    ChatBubbleLeftRightIcon
  },
  props: {
    id: Number,
    contactId: Number,
    companyId: Number,
    projectId: Number
  },
  async setup(props) {
    const { $api, $modal, util } = plugins

    const preSelectRecipient = ref(props.contactId !== undefined || props.companyId !== undefined)
    const preSelectProject = ref(props.projectId !== undefined)

    const { refObj: exchange, validationObj: exchange$, setVal: exchange$set } =
      useValidation(
        null as (Exchange | null),
        util.validators.exchange.exchangeVArgs
      )

    const { refObj: recipient, validationObj: recipient$, setVal: recipient$set } =
      useValidation(
        null as (Company | Contact | null),
        { required }
      )

    $modal.addLock(exchange$.value.$uid, recipient$.value.$uid)

    async function initExch(id?: number, contactId?: number, companyId?: number, projectId?: number) {
      const _today = today()
      try {
        if (id) {
          const exch = await $api.exchange.get({ id, relations: ['company', 'contact', 'project'] })
          exchange$set(exch)
          recipient$set(exch.contact || exch.company)
        } else if (contactId) {
          const contact = await $api.contact.get({ id: contactId, relations: ['@company'] })
          exchange$set({ type: Exchange.ExchangeType.EMAIL, date: _today, contact })
          recipient$set(contact)
          preSelectRecipient.value = true
        } else if (companyId) {
          const company = await $api.company.get({ id: companyId })
          exchange$set({ type: Exchange.ExchangeType.EMAIL, date: _today, company })
          recipient$set(company)
          preSelectRecipient.value = true
        } else if (projectId) {
          const project = await $api.project.get({ id: projectId, relations: ['company'] })
          recipient$set(project.company)
          exchange$set({ type: Exchange.ExchangeType.EMAIL, date: _today, project })
        } else {
          exchange$set({ type: Exchange.ExchangeType.EMAIL, date: _today })
        }
      } catch (e: any) {
        if (id !== undefined && contactId !== undefined && companyId !== undefined && projectId !== undefined)
          await initExch()
      }
    }

    function isContact(c: Contact | Company): c is Contact {
      if (!c) return false
      return c instanceof Contact
    }

    function searchContactOrCompany(query?: string) {
      const preSelectProjectCompany = preSelectProject.value ? exchange.value?.project?.company : undefined

      return Promise.all([
        preSelectProjectCompany ?
          { values: [preSelectProjectCompany] }
          :
          $api.company.find({
            limit: 3,
            criterias: query ?
              [{ field: 'name', value: `%${query}%`, operator: CriteriaOperator.I_LIKE }]
              :
              []
          })
        ,
        $api.contact.find({
          limit: 3,
          relations: ['@company'],
          criterias: query ? (
            preSelectProjectCompany ?
              [
                { field: 'firstname', value: `%${query}%`, operator: CriteriaOperator.I_LIKE, group: 1 },
                { field: 'company.id', value: `${preSelectProjectCompany.id}`, group: 1 },
                { field: 'lastname', value: `%${query}%`, operator: CriteriaOperator.I_LIKE, group: 2 },
                { field: 'company.id', value: `${preSelectProjectCompany.id}`, group: 2 },
                { field: 'email', value: `%${query}%`, operator: CriteriaOperator.I_LIKE, group: 3 },
                { field: 'company.id', value: `${preSelectProjectCompany.id}`, group: 3 }
              ]
              :
              [
                { field: 'firstname', value: `%${query}%`, operator: CriteriaOperator.I_LIKE, or: true },
                { field: 'lastname', value: `%${query}%`, operator: CriteriaOperator.I_LIKE, or: true },
                { field: 'email', value: `%${query}%`, operator: CriteriaOperator.I_LIKE, or: true }
              ]
          ) : (
            preSelectProjectCompany ?
              [{ field: 'company.id', value: `${preSelectProjectCompany.id}` }]
              :
              []
          )
        })
      ]).then(([_companies, _contacts]) => {
        if (!_contacts.values.length)
          return _companies.values
        else if (!_companies.values.length)
          return _contacts.values
        else
          return [..._contacts.values, { id: -1 }, ..._companies.values]
      })
    }

    async function save() {
      if (!exchange.value) return

      const exchToSave = exchange.value as IExchange
      if (!exchToSave.title) exchToSave.title = ''

      if (recipient.value)
        if (isContact(recipient.value))
          exchToSave.contact = { id: recipient.value.id }
        else
          exchToSave.company = { id: recipient.value.id }
      if (exchange.value.project)
        exchToSave.project = { id: exchange.value.project.id }

      if (exchange.value.id)
        await $api.exchange.update(exchToSave)
      else
        await $api.exchange.add(exchToSave)
      $modal.forceDismiss(true)
    }

    watch(recipient, r => {
      if (!r || !exchange.value) return

      const _isContact = isContact(r)
      if (_isContact === null) {
        exchange.value.contact = undefined
        exchange.value.company = undefined
      } else if (_isContact) {
        exchange.value.contact = r
        exchange.value.company = undefined
      } else {
        exchange.value.contact = undefined
        exchange.value.company = r
      }
    })

    await initExch(props.id, props.contactId, props.companyId, props.projectId)

    return {
      exchange: exchange as Ref<Exchange>,
      exchange$,

      recipient,
      recipient$,

      preSelectRecipient,
      preSelectProject,

      isContact,
      save,
      forceDismiss: $modal.forceDismiss,

      searchContactOrCompany,
      searchProject: (query: string) => {
        const criterias: ICriteria[] = [{
          field: 'status',
          values: [`${Project.ProjectStatus.DELIVERED__PAID}`, `${Project.ProjectStatus.CANCELED}`],
          not: true
        }]

        if (query)
          criterias.push({ field: 'name', value: `%${query}%`, operator: CriteriaOperator.I_LIKE })

        const cId = exchange.value!.contact?.company?.id || exchange.value!.company?.id
        if (cId)
          criterias.push({ field: 'company.id', value: `${cId}` })

        return $api.project.find({
          limit: 6,
          criterias
        }).then(v => v.values)
      },
    }
  }
})
</script>
