<template>
    <div class="m-auto p-2 w-full md:w-2/3 2xl:w-1/2 bg-white rounded-md overflow-y-auto">
        <button class="float-right border p-1 mr-4 rounded-md" @click="forceDismiss()">
            <t-icon-x-mark class="w-3 h-3" />
        </button>
        <div class="px-5">
            <TaxesSection v-model="taxesRate" :validation="(taxesRate$.$each as any)" />
        </div>
        <div class="flex flex-row flex-grow-0 gap-2 px-5 pt-5">
            <t-button v-vuelidate="() => ({ click: save, validation: taxesRate$ })">
                {{ $t('actions.apply') }}
            </t-button>
            <t-button color="white" @click="forceDismiss()">
                {{ $t('actions.cancel') }}
            </t-button>
        </div>
    </div>
</template>


<script lang="ts">
import { defineComponent } from 'vue';

import plugins from '@/plugins';

import TaxesSection from './TaxesSection.vue'

export default defineComponent({
    name: 't-tax-modal',
    components: { TaxesSection },
    setup() {
        const { $api, $modal, util } = plugins

        const { taxesRate, taxesRate$, taxesRate$sync } = util.taxes.useTaxes(async () => {

            const taxes = await $api.taxRate.find({})
            if (!taxes.values.length)
                taxes.values.push({ name: '', value: 0, default: true })
            return taxes
        })

        $modal.addLock(taxesRate$.value.$uid)

        return {
            forceDismiss: $modal.forceDismiss,

            taxesRate,
            taxesRate$,

            async save() {
                await taxesRate$sync()
                $modal.forceDismiss(taxesRate.value.find(t => t.default))
            }
        }

    }
})
</script>