<template>
  <ErrorTooltip :class="[
    containerClass, 't-input',
    disabled ? 't-input-disabled' : 't-input-no-disabled',
    focused ? 't-input-focused' : 't-input-no-focused'
  ]" :validation="validation" :validationSilentError="$props.validationSilentError" :focused="focused">

    <!-- start -->
    <span v-if="haveSlotStart" :class="startClass" class="t-input-slot">
      <slot name="start" />
      <div v-if="start" class="t-input-slot-string">{{ start }}</div>
      <button v-if="buttonMinusOn" v-on="buttonMinusOn"
        :class="{ 'border-l': start || $slots.start, 'rounded-l-lg': !(start || $slots.start), 'invisible': !focused }"
        class="border-r bg-gray-50 max-h-full min-h-full min-w-4 max-w-4 w-4 flex cursor-pointer outline-none"
        tabindex="-1">
        <MinusSmallIcon class="inline m-auto" />
      </button>
    </span>

    <span class="w-full" v-hide-label-directive="id">
      <!-- label -->
      <label :for="id" class="t-input-label" v-if="placeholderLabel || validationRequired">
        <span>{{ placeholderLabel }}</span>
        <span class="text-gray-400 text-xs" v-if="validationRequired">&nbsp;&#8727;&nbsp;</span>
      </label>

      <!-- input -->
      <textarea v-if="multiline" ref="input" :id="id" :value="val" v-bind="inputAttrs" v-on="inputOn" />
      <input v-else :id="id" ref="input" :value="val" v-bind="inputAttrs" v-on="inputOn" />

      <div v-if="multiline && $attrs.maxlength" ref="textarealength"
        class="absolute bottom-1 right-4 text-gray-400 text-sm select-none" />
    </span>

    <!-- end -->
    <span v-if="haveSlotEnd" :class="$props.endClass" class="t-input-slot">
      <button v-if="buttonPlusOn" v-on="buttonPlusOn"
        :class="{ 'border-r': end || $slots.end, 'rounded-r-lg': !(end || $slots.end), 'invisible': !focused }"
        class="border-l bg-gray-50 max-h-full min-h-full min-w-4 max-w-4 w-4 flex cursor-pointer outline-none"
        tabindex="-1">
        <PlusSmallIcon class="inline m-auto" />
      </button>
      <div v-if="end" class="t-input-slot-string">{{ end }}</div>
      <slot name="end" />
    </span>
  </ErrorTooltip>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch, reactive, onMounted, onUpdated, PropType, DefineComponent, InputHTMLAttributes, TextareaHTMLAttributes, getCurrentInstance } from "vue";
import { PlusSmallIcon, MinusSmallIcon } from '@heroicons/vue/24/solid'

import { useFocus } from '@/composition/focus'

import { ValidationOf } from "@/lib/vuelidate.type";
import { autoFocus } from "@/composition/autoFocus";
import { hideLabelDirective } from '@/directives/hide-label'

import ErrorTooltip from './error/ErrorTooltip.vue'

export default defineComponent({
  name: "t-input",
  inheritAttrs: false,
  components: {
    ErrorTooltip,
    PlusSmallIcon, MinusSmallIcon
  },
  mixins: [] as unknown as [DefineComponent<InputHTMLAttributes>, DefineComponent<TextareaHTMLAttributes>],
  directives: { hideLabelDirective },
  props: {
    /** placeholder + label */
    placeholder: String,

    /** default value added to placeholder */
    default: [String, Number],

    multiline: { type: Boolean, default: () => false },

    modelValue: [String, Number],
    modelModifiers: Object as PropType<{ integer?: true; float?: true; custom?: true; lazyoff?: true }>,

    inputClass: [String, Object],

    // if modelModifiers custom
    formatter: Object as PropType<InputFormatter>,

    // if modelModifiers float
    //    format number and add attribut step on input
    //    Ex: <t-input float-precision="3" type="number" v-model.float=...
    floatPrecision: [String, Number],

    validation: Object as PropType<ValidationOf<number | string, { required?: any }>>,
    validationSilentError: { type: Boolean, default: () => false },

    end: String,
    endClass: String,

    start: String,
    startClass: String,

    disabled: Boolean,

    // just to detect if @input listener is set
    onInput: { type: Function, default: () => undefined }
  },
  emits: ['update:model-value', 'change', 'input',],
  setup(props, { emit, attrs, slots }) {

    const floatPrecision = typeof props.floatPrecision === 'string' ? parseInt(props.floatPrecision) : props.floatPrecision

    const modelModifiers = props.modelModifiers || {}
    const lazyoff = modelModifiers.lazyoff === true

    // refs
    const input = ref<HTMLInputElement | HTMLTextAreaElement>()
    const textarealength = ref<HTMLDivElement>()

    // label
    const placeholderLabel = computed(() => (props.placeholder && props.placeholder != ' ') ? props.placeholder : '');
    const placeholderWithDefault = computed(() => props.default ? `${placeholderLabel.value}: ${props.default}` : `${placeholderLabel.value}`)

    // formatter
    if (modelModifiers.custom && !props.formatter)
      throw new Error('input with model modifier custom need a formatter')

    const formatter = modelModifiers.integer ? new IntegerFormatter() :
      modelModifiers.float ? new FloatFormatter(floatPrecision) :
        modelModifiers.custom ? props.formatter! :
          new DefaultFormatter();

    /** don't update ref here */
    function localValChange(v: any) {
      if (props.onInput)
        emit('input', formatter.parse(v))

      // update live length of textare content 
      if (textarealength.value)
        textarealength.value.textContent = `${v?.length || 0} / ${attrs.maxlength}`;
    }

    // input value
    const val = ref<string | undefined>(props.modelValue !== undefined && props.modelValue !== null && formatter.stringify(props.modelValue) || undefined)
    let skipFormat = false
    watch(() => props.modelValue, () => {
      val.value = props.modelValue === undefined ? '' :
        skipFormat ? `${props.modelValue}` :
          formatter.stringify(props.modelValue)
      if (skipFormat) skipFormat = false
    }, { immediate: true })

    onMounted(() => {
      if ("autofocus" in attrs && attrs.autofocus !== false) autoFocus(input)
      localValChange(val.value)
    })
    onUpdated(() => localValChange(val.value))

    // error
    const errors = computed(() =>
      props.validation ?
        props.validationSilentError ?
          props.validation.$silentErrors : props.validation.$errors : []
    )

    const inputOn: Record<string, (e: any) => void> = {}
    let buttonPlusOn: Record<string, (e: any) => void> | undefined;
    let buttonMinusOn: Record<string, (e: any) => void> | undefined;

    const { focused, onFocusChange } = useFocus(eventChange)
    inputOn.focusout = onFocusChange
    inputOn.focus = onFocusChange

    const haveSlotStart = computed(() => props.start !== undefined || slots.start !== undefined || buttonMinusOn !== undefined)
    const haveSlotEnd = computed(() => props.end !== undefined || slots.end !== undefined || buttonPlusOn !== undefined)

    // class + style
    const { class: containerClass, type, min, max, step, ...othersAttrs } = attrs

    const inputAttrs = reactive({ ...othersAttrs })
    inputAttrs.required = props.validation && props.validation.required !== undefined
    inputAttrs.placeholder = placeholderWithDefault

    inputAttrs.disabled = computed(() => props.disabled)

    inputAttrs.class = computed(() => {
      return [
        props.inputClass,
        haveSlotStart.value ? 'rounded-l-none' : 'rounded-l-lg',
        haveSlotEnd.value ? 'rounded-r-none' : 'rounded-r-lg', 'w-full',
        props.multiline ? 'p-2' : 'p-2'
      ]
    })

    const typeNumber = (type === "number" || (!type && (modelModifiers.integer || modelModifiers.float)))
    if (typeNumber) {
      // inputAttrs.type = "number"
      inputAttrs.inputmode = "numeric"
      inputAttrs.pattern = "[0-9]*"

      const minVal = min === undefined ? null : parseFloat(min as string)
      const maxVal = max === undefined ? null : parseFloat(max as string)
      const stepVal = step === undefined ? null : parseFloat(step as string)

      if (stepVal) {
        inputAttrs.step = stepVal.toString()
      } else if (floatPrecision !== undefined) {
        const prefix = (floatPrecision >= 1) ? [...Array(floatPrecision - 1)].reduce(r => `${r}0`, '0.') : '';
        inputAttrs.step = `${prefix}1`
      }

      let hold = false
      const increment = ($event?: KeyboardEvent | MouseEvent, plus?: boolean) => {

        if ($event instanceof KeyboardEvent) {
          if ($event?.key === 'ArrowUp') {
            plus = true; $event.preventDefault(); $event.stopPropagation();
          } else if ($event?.key === 'ArrowDown') {
            plus = false; $event.preventDefault(); $event.stopPropagation();
          } else {
            return
          }
        } else if ($event instanceof MouseEvent) {
          $event.preventDefault()
          $event.stopPropagation()
        } else {
          return
        }

        // const cursorPosition = (($event.target as any).selectionStart + 1) || 1
        // localValChange(val.value)


        let next = formatter.parse(val.value)
        if (plus === true) {
          next = (next || ((minVal || 1) - 1)) + (stepVal || 1)
          if (maxVal !== null && next > maxVal) return
        } else if (plus === false) {
          next = (next || 0) - (stepVal || 1)
          if (minVal !== null && next < minVal) return
        }

        if ($event && $event.type === 'keydown') {
          if (hold) val.value = formatter.stringify(next)
          hold = true
        } else {
          // keyup or click
          hold = false
          emit('update:model-value', next)
          emit('change', next)
        }
      }

      inputOn.keydown = increment
      inputOn.keyup = increment
      inputOn.click = ($event: EventWithTarget<HTMLInputElement | HTMLTextAreaElement>) => $event.target?.select()
      buttonPlusOn = {
        focusout: onFocusChange,
        focus: onFocusChange,
        click: ($event: MouseEvent) => increment($event, true)
      }
      buttonMinusOn = {
        focusout: onFocusChange,
        focus: onFocusChange,
        click: ($event: MouseEvent) => increment($event, false)
      }

    } else {
      inputAttrs.type = type
    }

    function eventChange() {
      const inputV = input.value?.value
      if (inputV !== undefined && inputV !== `${val.value}` && !(val.value === undefined && inputV === '')) {
        // input value change
        const inputVal = formatter.parse(inputV)
        if (inputVal != props.modelValue) {
          // model value change
          emit('update:model-value', inputVal)
          emit('change', inputVal)
        }
      }
    }
    inputOn.change = eventChange
    inputOn.mouseout = eventChange

    // - format input on each new caracter
    // - emit value if validation error to trigger validation rule
    let valSaved: string | undefined = val.value
    inputOn.input = function eventInput($event: Event) {
      const inputEl = ($event.target as HTMLInputElement | HTMLTextAreaElement)
      if (!inputEl) return

      localValChange(inputEl.value)

      if (inputEl.value != undefined && inputEl.value !== `${val.value}`) {
        let valNew = formatter.format(inputEl.value, valSaved);
        if (valNew != undefined) {
          valSaved = valNew
          if (lazyoff) {
            valNew = formatter.parse(valNew)
            emit('update:model-value', valNew)
            emit('change', valNew)
          } else {
            if (props.validation && props.validation.$error) {
              skipFormat = true
              const v = formatter.parse(valNew)
              emit('update:model-value', v)
              emit('change', v)
            } else {
              inputEl.value = valNew
            }
          }
        }
      }
    }

    return {
      placeholderLabel,

      val,
      errors,

      focused,

      // $refs
      input,
      textarealength,

      containerClass,

      id: computed(() => props.id || ('input' + getCurrentInstance()?.uid)),

      validationRequired: computed(() => (props.validation && props.validation.required !== undefined) || ('required' in othersAttrs && othersAttrs.required !== false)),

      inputAttrs,
      inputOn,
      buttonPlusOn,
      buttonMinusOn,

      haveSlotStart,
      haveSlotEnd
    }
  }
});

/**
 * @returns (string) => string
 * 
 * Example with 2: '0.100' => '0,10' | '0,111' => '0,11'
 */
function formatFloatPrecision(precision = 0) {
  const reg = precision ?
    new RegExp(`^[0-9]*[,|.]{0,1}[0-9]{0,${precision}}$`)
    :
    new RegExp(`^[0-9]*$`)
  return (val?: string, oldVal?: string) => {
    if (!val) return ''
    return reg.test(val) ? val : oldVal
  }
}

class DefaultFormatter implements InputFormatter<string>{
  format(val?: string) { return val }
  stringify(val: string) { return val }
  parse(val: string) { return val }
}

class IntegerFormatter implements InputFormatter<number>{
  format = formatFloatPrecision(0)
  stringify(val?: number) { return (val === null || val === undefined) ? '' : val.toFixed(0) }
  parse(val?: string) {
    if (!val) return undefined
    const v = parseInt(val);
    if (isNaN(v)) return undefined
    return v;
  }
}

class FloatFormatter implements InputFormatter<number>{
  private form: InputFormatter['format']
  constructor(private precision: number = 2) {
    this.form = formatFloatPrecision(this.precision)
  }
  format(val?: string, oldVal?: string): string | undefined {
    return this.form(val, oldVal)
  }
  stringify(val: number) {
    if (val === undefined || val === null) return ''
    return val.toFixed(this.precision)
  }
  parse(val?: string) {
    if (!val) return undefined
    const v = parseFloat(val.replace(',', '.'));
    if (isNaN(v)) return undefined
    return v;
  }
}
</script>

<style lang="postcss">
.t-input {
  @apply flex my-2 h-fit border-2 rounded-lg;

  &.t-input-disabled {
    @apply t-background-disabled;
  }

  &.t-input-no-disabled {
    @apply bg-white;
  }

  &.t-input-focused {
    @apply t-outline-ocean;
  }

  &.t-error {
    &.t-input-no-focused {
      @apply border-red-300
    }

    &.t-input-focused {
      @apply border-red-500 outline-none;
    }
  }

  >.t-input-slot {
    @apply flex items-center;

    >.t-input-slot-string {
      @apply text-center px-1 text-gray-500 sm:text-sm pointer-events-none h-full flex items-center whitespace-nowrap;
    }
  }

  input,
  textarea,
  input:focus-visible,
  textarea:focus-visible {
    @apply outline-none;
  }
}
</style>