import { ref, unref, watch, onUnmounted, nextTick, isRef, handleError, Ref, ObjectDirective, DirectiveBinding } from "vue";

import { useVuelidate } from "../lib/vuelidate";
import { VArgs, RootValidationOf, ValidationOf } from "../lib/vuelidate.type";

import plugins from "@/plugins"

import * as validator from './vuelidate.validator'

export { validator }

/**
 * @param state 
 * @param validationsArgs validation rules. rem: list all field to detect dirty.
 * @param lockDirty emit anyDirty event on change anyDirty state
 */
export function useValidation<T, V extends VArgs<T>>(state: Ref<T> | T, validationsArgs: V | Ref<V>, options: { skipLockDirty?: boolean } = {}) {

    // save initial state with json string
    const stateStringify = ref(JSON.stringify(unref(state), stringEmpty));

    const refObj = isRef(state) ? state : ref(state) as Ref<T>
    const validationObj = useVuelidate(validationsArgs, refObj, { $autoDirty: true, $autoDirtyCompare: compare, $lazy: true })

    const rootEmitter = options.skipLockDirty ? null : plugins.$emitter

    // - emit event anyDirty, to sync with vue router lock dirty
    const startRootWatch = () => watch(() => validationObj.value.$anyDirty, (anyDirty, oldAnyDirty) => {
        if (rootEmitter && anyDirty !== oldAnyDirty)
            anyDirty ? rootEmitter.lock(validationObj.value.$uid) : rootEmitter.unlock(validationObj.value.$uid)
    });

    // if state null, waiting the first `setVal()` to enable watch lock dirty
    let rootUnwatch = state === null ? null : startRootWatch()

    onUnmounted(() => {
        if (rootUnwatch) rootUnwatch()
        if (rootEmitter) rootEmitter.unlock(validationObj.value.$uid)
    })

    return {
        refObj,
        validationObj,

        /**
         * set value of refObj and save state to detect future changes.
         * @param obj optional set refObj. if undefined, use current val of refObj, refObj will therefore be considered as unmodified.
         */
        setVal(obj?: T) {
            if (rootUnwatch === null) rootUnwatch = startRootWatch()

            if (obj !== undefined && !(obj instanceof Event))
                refObj.value = obj

            stateStringify.value = JSON.stringify(refObj.value, stringEmpty);

            return new Promise<void>(r => {
                nextTick(() => {
                    validationObj.value.$record();
                    nextTick(() => { r() })
                })
            })
        },

        /**
         * disard changes => reload stateStringify
         * @param force set the value to null temporarily, before reloading
         */
        resetVal(force = false) {
            if (force === true) (refObj.value as any) = null

            return new Promise<void>(r => {
                nextTick(() => {
                    refObj.value = JSON.parse(stateStringify.value)
                    validationObj.value.$record();
                    nextTick(() => { r() })
                })
            })
        }
    }
}

function stringEmpty(key: string, value: any) {
    return value === '' ? void (0) : value
}

function compare(a: any, b: any) {
    if (a === '') a = undefined
    else if (Array.isArray(a) && a.length === 0) a = undefined

    if (b === '') b = undefined
    else if (Array.isArray(b) && b.length === 0) b = undefined
    return a === b
}



class TypeWrapperValidation<T, V extends VArgs<T>> {
    useValidation = () => useValidation<T, V>(null as any, null as any);
}
export type ReturnTypeValidation<T, R extends VArgs<T> = VArgs<T>> = ReturnType<TypeWrapperValidation<T, R>['useValidation']>

/**
 * use the change detection to:
 * - update only dirty resources
 * - add new resources (without id or id < 0)
 * - delete old resources
 * 
 * @param apiAdd 
 * @param apiUpdate 
 * @param apiDelete 
 * @param validation (see return of validation())
 * @param loadResources load resources on init and reload reources after sync (use this only if you dont call validation.reset)
 */
export function syncLazyResources<Z extends { id?: number }, T extends Z = Z, V extends VArgs<T[]> = VArgs<T[]>>(
    apiAdd: (r: Z) => Promise<Z>,
    apiUpdate: (r: Z) => Promise<Z>,
    apiDelete: (r: { id?: number; }) => Promise<any>,

    validation: ReturnTypeValidation<T[], V>,

    loadResources?: () => Promise<{ values?: T[], count?: number }>,
    onLoadResources?: (data: { values?: T[], count?: number }) => void,
    onReloadResources?: (data: { values?: T[], count?: number }) => void,
) {

    async function load() {
        if (loadResources) {
            const r = await loadResources()
            await validation.setVal(r.values)
            if (onLoadResources)
                onLoadResources(r)
        }
    }

    load()

    /**
     * sync resources
     * 
     * @param partial list of resource ids to sync (null for new resources). If not set, all resources will be synced. 
     */
    return {
        load,
        async sync(partial?: number[]) {

            const cacheIds = validation.validationObj.value.$getRecord().map(v => v.id)

            let resourcesSkipped = 0
            let addAndRemove = 0

            // result
            const deleted: number[] = []
            const updated: number[] = []
            const added = new Map<number, number>() // <local id, saved id>

            // update or add
            for (const validationItem of validation.validationObj.value.$each) {
                const { id: resourceId, ...resource } = validationItem.$model

                const cacheResourceIndex = resourceId ? cacheIds.findIndex(r => r === resourceId) : -1
                if (cacheResourceIndex !== -1)
                    cacheIds.splice(cacheResourceIndex, 1)

                if (resourceId && resourceId > 0) {
                    if (validationItem.$anyDirty) {
                        if ((!partial || partial.some(p => p === resourceId))) {
                            await apiUpdate(validationItem.$model as T);
                            updated.push(resourceId)
                            validationItem.$record()
                        } else {
                            resourcesSkipped++
                        }
                    }
                } else {
                    if (!partial || partial.some(p => p === null)) {
                        const newR = await apiAdd(resource as T)
                        if (resourceId) added.set(resourceId, newR.id!)
                        validationItem.$record()
                        addAndRemove++
                    } else {
                        resourcesSkipped++
                    }
                }
            }

            // delete
            for (const idToDelete of cacheIds) {
                if (!idToDelete) continue
                if (!partial || partial.some(p => p === idToDelete)) {
                    await apiDelete({ id: idToDelete })
                    deleted.push(idToDelete)
                    addAndRemove--
                } else {
                    resourcesSkipped++
                }
            }

            // reload or sync length
            if (loadResources && resourcesSkipped === 0) {
                const resources = await loadResources()
                await validation.setVal(resources.values)
                if (onReloadResources)
                    onReloadResources(resources)
            } else if (addAndRemove !== 0) {
                validation.validationObj.value.length.$setRecord(
                    validation.validationObj.value.length.$getRecord() + addAndRemove
                )
            }

            return {
                /** map local id (< 0) to new id of created resource by api */
                getAddedId(localId: number) {
                    return added.get(localId)
                },

                /** all new ids of created resources by api */
                get allAdded() {
                    return [...added.values()]
                },

                get allDeleted() {
                    return deleted
                },

                get allUpdated() {
                    return updated
                }
            }
        }
    }
}


class TypeWrapperSync<Z extends { id?: number }, T extends Z = Z, V extends VArgs<T[]> = VArgs<T[]>>{
    syncLazyResources = () => syncLazyResources<Z, T, V>(null as any, null as any, null as any, null as any);
}
export type ReturnTypeSync<Z extends { id?: number }, T extends Z = Z, V extends VArgs<T[]> = VArgs<T[]>> = ReturnType<TypeWrapperSync<Z, T, V>['syncLazyResources']>


const BUTON_TAG_NAME = 'ion-button'
const BUTTON_CLASS_LOADING = 't-button-loading-vuelidate'
const BUTTON_CLASS_UNCHANGED = 'ion-button-unchanged'
const BUTTON_CLASS_INVALID = 'ion-button-invalid'

interface ClickValidationArgsI {

    click: (...args: any) => Promise<any> | any;

    onInvalid?: () => void;

    /** use RootValidationOf with $uid if you use this directive multiple times */
    validation: RootValidationOf<any> | ValidationOf<any> | (RootValidationOf<any> | ValidationOf<any>)[];

    /** enable click event even if no change (validation.$anyDirty === false) */
    unchanged?: boolean
}


const directives = new Map<HTMLElement, { unwatch: () => void; uid?: number }>()
const locks: Record<number, { locked: boolean; button?: HTMLElement }> = {}

function bindingValue(binding: DirectiveBinding<() => ClickValidationArgsI>) {
    if (!binding.value || typeof binding.value !== 'function') {
        console.warn('vuelidate directive. wrong binding.value')
        return
    }
    return binding.value()
}

function normalizeState(binding: DirectiveBinding<() => ClickValidationArgsI>) {
    const arg = bindingValue(binding)
    if (!arg) return

    const { $invalid, $anyDirty, $uid } =
        !Array.isArray(arg.validation) ?
            { $invalid: arg.validation.$invalid, $anyDirty: arg.validation.$anyDirty, $uid: (arg.validation as any).$uid }
            :
            arg.validation.reduce(
                (result, curr) => ({
                    $invalid: result.$invalid || curr.$invalid,
                    $anyDirty: result.$anyDirty || curr.$anyDirty,
                    $uid: result.$uid + ((curr as any).$uid || 0)
                }),
                { $invalid: false, $anyDirty: false, $uid: 0 }
            )

    return { $invalid, $anyDirty, $uid, unchanged: arg.unchanged, click: arg.click }
}


/**
 * Check validation on click. Click only if validation ok = dirty and valid.
 * 
 * <button v-vuelidate="()=> ({ click: action, validation: form$ })"/>
 * <form v-vuelidate:submit="()=> ({ click: action, validation: form$ })">
 * <form v-vuelidate:submit.prevent="()=> ({ click: action, validation: form$ })">
 */
export const vuelidateDirective: ObjectDirective<HTMLElement, () => ClickValidationArgsI> = {

    created(el, binding) {

        let savedTitle: string | undefined = undefined
        let haveTitle = false
        let saveState = { $invalid: undefined, $anyDirty: undefined, unchanged: undefined } as { $invalid?: boolean, $anyDirty?: boolean, unchanged?: boolean }

        const initState = normalizeState(binding)
        const uid = initState?.$uid ? initState.$uid : new Date().getTime()
        if (!locks[uid]) locks[uid] = { locked: false }

        const eventType: string[] = binding.arg ? [binding.arg] : []

        if (el.localName === BUTON_TAG_NAME) {
            eventType.push('click')
            locks[uid].button = el

            function onStateChange(_state?: { $invalid: boolean, $anyDirty: boolean, unchanged?: boolean }) {
                if (!_state) return

                if (saveState.$invalid && !_state.$invalid) {
                    const title = el.attributes.getNamedItem('title')
                    if (title)
                        title.value = haveTitle && savedTitle ? savedTitle : ''
                }

                if (saveState.$invalid !== _state.$invalid || saveState.$anyDirty !== _state.$anyDirty || saveState.unchanged !== _state.unchanged) {
                    saveState = _state
                    // can't use pure css (ex: ion-button[data-validation='unchanged']) because ionic button use css var in shadow dom element
                    if (!_state.unchanged && _state.$anyDirty === el.classList.contains(BUTTON_CLASS_UNCHANGED))
                        nextTick(() => el.classList.toggle(BUTTON_CLASS_UNCHANGED))

                    if (_state.$invalid !== el.classList.contains(BUTTON_CLASS_INVALID))
                        nextTick(() => el.classList.toggle(BUTTON_CLASS_INVALID))
                }

            }

            const unwatch = watch(() => normalizeState(binding), onStateChange, { deep: true })
            directives.set(el, { unwatch, uid })
            onStateChange(initState)

            if (import.meta.env.DEV) {
                let timeoutPrintDiff: NodeJS.Timeout | undefined = undefined
                const cancelPrintDiff = function () {
                    if (timeoutPrintDiff) clearTimeout(timeoutPrintDiff)
                    timeoutPrintDiff = undefined
                }
                el.addEventListener('mousedown', () => {
                    timeoutPrintDiff = setTimeout(() => {
                        const _validations = bindingValue(binding)
                        if (_validations?.validation)
                            (Array.isArray(_validations.validation) ? _validations.validation : [_validations.validation])
                                .forEach((_validation: any, _validationIdx) => {
                                    if (_validation.$printDiff) _validation.$printDiff(_validationIdx)
                                })
                    }, 500)
                })
                el.addEventListener('mouseup', cancelPrintDiff)
                el.addEventListener('mouseout', cancelPrintDiff)
            }

        } else if (el.localName === 'form') {
            eventType.push('submit', 'keyup.enter')
            const input = document.createElement("input");
            input.setAttribute("type", "submit");
            input.setAttribute("hidden", '');
            el.appendChild(input)
            el.setAttribute('novalidate', '')
            el.setAttribute("action", '');
            el.addEventListener('submit', (e) => e.preventDefault())
        }

        if (!eventType.length)
            eventType.push('click')

        const eventCall = async (evt: Event) => {

            if (binding.modifiers.prevent) evt.preventDefault();

            const lock = locks[uid]
            if (lock?.locked) return
            if (lock?.button) lock.button.classList.add(BUTTON_CLASS_LOADING)

            const _instance = binding.instance?.$
            try {
                lock.locked = true

                const arg = bindingValue(binding)
                if (!arg) return

                const valid = !Array.isArray(arg.validation) ?
                    arg.validation.$validate()
                    :
                    arg.validation.map(v => v.$validate())
                        .find(v => v === false) === undefined

                if (valid) {
                    try {
                        const _arg = normalizeState(binding)
                        if (_arg && !_arg.$invalid && (_arg.$anyDirty || _arg.unchanged))
                            await _arg.click()
                    } catch (e) {
                        if (_instance)
                            handleError(e, _instance, 6)
                    }

                } else {
                    const _arg = bindingValue(binding)
                    if (!_arg) return

                    if (arg.onInvalid) arg.onInvalid()

                    if (el.localName === BUTON_TAG_NAME) {
                        const silentErrors = (!Array.isArray(_arg.validation) ? [_arg.validation] : _arg.validation)
                            .map(v => v.$silentErrors)
                            .flat(1)

                        if (silentErrors) {
                            let title = el.attributes.getNamedItem('title')
                            if (title) {
                                if (!haveTitle) {
                                    haveTitle = true
                                    savedTitle = title.value
                                }
                            } else {
                                title = document.createAttribute('title')
                                el.attributes.setNamedItem(title)
                            }
                            title.value = silentErrors
                                .map(e => e.$propertyPath ? `${e.$propertyPath}: ${e.$message}` : e.$message)
                                .join('\n')

                            document.getElementById(`v-${silentErrors[0].$propertyPath}`)
                                ?.scrollIntoView({ behavior: "smooth", block: 'center' });
                        }
                    }
                }
            } catch (e) {
                if (!_instance) throw e
            } finally {
                if (lock?.button) lock.button.classList.remove(BUTTON_CLASS_LOADING)
                if (lock?.locked) lock.locked = false
            }
        }

        eventType.forEach(e => el.addEventListener(e, eventCall))
    },

    unmounted(el) {
        if (directives.has(el)) {
            const dir = directives.get(el)
            if (dir) {
                dir.unwatch()
                if (dir.uid && [...directives.values()].filter(d => d.uid === dir.uid).length === 1)
                    delete locks[dir.uid]
                directives.delete(el)
            }
        }
    }

}