import { Composer } from "vue-i18n"

export class Languages {
    private local = {
        code: 'fr',
        languageNames: new Intl.DisplayNames(['fr'], { type: 'language' }),
        regionNames: new Intl.DisplayNames(['fr'], { type: 'region' })
    }
    private all?: Map<string, LANG>

    constructor(private i18n: Composer) { }

    get languages() {
        if (this.local.code !== this.i18n.locale.value || !this.all) {
            this.local = {
                code: this.i18n.locale.value,
                languageNames: new Intl.DisplayNames([this.i18n.locale.value], { type: 'language' }),
                regionNames: new Intl.DisplayNames([this.i18n.locale.value], { type: 'region' })
            }

            this.all = new Map()
            for (const l of CODE_LANGUAGES)
                for (const country of l.countries) {
                    const code = `${l.code}${country.alpha2}`
                    const nameCountry = this.local.regionNames.of(country.alpha2) || ''
                    const nameLang = this.local.languageNames.of(l.code) || ''
                    const name = l.countries.length !== 1 ? `${nameLang} - ${nameCountry}` : nameLang
                    this.all!.set(code, {
                        code,
                        name,
                        nameCountry,
                        nameLang,
                        codeCountry: country.alpha2,
                        codeLang: l.code,
                        defaultCode: l.countries.filter(c => c.default).map(c => `${l.code}${c.alpha2}`),
                        flagCountry: Languages.getFlagEmoji(country.alpha2),
                    })
                }
        }

        return this.all
    }

    get(code: string) {
        return this.languages.get(code)
    }

    getAll(key: string[] = []) {
        return key.map(l => this.get(l)!).filter(l => !!l)
    }

    /**
     * @param query 
     * @param excludeCode 
     * @param maxResult (5 default)
     */
    search(query?: string, excludeCode: string[] = [], maxResult: number | null = 5) {
        const languages: LANG[] = []

        const excl = [...excludeCode]
        const all = maxResult === null || (query && query.startsWith('#'))
        query = query ? stringNormalize(query) : query
        for (const lang of this.languages.values()) {
            if (excl.indexOf(lang.code) !== -1)
                continue

            if (!query) {
                languages.push(lang)
            } else if (query.startsWith('#')) {
                const l = query.substring(1, 3).toLowerCase()
                const c = query.substring(3, 5).toLowerCase()
                if (lang.codeLang.toLowerCase().startsWith(l))
                    if (!c) languages.push(lang)
                    else if (lang.codeCountry.toLowerCase().startsWith(c)) languages.push(lang)
            } else if (stringNormalize(lang.nameLang).indexOf(query) !== -1) {
                languages.push(lang)
                excl.push(lang.code)
                for (const d of lang.defaultCode.filter(c => c !== lang.code && excl.indexOf(c) === -1)) {
                    languages.push(this.languages.get(d)!)
                    excl.push(d)
                }
            } else if (stringNormalize(lang.nameCountry).indexOf(query) !== -1) {
                languages.push(lang)
                excl.push(lang.code)
            }

            if (!all && languages.length === maxResult) break
        }
        return languages.sort((a, b) => {
            const uu = (a.nameLang || '').localeCompare(b.nameLang || '')
            if (uu === 0) {
                if (a.defaultCode.indexOf(a.code) !== -1)
                    return -1
                if (b.defaultCode.indexOf(b.code) !== -1)
                    return 1
                return (a.nameCountry || '').localeCompare(b.nameCountry || '')
            }
            return uu
        })
    }

    static getFlagEmoji(countryCode: string) {
        return countryCode
            .split('')
            .map(letter => letter.charCodeAt(0) % 32 + 0x1F1E5)
            .map(n => String.fromCodePoint(n))
            .join('')
    }
}

function stringNormalize(str: string) {
    return str.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

type LangCountries = {
    /** see https://fr.wikipedia.org/wiki/Liste_des_codes_ISO_639-1 */
    code: string;
    countries: {
        /** help to search */
        default?: true;

        /** see https://fr.wikipedia.org/wiki/ISO_3166-1 */
        alpha2: string;

        /** see https://fr.wikipedia.org/wiki/ISO_3166-1 */
        alpha3: string;
    }[]
}

const CODE_LANGUAGES: LangCountries[] = [{
    code: "af",
    countries: [{
        alpha2: "NA",
        alpha3: "NAM"
    }, {
        alpha2: "ZA",
        alpha3: "ZAF"
    }]
}, {
    code: "sq",
    countries: [{
        alpha2: "AL",
        alpha3: "ALB"
    }, {
        alpha2: "ME",
        alpha3: "MNE"
    }]
}, {
    code: "am",
    countries: [{
        alpha2: "ET",
        alpha3: "ETH"
    }]
}, {
    code: "ar",
    countries: [{
        alpha2: "SA",
        alpha3: "SAU"
    }, {
        alpha2: "AE",
        alpha3: "ARE"
    }, {
        alpha2: "BH",
        alpha3: "BHR"
    }, {
        alpha2: "DJ",
        alpha3: "DJI"
    }, {
        alpha2: "DZ",
        alpha3: "DZA"
    }, {
        alpha2: "EG",
        alpha3: "EGY"
    }, {
        alpha2: "ER",
        alpha3: "ERI"
    }, {
        alpha2: "IL",
        alpha3: "ISR"
    }, {
        alpha2: "IQ",
        alpha3: "IRQ"
    }, {
        alpha2: "JO",
        alpha3: "JOR"
    }, {
        alpha2: "KM",
        alpha3: "COM"
    }, {
        alpha2: "KW",
        alpha3: "KWT"
    }, {
        alpha2: "LB",
        alpha3: "LBN"
    }, {
        alpha2: "LY",
        alpha3: "LBY"
    }, {
        alpha2: "MA",
        alpha3: "MAR"
    }, {
        alpha2: "MR",
        alpha3: "MRT"
    }, {
        alpha2: "OM",
        alpha3: "OMN"
    }, {
        alpha2: "PS",
        alpha3: "PSE"
    }, {
        alpha2: "QA",
        alpha3: "QAT"
    }, {
        alpha2: "SD",
        alpha3: "SDN"
    }, {
        alpha2: "SO",
        alpha3: "SOM"
    }, {
        alpha2: "SY",
        alpha3: "SYR"
    }, {
        alpha2: "TD",
        alpha3: "TCD"
    }, {
        alpha2: "TN",
        alpha3: "TUN"
    }, {
        alpha2: "YE",
        alpha3: "YEM"
    }]
}, {
    code: "hy",
    countries: [{
        alpha2: "AM",
        alpha3: "ARM"
    }, {
        alpha2: "CY",
        alpha3: "CYP"
    }]
}, {
    code: "ay",
    countries: [{
        alpha2: "BO",
        alpha3: "BOL"
    }]
}, {
    code: "az",
    countries: [{
        alpha2: "AZ",
        alpha3: "AZE"
    }]
}, {
    code: "eu",
    countries: [{
        alpha2: "ES",
        alpha3: "ESP"
    }]
}, {
    code: "be",
    countries: [{
        alpha2: "BY",
        alpha3: "BLR"
    }]
}, {
    code: "bn",
    countries: [{
        alpha2: "BD",
        alpha3: "BGD"
    }]
}, {
    code: "bi",
    countries: [{
        alpha2: "VU",
        alpha3: "VUT"
    }]
}, {
    code: "bs",
    countries: [{
        alpha2: "BA",
        alpha3: "BIH"
    }, {
        alpha2: "ME",
        alpha3: "MNE"
    }]
}, {
    code: "bg",
    countries: [{
        alpha2: "BG",
        alpha3: "BGR"
    }]
}, {
    code: "my",
    countries: [{
        alpha2: "MM",
        alpha3: "MMR"
    }]
}, {
    code: "ca",
    countries: [{
        alpha2: "AD",
        alpha3: "AND"
    }, {
        alpha2: "ES",
        alpha3: "ESP"
    }]
}, {
    code: "ch",
    countries: [{
        alpha2: "GU",
        alpha3: "GUM"
    }, {
        alpha2: "MP",
        alpha3: "MNP"
    }]
}, {
    code: "ny",
    countries: [{
        alpha2: "MW",
        alpha3: "MWI"
    }]
}, {
    code: "zh",
    countries: [{
        alpha2: "CN",
        alpha3: "CHN"
    }, {
        alpha2: "HK",
        alpha3: "HKG"
    }, {
        alpha2: "MO",
        alpha3: "MAC"
    }, {
        alpha2: "SG",
        alpha3: "SGP"
    }, {
        alpha2: "TW",
        alpha3: "TWN"
    }]
}, {
    code: "hr",
    countries: [{
        alpha2: "BA",
        alpha3: "BIH"
    }, {
        alpha2: "HR",
        alpha3: "HRV"
    }, {
        alpha2: "ME",
        alpha3: "MNE"
    }]
}, {
    code: "cs",
    countries: [{
        alpha2: "CZ",
        alpha3: "CZE"
    }]
}, {
    code: "da",
    countries: [{
        alpha2: "DK",
        alpha3: "DNK"
    }]
}, {
    code: "dv",
    countries: [{
        alpha2: "MV",
        alpha3: "MDV"
    }]
}, {
    code: "nl",
    countries: [{
        alpha2: "AW",
        alpha3: "ABW"
    }, {
        alpha2: "BE",
        alpha3: "BEL"
    }, {
        alpha2: "BQ",
        alpha3: "BES"
    }, {
        alpha2: "CW",
        alpha3: "CUW"
    }, {
        alpha2: "MF",
        alpha3: "MAF"
    }, {
        alpha2: "NL",
        alpha3: "NLD"
    }, {
        alpha2: "SR",
        alpha3: "SUR"
    }, {
        alpha2: "SX",
        alpha3: "SXM"
    }]
}, {
    code: "en",
    countries: [{
        default: true,
        alpha2: "GB",
        alpha3: "GBR"
    }, {
        default: true,
        alpha2: "US",
        alpha3: "USA"
    }, {
        alpha2: "AG",
        alpha3: "ATG"
    }, {
        alpha2: "AI",
        alpha3: "AIA"
    }, {
        alpha2: "AS",
        alpha3: "ASM"
    }, {
        alpha2: "AU",
        alpha3: "AUS"
    }, {
        alpha2: "BB",
        alpha3: "BRB"
    }, {
        alpha2: "BM",
        alpha3: "BMU"
    }, {
        alpha2: "BS",
        alpha3: "BHS"
    }, {
        alpha2: "BW",
        alpha3: "BWA"
    }, {
        alpha2: "BZ",
        alpha3: "BLZ"
    }, {
        alpha2: "CA",
        alpha3: "CAN"
    }, {
        alpha2: "CC",
        alpha3: "CCK"
    }, {
        alpha2: "CK",
        alpha3: "COK"
    }, {
        alpha2: "CM",
        alpha3: "CMR"
    }, {
        alpha2: "CW",
        alpha3: "CUW"
    }, {
        alpha2: "CX",
        alpha3: "CXR"
    }, {
        alpha2: "DM",
        alpha3: "DMA"
    }, {
        alpha2: "ER",
        alpha3: "ERI"
    }, {
        alpha2: "FJ",
        alpha3: "FJI"
    }, {
        alpha2: "FK",
        alpha3: "FLK"
    }, {
        alpha2: "FM",
        alpha3: "FSM"
    }, {
        alpha2: "GD",
        alpha3: "GRD"
    }, {
        alpha2: "GG",
        alpha3: "GGY"
    }, {
        alpha2: "GH",
        alpha3: "GHA"
    }, {
        alpha2: "GI",
        alpha3: "GIB"
    }, {
        alpha2: "GM",
        alpha3: "GMB"
    }, {
        alpha2: "GS",
        alpha3: "SGS"
    }, {
        alpha2: "GU",
        alpha3: "GUM"
    }, {
        alpha2: "GY",
        alpha3: "GUY"
    }, {
        alpha2: "HK",
        alpha3: "HKG"
    }, {
        alpha2: "HM",
        alpha3: "HMD"
    }, {
        alpha2: "IE",
        alpha3: "IRL"
    }, {
        alpha2: "IM",
        alpha3: "IMN"
    }, {
        alpha2: "IN",
        alpha3: "IND"
    }, {
        alpha2: "IO",
        alpha3: "IOT"
    }, {
        alpha2: "JE",
        alpha3: "JEY"
    }, {
        alpha2: "JM",
        alpha3: "JAM"
    }, {
        alpha2: "KE",
        alpha3: "KEN"
    }, {
        alpha2: "KI",
        alpha3: "KIR"
    }, {
        alpha2: "KN",
        alpha3: "KNA"
    }, {
        alpha2: "KY",
        alpha3: "CYM"
    }, {
        alpha2: "LC",
        alpha3: "LCA"
    }, {
        alpha2: "LR",
        alpha3: "LBR"
    }, {
        alpha2: "LS",
        alpha3: "LSO"
    }, {
        alpha2: "MF",
        alpha3: "MAF"
    }, {
        alpha2: "MH",
        alpha3: "MHL"
    }, {
        alpha2: "MP",
        alpha3: "MNP"
    }, {
        alpha2: "MS",
        alpha3: "MSR"
    }, {
        alpha2: "MT",
        alpha3: "MLT"
    }, {
        alpha2: "MU",
        alpha3: "MUS"
    }, {
        alpha2: "MW",
        alpha3: "MWI"
    }, {
        alpha2: "NA",
        alpha3: "NAM"
    }, {
        alpha2: "NF",
        alpha3: "NFK"
    }, {
        alpha2: "NG",
        alpha3: "NGA"
    }, {
        alpha2: "NR",
        alpha3: "NRU"
    }, {
        alpha2: "NU",
        alpha3: "NIU"
    }, {
        alpha2: "NZ",
        alpha3: "NZL"
    }, {
        alpha2: "PG",
        alpha3: "PNG"
    }, {
        alpha2: "PH",
        alpha3: "PHL"
    }, {
        alpha2: "PK",
        alpha3: "PAK"
    }, {
        alpha2: "PN",
        alpha3: "PCN"
    }, {
        alpha2: "PR",
        alpha3: "PRI"
    }, {
        alpha2: "PW",
        alpha3: "PLW"
    }, {
        alpha2: "RW",
        alpha3: "RWA"
    }, {
        alpha2: "SB",
        alpha3: "SLB"
    }, {
        alpha2: "SC",
        alpha3: "SYC"
    }, {
        alpha2: "SD",
        alpha3: "SDN"
    }, {
        alpha2: "SG",
        alpha3: "SGP"
    }, {
        alpha2: "SH",
        alpha3: "SHN"
    }, {
        alpha2: "SL",
        alpha3: "SLE"
    }, {
        alpha2: "SS",
        alpha3: "SSD"
    }, {
        alpha2: "SX",
        alpha3: "SXM"
    }, {
        alpha2: "SZ",
        alpha3: "SWZ"
    }, {
        alpha2: "TC",
        alpha3: "TCA"
    }, {
        alpha2: "TK",
        alpha3: "TKL"
    }, {
        alpha2: "TO",
        alpha3: "TON"
    }, {
        alpha2: "TT",
        alpha3: "TTO"
    }, {
        alpha2: "TV",
        alpha3: "TUV"
    }, {
        alpha2: "TZ",
        alpha3: "TZA"
    }, {
        alpha2: "UG",
        alpha3: "UGA"
    }, {
        alpha2: "UM",
        alpha3: "UMI"
    }, {
        alpha2: "VC",
        alpha3: "VCT"
    }, {
        alpha2: "VG",
        alpha3: "VGB"
    }, {
        alpha2: "VI",
        alpha3: "VIR"
    }, {
        alpha2: "VU",
        alpha3: "VUT"
    }, {
        alpha2: "WS",
        alpha3: "WSM"
    }, {
        alpha2: "ZA",
        alpha3: "ZAF"
    }, {
        alpha2: "ZM",
        alpha3: "ZMB"
    }, {
        alpha2: "ZW",
        alpha3: "ZWE"
    }]
}, {
    code: "et",
    countries: [{
        alpha2: "EE",
        alpha3: "EST"
    }]
}, {
    code: "fo",
    countries: [{
        alpha2: "FO",
        alpha3: "FRO"
    }]
}, {
    code: "fj",
    countries: [{
        alpha2: "FJ",
        alpha3: "FJI"
    }]
}, {
    code: "fi",
    countries: [{
        alpha2: "FI",
        alpha3: "FIN"
    }]
}, {
    code: "fr",
    countries: [{
        alpha2: "BE",
        alpha3: "BEL"
    }, {
        alpha2: "BF",
        alpha3: "BFA"
    }, {
        alpha2: "BI",
        alpha3: "BDI"
    }, {
        alpha2: "BJ",
        alpha3: "BEN"
    }, {
        alpha2: "BL",
        alpha3: "BLM"
    }, {
        alpha2: "CA",
        alpha3: "CAN"
    }, {
        alpha2: "CD",
        alpha3: "COD"
    }, {
        alpha2: "CF",
        alpha3: "CAF"
    }, {
        alpha2: "CG",
        alpha3: "COG"
    }, {
        alpha2: "CH",
        alpha3: "CHE"
    }, {
        alpha2: "CI",
        alpha3: "CIV"
    }, {
        alpha2: "CM",
        alpha3: "CMR"
    }, {
        alpha2: "DJ",
        alpha3: "DJI"
    }, {
        default: true,
        alpha2: "FR",
        alpha3: "FRA"
    }, {
        alpha2: "GA",
        alpha3: "GAB"
    }, {
        alpha2: "GF",
        alpha3: "GUF"
    }, {
        alpha2: "GG",
        alpha3: "GGY"
    }, {
        alpha2: "GN",
        alpha3: "GIN"
    }, {
        alpha2: "GP",
        alpha3: "GLP"
    }, {
        alpha2: "GQ",
        alpha3: "GNQ"
    }, {
        alpha2: "HT",
        alpha3: "HTI"
    }, {
        alpha2: "JE",
        alpha3: "JEY"
    }, {
        alpha2: "KM",
        alpha3: "COM"
    }, {
        alpha2: "LB",
        alpha3: "LBN"
    }, {
        alpha2: "LU",
        alpha3: "LUX"
    }, {
        alpha2: "MC",
        alpha3: "MCO"
    }, {
        alpha2: "MF",
        alpha3: "MAF"
    }, {
        alpha2: "MG",
        alpha3: "MDG"
    }, {
        alpha2: "ML",
        alpha3: "MLI"
    }, {
        alpha2: "MQ",
        alpha3: "MTQ"
    }, {
        alpha2: "NC",
        alpha3: "NCL"
    }, {
        alpha2: "NE",
        alpha3: "NER"
    }, {
        alpha2: "PF",
        alpha3: "PYF"
    }, {
        alpha2: "PM",
        alpha3: "SPM"
    }, {
        alpha2: "RE",
        alpha3: "REU"
    }, {
        alpha2: "RW",
        alpha3: "RWA"
    }, {
        alpha2: "SC",
        alpha3: "SYC"
    }, {
        alpha2: "SN",
        alpha3: "SEN"
    }, {
        alpha2: "TD",
        alpha3: "TCD"
    }, {
        alpha2: "TF",
        alpha3: "ATF"
    }, {
        alpha2: "TG",
        alpha3: "TGO"
    }, {
        alpha2: "VU",
        alpha3: "VUT"
    }, {
        alpha2: "WF",
        alpha3: "WLF"
    }, {
        alpha2: "YT",
        alpha3: "MYT"
    }]
}, {
    code: "ff",
    countries: [{
        alpha2: "BF",
        alpha3: "BFA"
    }, {
        alpha2: "GN",
        alpha3: "GIN"
    }]
}, {
    code: "gl",
    countries: [{
        alpha2: "ES",
        alpha3: "ESP"
    }]
}, {
    code: "ka",
    countries: [{
        alpha2: "GE",
        alpha3: "GEO"
    }]
}, {
    code: "de",
    countries: [{
        alpha2: "AT",
        alpha3: "AUT"
    }, {
        alpha2: "BE",
        alpha3: "BEL"
    }, {
        alpha2: "CH",
        alpha3: "CHE"
    }, {
        default: true,
        alpha2: "DE",
        alpha3: "DEU"
    }, {
        alpha2: "LI",
        alpha3: "LIE"
    }, {
        alpha2: "LU",
        alpha3: "LUX"
    }]
}, {
    code: "el",
    countries: [{
        alpha2: "CY",
        alpha3: "CYP"
    }, {
        alpha2: "GR",
        alpha3: "GRC"
    }]
}, {
    code: "gn",
    countries: [{
        alpha2: "AR",
        alpha3: "ARG"
    }, {
        alpha2: "PY",
        alpha3: "PRY"
    }]
}, {
    code: "ht",
    countries: [{
        alpha2: "HT",
        alpha3: "HTI"
    }]
}, {
    code: "he",
    countries: [{
        alpha2: "IL",
        alpha3: "ISR"
    }]
}, {
    code: "hi",
    countries: [{
        alpha2: "IN",
        alpha3: "IND"
    }, {
        alpha2: "FJ",
        alpha3: "FJI"
    }]
}, {
    code: "hu",
    countries: [{
        alpha2: "HU",
        alpha3: "HUN"
    }]
}, {
    code: "id",
    countries: [{
        alpha2: "ID",
        alpha3: "IDN"
    }]
}, {
    code: "ga",
    countries: [{
        alpha2: "IE",
        alpha3: "IRL"
    }]
}, {
    code: "is",
    countries: [{
        alpha2: "IS",
        alpha3: "ISL"
    }]
}, {
    code: "it",
    countries: [{
        alpha2: "CH",
        alpha3: "CHE"
    }, {
        alpha2: "IT",
        alpha3: "ITA"
    }, {
        alpha2: "SM",
        alpha3: "SMR"
    }, {
        alpha2: "VA",
        alpha3: "VAT"
    }]
}, {
    code: "ja",
    countries: [{
        alpha2: "JP",
        alpha3: "JPN"
    }]
}, {
    code: "kl",
    countries: [{
        alpha2: "GL",
        alpha3: "GRL"
    }]
}, {
    code: "kk",
    countries: [{
        alpha2: "KZ",
        alpha3: "KAZ"
    }]
}, {
    code: "km",
    countries: [{
        alpha2: "KH",
        alpha3: "KHM"
    }]
}, {
    code: "rw",
    countries: [{
        alpha2: "RW",
        alpha3: "RWA"
    }]
}, {
    code: "ky",
    countries: [{
        alpha2: "KG",
        alpha3: "KGZ"
    }]
}, {
    code: "kg",
    countries: [{
        alpha2: "CD",
        alpha3: "COD"
    }]
}, {
    code: "ko",
    countries: [{
        alpha2: "KP",
        alpha3: "PRK"
    }, {
        alpha2: "KR",
        alpha3: "KOR"
    }]
}, {
    code: "ku",
    countries: [{
        alpha2: "IQ",
        alpha3: "IRQ"
    }]
}, {
    code: "la",
    countries: [{
        alpha2: "VA",
        alpha3: "VAT"
    }]
}, {
    code: "lb",
    countries: [{
        alpha2: "LU",
        alpha3: "LUX"
    }]
}, {
    code: "ln",
    countries: [{
        alpha2: "CD",
        alpha3: "COD"
    }, {
        alpha2: "CG",
        alpha3: "COG"
    }]
}, {
    code: "lo",
    countries: [{
        alpha2: "LA",
        alpha3: "LAO"
    }]
}, {
    code: "lt",
    countries: [{
        alpha2: "LT",
        alpha3: "LTU"
    }]
}, {
    code: "lu",
    countries: [{
        alpha2: "CD",
        alpha3: "COD"
    }]
}, {
    code: "lv",
    countries: [{
        alpha2: "LV",
        alpha3: "LVA"
    }]
}, {
    code: "gv",
    countries: [{
        alpha2: "IM",
        alpha3: "IMN"
    }]
}, {
    code: "mk",
    countries: [{
        alpha2: "MK",
        alpha3: "MKD"
    }]
}, {
    code: "mg",
    countries: [{
        alpha2: "MG",
        alpha3: "MDG"
    }]
}, {
    code: "ms",
    countries: [{
        alpha2: "BN",
        alpha3: "BRN"
    }, {
        alpha2: "MY",
        alpha3: "MYS"
    }, {
        alpha2: "SG",
        alpha3: "SGP"
    }]
}, {
    code: "mt",
    countries: [{
        alpha2: "MT",
        alpha3: "MLT"
    }]
}, {
    code: "mi",
    countries: [{
        alpha2: "NZ",
        alpha3: "NZL"
    }]
}, {
    code: "mh",
    countries: [{
        alpha2: "MH",
        alpha3: "MHL"
    }]
}, {
    code: "mn",
    countries: [{
        alpha2: "MN",
        alpha3: "MNG"
    }]
}, {
    code: "na",
    countries: [{
        alpha2: "NR",
        alpha3: "NRU"
    }]
}, {
    code: "nb",
    countries: [{
        alpha2: "BV",
        alpha3: "BVT"
    }, {
        alpha2: "NO",
        alpha3: "NOR"
    }]
}, {
    code: "nd",
    countries: [{
        alpha2: "ZW",
        alpha3: "ZWE"
    }]
}, {
    code: "ne",
    countries: [{
        alpha2: "NP",
        alpha3: "NPL"
    }]
}, {
    code: "nn",
    countries: [{
        alpha2: "BV",
        alpha3: "BVT"
    }, {
        alpha2: "NO",
        alpha3: "NOR"
    }]
}, {
    code: "no",
    countries: [{
        alpha2: "BV",
        alpha3: "BVT"
    }, {
        alpha2: "NO",
        alpha3: "NOR"
    }, {
        alpha2: "SJ",
        alpha3: "SJM"
    }]
}, {
    code: "nr",
    countries: [{
        alpha2: "ZA",
        alpha3: "ZAF"
    }]
}, {
    code: "oc",
    countries: [{
        alpha2: "ES",
        alpha3: "ESP"
    }]
}, {
    code: "pa",
    countries: [{
        alpha2: "AW",
        alpha3: "ABW"
    }, {
        alpha2: "CW",
        alpha3: "CUW"
    }]
}, {
    code: "fa",
    countries: [{
        alpha2: "IR",
        alpha3: "IRN"
    }]
}, {
    code: "pl",
    countries: [{
        alpha2: "PL",
        alpha3: "POL"
    }]
}, {
    code: "ps",
    countries: [{
        alpha2: "AF",
        alpha3: "AFG"
    }]
}, {
    code: "pt",
    countries: [{
        alpha2: "AO",
        alpha3: "AGO"
    }, {
        alpha2: "BR",
        alpha3: "BRA"
    }, {
        alpha2: "CV",
        alpha3: "CPV"
    }, {
        alpha2: "GW",
        alpha3: "GNB"
    }, {
        alpha2: "MO",
        alpha3: "MAC"
    }, {
        alpha2: "MZ",
        alpha3: "MOZ"
    }, {
        alpha2: "PT",
        alpha3: "PRT"
    }, {
        alpha2: "ST",
        alpha3: "STP"
    }, {
        alpha2: "TL",
        alpha3: "TLS"
    }]
}, {
    code: "qu",
    countries: [{
        alpha2: "BO",
        alpha3: "BOL"
    }]
}, {
    code: "rn",
    countries: [{
        alpha2: "BI",
        alpha3: "BDI"
    }]
}, {
    code: "ro",
    countries: [{
        alpha2: "MD",
        alpha3: "MDA"
    }, {
        alpha2: "RO",
        alpha3: "ROU"
    }]
}, {
    code: "ru",
    countries: [{
        alpha2: "AM",
        alpha3: "ARM"
    }, {
        alpha2: "BY",
        alpha3: "BLR"
    }, {
        alpha2: "KG",
        alpha3: "KGZ"
    }, {
        alpha2: "KZ",
        alpha3: "KAZ"
    }, {
        alpha2: "RU",
        alpha3: "RUS"
    }, {
        alpha2: "TJ",
        alpha3: "TJK"
    }, {
        alpha2: "TM",
        alpha3: "TKM"
    }, {
        alpha2: "UZ",
        alpha3: "UZB"
    }]
}, {
    code: "sm",
    countries: [{
        alpha2: "AS",
        alpha3: "ASM"
    }, {
        alpha2: "WS",
        alpha3: "WSM"
    }]
}, {
    code: "sg",
    countries: [{
        alpha2: "CF",
        alpha3: "CAF"
    }]
}, {
    code: "sr",
    countries: [{
        alpha2: "BA",
        alpha3: "BIH"
    }, {
        alpha2: "ME",
        alpha3: "MNE"
    }, {
        alpha2: "RS",
        alpha3: "SRB"
    }]
}, {
    code: "sn",
    countries: [{
        alpha2: "ZW",
        alpha3: "ZWE"
    }]
}, {
    code: "si",
    countries: [{
        alpha2: "LK",
        alpha3: "LKA"
    }]
}, {
    code: "sk",
    countries: [{
        alpha2: "CZ",
        alpha3: "CZE"
    }, {
        alpha2: "SK",
        alpha3: "SVK"
    }]
}, {
    code: "sl",
    countries: [{
        alpha2: "SI",
        alpha3: "SVN"
    }]
}, {
    code: "so",
    countries: [{
        alpha2: "SO",
        alpha3: "SOM"
    }]
}, {
    code: "st",
    countries: [{
        alpha2: "LS",
        alpha3: "LSO"
    }, {
        alpha2: "ZA",
        alpha3: "ZAF"
    }]
}, {
    code: "es",
    countries: [{
        alpha2: "AR",
        alpha3: "ARG"
    }, {
        alpha2: "BO",
        alpha3: "BOL"
    }, {
        alpha2: "BZ",
        alpha3: "BLZ"
    }, {
        alpha2: "CL",
        alpha3: "CHL"
    }, {
        alpha2: "CO",
        alpha3: "COL"
    }, {
        alpha2: "CR",
        alpha3: "CRI"
    }, {
        alpha2: "CU",
        alpha3: "CUB"
    }, {
        alpha2: "DO",
        alpha3: "DOM"
    }, {
        alpha2: "EC",
        alpha3: "ECU"
    }, {
        alpha2: "EH",
        alpha3: "ESH"
    }, {
        default: true,
        alpha2: "ES",
        alpha3: "ESP"
    }, {
        alpha2: "GQ",
        alpha3: "GNQ"
    }, {
        alpha2: "GT",
        alpha3: "GTM"
    }, {
        alpha2: "GU",
        alpha3: "GUM"
    }, {
        alpha2: "HN",
        alpha3: "HND"
    }, {
        alpha2: "MX",
        alpha3: "MEX"
    }, {
        alpha2: "NI",
        alpha3: "NIC"
    }, {
        alpha2: "PA",
        alpha3: "PAN"
    }, {
        alpha2: "PE",
        alpha3: "PER"
    }, {
        alpha2: "PR",
        alpha3: "PRI"
    }, {
        alpha2: "PY",
        alpha3: "PRY"
    }, {
        alpha2: "SV",
        alpha3: "SLV"
    }, {
        alpha2: "UY",
        alpha3: "URY"
    }, {
        alpha2: "VE",
        alpha3: "VEN"
    }]
}, {
    code: "sw",
    countries: [{
        alpha2: "CD",
        alpha3: "COD"
    }, {
        alpha2: "KE",
        alpha3: "KEN"
    }, {
        alpha2: "TZ",
        alpha3: "TZA"
    }, {
        alpha2: "UG",
        alpha3: "UGA"
    }]
}, {
    code: "ss",
    countries: [{
        alpha2: "SZ",
        alpha3: "SWZ"
    }, {
        alpha2: "ZA",
        alpha3: "ZAF"
    }]
}, {
    code: "sv",
    countries: [{
        alpha2: "AX",
        alpha3: "ALA"
    }, {
        alpha2: "FI",
        alpha3: "FIN"
    }, {
        alpha2: "SE",
        alpha3: "SWE"
    }]
}, {
    code: "ta",
    countries: [{
        alpha2: "LK",
        alpha3: "LKA"
    }, {
        alpha2: "SG",
        alpha3: "SGP"
    }]
}, {
    code: "tg",
    countries: [{
        alpha2: "TJ",
        alpha3: "TJK"
    }]
}, {
    code: "th",
    countries: [{
        alpha2: "TH",
        alpha3: "THA"
    }]
}, {
    code: "ti",
    countries: [{
        alpha2: "ER",
        alpha3: "ERI"
    }]
}, {
    code: "tk",
    countries: [{
        alpha2: "AF",
        alpha3: "AFG"
    }, {
        alpha2: "TM",
        alpha3: "TKM"
    }]
}, {
    code: "tn",
    countries: [{
        alpha2: "BW",
        alpha3: "BWA"
    }, {
        alpha2: "ZA",
        alpha3: "ZAF"
    }]
}, {
    code: "to",
    countries: [{
        alpha2: "TO",
        alpha3: "TON"
    }]
}, {
    code: "tr",
    countries: [{
        alpha2: "CY",
        alpha3: "CYP"
    }, {
        alpha2: "TR",
        alpha3: "TUR"
    }]
}, {
    code: "ts",
    countries: [{
        alpha2: "ZA",
        alpha3: "ZAF"
    }]
}, {
    code: "uk",
    countries: [{
        alpha2: "UA",
        alpha3: "UKR"
    }]
}, {
    code: "ur",
    countries: [{
        alpha2: "PK",
        alpha3: "PAK"
    }, {
        alpha2: "FJ",
        alpha3: "FJI"
    }]
}, {
    code: "uz",
    countries: [{
        alpha2: "AF",
        alpha3: "AFG"
    }, {
        alpha2: "UZ",
        alpha3: "UZB"
    }]
}, {
    code: "ve",
    countries: [{
        alpha2: "ZA",
        alpha3: "ZAF"
    }]
}, {
    code: "vi",
    countries: [{
        alpha2: "VN",
        alpha3: "VNM"
    }]
}, {
    code: "xh",
    countries: [{
        alpha2: "ZA",
        alpha3: "ZAF"
    }]
}]