import { defineStore } from "pinia";
import jwtDecode, { JwtPayload } from "jwt-decode";

import { ErrorCode } from "@tolemac/grpc_web_api/tolemac/pub/common";
import { Orga, User, IUser, IOrga, Company, Invoice } from "@tolemac/grpc_web_api/tolemac/pub/model";
import { IPostChangeMyPassword, IPostUnsubscribe } from "@tolemac/grpc_web_api/tolemac/pub/user";

import { changeLocal } from "@/plugins/i18n";


type RefreshToken = JwtPayload & {
    userId?: number;
}

type Token = RefreshToken & {
    orgaId?: number;
    rights?: number[];
    loginAs?: boolean;
}

type _User = RequireOne<User, 'orga'>

export interface StoreUserState {
    user: _User | null;
    keepFreshToken: number;
    token: {
        access: string;
        accessDecoded: Token;
        refresh: string;
        refreshDecoded: RefreshToken;
    } | null;
    directorySearch: {
        search?: string;
        labelsContact?: number[];
        labelsCompany?: number[];
        statusContact?: ContactStatus[];
        statusCompany?: Company.CompanyProspection[];
        sectorCompany?: Company.ActivitySector[];
        companyIsCustomer?: boolean[];
        and?: boolean;
    } | null;
    invoiceSearch: {
        amountMin?: number;
        amountMax?: number;
        status?: Invoice.InvoiceStatus[]
        dateMin?: number;
        dateMax?: number;
        dueDateMin?: number;
        dueDateMax?: number;
        payDateMin?: number;
        payDateMax?: number;
        reference?: string;
        customer?: string;
    } | null;
    projectSearch: {
        search?: string;
        ref?: string;
        orderNumber?: string;
        companyName?: string;
        amountMin?: number;
        amountMax?: number;
        dateMin?: number;
        dateMax?: number;
    } | null;

    userSearch: {
        email?: string;
        firstname?: string;
        lastname?: string;
        created_min?: number;
        created_max?: number;
        deleted?: boolean;
        deleted_min?: number;
        deleted_max?: number;
        status?: (1 | 2 | 3)[],
        currency?: string
        demo?: boolean;
        customerError?: boolean;
        countContacts_min?: number;
        countContacts_max?: number;
        countCompanies_min?: number;
        countCompanies_max?: number;
        countProjects_min?: number;
        countProjects_max?: number;
    } | null;
}

export enum ContactStatus {
    REF_PROJECT = 1,
    MAIN = 2,
    OTHERS = 3
}

let timeoutRefreshToken: null | number | NodeJS.Timeout = null

export const ID_STORE_ACCOUNT = 'account'

export const useStoreAccount = defineStore(ID_STORE_ACCOUNT, {

    state: (): StoreUserState => {
        const savedSate = localStorage.getItem(ID_STORE_ACCOUNT)
        if (savedSate) return { ...JSON.parse(savedSate), keepFreshToken: 0 }
        return {
            token: null,
            user: null,
            directorySearch: null,
            invoiceSearch: null,
            projectSearch: null,
            userSearch: null,
            keepFreshToken: 0
        }
    },
    getters: {
        search(state) {
            const emptyDirectory = {
                and: false,
                labelsCompany: [],
                labelsContact: [],
                statusContact: [],
                statusCompany: [],
                sectorCompany: [],
                companyIsCustomer: [],
                search: ''
            } satisfies StoreUserState['directorySearch']

            const emptyInvoice = {
                amountMin: undefined,
                amountMax: undefined,
                status: [],
                dateMin: undefined,
                dateMax: undefined,
                dueDateMin: undefined,
                dueDateMax: undefined,
                payDateMin: undefined,
                payDateMax: undefined,
                reference: '',
                customer: ''
            } satisfies StoreUserState['invoiceSearch']

            const emptyProject = {
                search: '',
                ref: '',
                orderNumber: '',
                companyName: '',
                amountMin: undefined,
                amountMax: undefined,
                dateMin: undefined,
                dateMax: undefined
            } satisfies StoreUserState['projectSearch']

            const emptyUsers = {
                email: undefined,
                firstname: undefined,
                lastname: undefined,
                created_min: undefined,
                created_max: undefined,
                deleted: false,
                deleted_min: undefined,
                deleted_max: undefined,
                status: [],
                currency: undefined,
                demo: undefined,
                customerError: undefined,
                countContacts_min: undefined,
                countContacts_max: undefined,
                countCompanies_min: undefined,
                countCompanies_max: undefined,
                countProjects_min: undefined,
                countProjects_max: undefined,
            } satisfies StoreUserState['userSearch']

            return {
                emptyDirectory,
                directory: {
                    search: state.directorySearch?.search ?? emptyDirectory.search,
                    labelsContact: state.directorySearch?.labelsContact ?? emptyDirectory.labelsContact,
                    labelsCompany: state.directorySearch?.labelsCompany ?? emptyDirectory.labelsCompany,
                    statusContact: state.directorySearch?.statusContact ?? emptyDirectory.statusContact,
                    statusCompany: state.directorySearch?.statusCompany ?? emptyDirectory.statusCompany,
                    sectorCompany: state.directorySearch?.sectorCompany ?? emptyDirectory.sectorCompany,
                    companyIsCustomer: state.directorySearch?.companyIsCustomer ?? emptyDirectory.companyIsCustomer,
                    and: state.directorySearch?.and ?? emptyDirectory.and,
                },
                emptyInvoice,
                invoice: {
                    amountMin: state.invoiceSearch?.amountMin ?? emptyInvoice.amountMin,
                    amountMax: state.invoiceSearch?.amountMax ?? emptyInvoice.amountMax,
                    status: state.invoiceSearch?.status ?? emptyInvoice.status,
                    dateMin: state.invoiceSearch?.dateMin ?? emptyInvoice.dateMin,
                    dateMax: state.invoiceSearch?.dateMax ?? emptyInvoice.dateMax,
                    dueDateMin: state.invoiceSearch?.dueDateMin ?? emptyInvoice.dueDateMin,
                    dueDateMax: state.invoiceSearch?.dueDateMax ?? emptyInvoice.dueDateMax,
                    payDateMin: state.invoiceSearch?.payDateMin ?? emptyInvoice.payDateMin,
                    payDateMax: state.invoiceSearch?.payDateMax ?? emptyInvoice.payDateMax,
                    reference: state.invoiceSearch?.reference ?? emptyInvoice.reference,
                    customer: state.invoiceSearch?.customer ?? emptyInvoice.customer
                },
                emptyProject,
                project: {
                    search: state.projectSearch?.search ?? emptyProject.search,
                    ref: state.projectSearch?.ref ?? emptyProject.ref,
                    orderNumber: state.projectSearch?.orderNumber ?? emptyProject.orderNumber,
                    companyName: state.projectSearch?.companyName ?? emptyProject.companyName,
                    amountMin: state.projectSearch?.amountMin ?? emptyProject.amountMin,
                    amountMax: state.projectSearch?.amountMax ?? emptyProject.amountMax,
                    dateMin: state.projectSearch?.dateMin ?? emptyProject.dateMin,
                    dateMax: state.projectSearch?.dateMax ?? emptyProject.dateMax
                },
                // admin
                emptyUsers,
                users: {
                    email: state.userSearch?.email ?? emptyUsers.email,
                    firstname: state.userSearch?.firstname ?? emptyUsers.firstname,
                    lastname: state.userSearch?.lastname ?? emptyUsers.lastname,
                    created_min: state.userSearch?.created_min ?? emptyUsers.created_min,
                    created_max: state.userSearch?.created_max ?? emptyUsers.created_max,
                    deleted: state.userSearch?.deleted ?? emptyUsers.deleted,
                    deleted_min: state.userSearch?.deleted_min ?? emptyUsers.deleted_min,
                    deleted_max: state.userSearch?.deleted_max ?? emptyUsers.deleted_max,
                    status: state.userSearch?.status ?? emptyUsers.status,
                    currency: state.userSearch?.currency ?? emptyUsers.currency,
                    demo: state.userSearch?.demo ?? emptyUsers.demo,
                    customerError: state.userSearch?.customerError ?? emptyUsers.customerError,
                    countContacts_min: state.userSearch?.countContacts_min ?? emptyUsers.countContacts_min,
                    countContacts_max: state.userSearch?.countContacts_max ?? emptyUsers.countContacts_max,
                    countCompanies_min: state.userSearch?.countCompanies_min ?? emptyUsers.countCompanies_min,
                    countCompanies_max: state.userSearch?.countCompanies_max ?? emptyUsers.countCompanies_max,
                    countProjects_min: state.userSearch?.countProjects_min ?? emptyUsers.countProjects_min,
                    countProjects_max: state.userSearch?.countProjects_max ?? emptyUsers.countProjects_max
                }
            }
        },

        isAdmin(state) {
            return (state.token?.accessDecoded.rights || []).indexOf(User.UserRight.ADMIN) !== -1
        },

        isAdminLoginAs(state) {
            return state.token?.accessDecoded.loginAs === true
        },

        isDevelopper(state) {
            return (state.token?.accessDecoded.rights || []).indexOf(User.UserRight.DEVELOPPER) !== -1
        },

        currentLang(state) {
            return state.user?.orga?.localize?.lang || 'fr'
        }
    },

    actions: {

        // // Mutation (sync) // //

        mutOrga(payload: Orga) {
            if (!this.$state.user) throw new Error('mutation orga with user undefined')
            this.$state.user.orga = payload
        },

        mutUser(payload: _User | null) {
            this.$state.user = payload
        },

        mutToken(payload: Partial<StoreUserState['token']> | null) {
            const newToken: Partial<NotNull<StoreUserState['token']>> = JSON.parse(JSON.stringify(this.$state.token || {}))

            if (payload?.access) {
                newToken.access = payload.access
                newToken.accessDecoded = jwtDecode<JwtPayload>(payload.access.replace(/^Bearer /, ''))
            } else if (this.$state.token) {
                newToken.access = this.$state.token.access
                newToken.accessDecoded = this.$state.token.accessDecoded
            }

            if (payload?.refresh) {
                newToken.refresh = payload.refresh
                newToken.refreshDecoded = jwtDecode<JwtPayload>(payload.refresh.replace(/^Bearer /, ''))
            } else if (this.$state.token) {
                newToken.refresh = this.$state.token.refresh
                newToken.refreshDecoded = this.$state.token.refreshDecoded
            }

            if (!newToken.access || !newToken.refresh) throw {
                message: 'jwt token empty',
                code: ErrorCode.UNAUTHORIZED_LOGOUT
            }

            this.$state.token = newToken as StoreUserState['token']

            if (import.meta.env.DEV)
                this.$cookies.set(
                    'access_token',
                    this.$state.token?.access,
                    this.$state.token?.accessDecoded?.exp ? new Date(this.$state.token.accessDecoded.exp * 1000) : undefined
                )
            else
                this.$cookies.set(
                    'access_token',
                    this.$state.token?.access,
                    this.$state.token?.accessDecoded?.exp ? new Date(this.$state.token.accessDecoded.exp * 1000) : undefined,
                    '/',
                    `.${window.location.host}`,
                    true,
                    'Strict'
                )
        },

        mutDirectorySearch(payload: StoreUserState['directorySearch'] | null) {
            this.$state.directorySearch = payload
        },

        mutInvoiceSearch(payload: StoreUserState['invoiceSearch'] | null) {
            this.$state.invoiceSearch = payload
        },

        mutUserSearch(payload: StoreUserState['userSearch'] | null) {
            this.$state.userSearch = payload
        },

        mutKeepFreshToken(k: boolean) {
            const self = this
            function reloadToken() {
                if (self.$state.token?.accessDecoded.exp) {
                    const remainingTime = (self.$state.token.accessDecoded.exp * 1000) - Date.now() + 100
                    timeoutRefreshToken = setTimeout(
                        async () => {
                            try {
                                await self.$api.orga.ping({})
                                setTimeout(reloadToken, 200)
                            } catch (e: any) {
                                /** nop */
                            }
                        },
                        remainingTime
                    )
                }
            }

            this.$state.keepFreshToken = k ? this.$state.keepFreshToken + 1 : Math.max(this.$state.keepFreshToken - 1, 0)

            if (this.$state.keepFreshToken === 1 || this.$state.keepFreshToken === 0) {
                if (timeoutRefreshToken) {
                    clearTimeout(timeoutRefreshToken)
                    timeoutRefreshToken = null
                }
                if (this.$state.keepFreshToken) {
                    reloadToken()
                }
            }

        },

        // // Action (sync/async) // //

        async init() {
            if (this.$state.token?.access || this.$state.token?.refresh)
                try {
                    await this.getme()
                    return true
                } catch (e) { /** nop */ }
            return false
        },

        // user

        async getme() {
            try {
                if (this.$state.token?.accessDecoded.loginAs)
                    this.$state.user = await this.$api.user.getme({}) as _User
                else
                    this.$state.user = await this.$api.user.getme({}, { metadata: { refreshToken: 'true' } }) as _User

                if (this.$state.user?.orga?.localize)
                    changeLocal({ ...this.$state.user.orga.localize, lang: this.currentLang })
            } catch (e: any) {
                console.error(e)
                this.logout()
            }
        },

        async login(payload: { email: string; password: string, to?: string }) {
            this.reset();
            const account = await this.$api.user.login({
                email: payload.email,
                password: payload.password
            })
            this.mutUser(account as _User)
            await this.$router.push(payload.to || '/');
        },

        async logout() {
            this.reset();
            this.$cookies.remove('access_token')
            this.$router.push('/login')
        },

        async forceExpAccesToken() {
            if (this.$state.token?.accessDecoded.exp)
                this.$state.token.accessDecoded.exp = undefined
            this.$cookies.remove('access_token')
        },

        async signup(payload: { email: string; password: string }) {
            await this.$api.user.signup({
                email: payload.email,
                password: payload.password
            })
            await this.login(payload)
        },

        async changeMyPassword(payload: IPostChangeMyPassword) {
            await this.$api.user.changemypassword(payload)
        },

        async unsubscribe(payload: IPostUnsubscribe) {
            await this.$api.user.unsubscribe(payload, { metadata: { skipErrorCode: '401' } })
        },

        async updateUser(payload: IUser) {
            await this.$api.user.update(payload)
            this.mutUser(await this.$api.user.getme({}) as _User)
            return this.$state.user
        },

        haveRole(payload: User.UserRight[]) {
            const myRights = this.$state.token?.accessDecoded.rights || []
            return payload.reduce(
                (tot, pR) => tot && myRights.indexOf(pR) !== -1
                , true
            )
        },

        // orga

        async refreshOrga() {
            this.mutOrga(await this.$api.orga.getmine({}))
            if (this.$state.user?.orga?.localize)
                changeLocal({ ...this.$state.user.orga.localize, lang: this.currentLang })
            return this.$state.user!.orga!
        },

        async updateOrga(payload: IOrga) {
            await this.$api.orga.update({ ...payload, id: this.$state.user!.orga!.id })
            return this.refreshOrga()
        },

        // 

        async test() {
            await this.$api.test.subscribe({ id: 'test OK' })
            await this.$api.test.subscribe({ id: 'testKO' })
        }
    }

})

export type StoreAccount = ReturnType<typeof useStoreAccount>
