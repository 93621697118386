<template>
  <div class="my-1 relative">
    <Combobox v-model="model" :multiple="$props.multiple" :nullable="!$props.disabled && $props.nullable"
      :disabled="$props.disabled" :by="optsCompare">
      <div class="relative mt-1" v-hide-label-directive="'combobox' + $.uid">

        <ComboboxLabel :for="'combobox' + $.uid" ref="label" class="t-input-label">
          <span>{{ $props.placeholder }}</span>
          <span class="text-gray-400 text-xs" v-if="validation && validation.required">&nbsp;&#8727;&nbsp;</span>
        </ComboboxLabel>

        <div class="relative w-full bg-white border-2 border-gray-200 rounded-lg text-left cursor-default sm:text-sm"
          :class="[(validation && validation.$errors.length) ? 'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500' : '']">

          <ComboboxInput :id="'combobox' + $.uid" ref="refInput"
            class="w-full py-2.5 border-none pl-3 rounded-lg text-sm leading-5 text-gray-900 focus:ring-0"
            :displayValue="optsLabel" v-model="query" :placeholder="'      ' + placeholder" :disabled="$props.disabled"
            @change="searchDebounce" @focusin="openOn" />
          <span :for="'notcombobox' + $.uid" v-if="noValue"
            class="absolute font-material-symbols-outlined text-gray-400 text-lg"
            style="bottom: 6px; left: 10px;">&#59574;</span>

          <ComboboxButton ref="refButtton" class="absolute inset-y-0 rounded-r-lg right-0 flex items-center pr-2 bg-white"
            @click="search(true)">
            <t-icon-arrow-path v-if="searching" class="animate-spin h-5 w-5 text-gray-400 bg-white z-50"
              aria-hidden="true" />
            <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
          </ComboboxButton>

          <button v-if="!$props.disabled && $props.nullable && !noValue"
            class="absolute inset-y-0 right-5 flex items-center pr-2"
            @click.prevent="$props.multiple ? model = [] : model = undefined">
            <t-icon-x-mark class="h-4 w-4 text-gray-400" aria-hidden="true" />
          </button>
        </div>

        <TransitionRoot @after-leave="query = ''" @before-enter="isOpen = true" @before-leave="isOpen = false">
          <ComboboxOptions :class="{ 'hidden': hideDuringclose, 'bottom-full': top }"
            class="absolute z-20 m-1 w-full py-1 rounded-lg bg-white text-base ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            <div v-if="opts.length === 0" class="relative cursor-default select-none py-2 px-4 text-gray-700">
              {{ $t('components.combobox.no_result') }}
            </div>

            <ComboboxOption v-for="opt in opts" as="template" :key="optsKey(opt)" :value="opt"
              :disabled="!(!$props.optionsDisabled || !$props.optionsDisabled(opt))" v-slot="{ selected, active }">
              <li class="relative cursor-default select-none py-2 px-3"
                :class="active ? 'bg-ocean text-white' : 'text-gray-900'">
                <slot :opt="opt" :selected="selected" :active="active" />
                <template v-if="!$slots.default">
                  <span class="block truncate" :class="selected ? 'font-medium' : 'font-normal'">
                    {{ optToLabel(opt) }}
                  </span>
                  <span v-if="selected" class="absolute inset-y-0 right-0 flex items-center pr-3"
                    :class="active ? 'text-white' : 'text-gray-900'">
                    <t-icon-check class="h-5 w-5" aria-hidden="true" />
                  </span>
                </template>
              </li>
            </ComboboxOption>

            <ComboboxOption as="template" v-if="'end' in $slots" key="slotend" v-slot="{ active }">
              <li class="select-none">
                <slot name="end" :active="active" />
              </li>
            </ComboboxOption>

            <ComboboxOption as="template" v-if="'end1' in $slots" key="slotend1" v-slot="{ active }">
              <li class="select-none">
                <slot name="end1" :active="active" />
              </li>
            </ComboboxOption>

          </ComboboxOptions>
        </TransitionRoot>

      </div>
    </Combobox>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch, PropType, SlotsType } from "vue"
import {
  Combobox,
  ComboboxLabel,
  ComboboxInput,
  ComboboxButton,
  ComboboxOptions,
  ComboboxOption,
  TransitionRoot,
} from '@headlessui/vue'
import { ChevronUpDownIcon } from '@heroicons/vue/24/solid'

import { ValidationOf } from "@/lib/vuelidate.type";
import { Null } from "@/util";

import { debounce } from "@/composition/debounce";
import { hideLabelDirective } from '@/directives/hide-label'

export default defineComponent({
  name: "t-combobox",
  components: {
    Combobox, ComboboxInput, ComboboxOptions, ComboboxOption, ComboboxButton, ComboboxLabel, TransitionRoot,
    ChevronUpDownIcon
  },
  directives: { hideLabelDirective },
  props: {
    modelValue: [Object, String, Boolean, Array, Number, Null],

    /** property name or Function */
    optionsLabel: {
      type: [String, Function] as PropType<string> | PropType<(opt: any) => any>,
      default: (opt: string) => opt
    },

    multiple: Boolean,
    nullable: Boolean,
    disabled: Boolean,

    /** set to null to execute search on mount or reload data */
    count: [Number, Null],

    optionsKey: { type: [String, Function], default: () => 'id' },
    optionsSearch: Function as PropType<(query: string) => (any[] | Promise<any[]>)>,
    optionsDisabled: Function as PropType<(opt: any) => boolean>,

    placeholder: String,

    top: Boolean,

    validation: Object as PropType<ValidationOf<unknown, { required?: any }>>
  },
  emits: ['update:modelValue', 'change', 'update:count'],
  slots: Object as SlotsType<{
    default: { opt: any; selected: boolean; active: boolean },
    end: { active: boolean },
    end1: { active: boolean },
  }>,
  setup(props, { emit }) {

    const optToLabel = (a: any) => {
      if (a === undefined || a === null)
        return ''
      if (typeof props.optionsLabel === 'string')
        return a[props.optionsLabel]
      return props.optionsLabel(a)
    }

    const searching = ref(false);
    const isOpen = ref(false)
    const opts = ref<any[]>([])
    const query = ref('')
    const top = ref(props.top === true)

    const refButtton = ref<{ $el: HTMLButtonElement }>()
    const refInput = ref<{ $el: HTMLInputElement }>()

    let querySaved: string | null;
    async function search($event?: EventWithTarget<HTMLInputElement> | true, force?: true) {
      if (!props.optionsSearch) return

      if ($event === true) {
        if (querySaved === undefined)
          query.value = refInput.value?.$el.value || ''
        else if (!force)
          return;
      } else {
        if (!isOpen.value && !force) return;
        query.value = $event?.target?.value || ''
      }

      if (!force && querySaved === query.value) return;

      searching.value = true;
      opts.value = await props.optionsSearch(query.value)
      querySaved = query.value
      searching.value = false;
      emit('update:count', opts.value.length)
    }

    const openOn = ($event: FocusEvent) => {
      if (isOpen.value) return
      if ($event.target) ($event.target as HTMLInputElement)?.select();
      if ($event.target && props.top !== true) {
        const t = $event.target as HTMLInputElement
        if (t)
          top.value = (t.getClientRects()[0].bottom || 0) + 150 > window.innerHeight
      }
      refButtton.value?.$el?.click();
    }

    const hideDuringclose = ref(false)
    const forceClose = () => {
      hideDuringclose.value = true
      setTimeout(() => {
        refButtton.value?.$el?.click()
        setTimeout(() => {
          refInput.value?.$el?.blur()
          setTimeout(() => {
            hideDuringclose.value = false
          })
        })
      })
    }

    const model = computed({
      get() {
        return props.modelValue || null
      },
      set(val: any) {
        querySaved = null; // if value changed, force refresh options

        emit('update:modelValue', val ? val : null);
        emit('change', val ? val : null);

        if (!props.multiple) forceClose()
      }
    })

    watch(() => props.count, c => { if (c === null) search(true, true) }, { immediate: true })

    return {
      isOpen,
      model,
      searching,
      noValue: computed(() => props.multiple ? ((model.value as Array<any>).length === 0) : !model.value),

      opts,
      optToLabel,
      optsLabel: (val: any | any[]) => Array.isArray(val) ? val.map(v => optToLabel(v)).join(', ') : optToLabel(val),

      optsKey: typeof props.optionsKey === 'string' ?
        (a: any) => a && a[props.optionsKey as string]
        :
        props.optionsKey,

      optsCompare: typeof props.optionsKey === 'string' ?
        props.optionsKey
        :
        (a: any, b: any) =>
          ((!a || !b) && a == b)
          ||
          (props.optionsKey as typeof Function)(a) === (props.optionsKey as typeof Function)(b),


      query,
      search,
      searchDebounce: debounce(search),

      refButtton,
      refInput,
      hideDuringclose,
      openOn,
      top
    }
  }
});

</script>

<style>
@font-face {
  font-family: 'Material Symbols Outlined';
  font-style: normal;
  src: url('/assets/MaterialSymbolsOutlined.partial.woff2') format('woff2'),
       url('/assets/MaterialSymbolsOutlined.partial.woff') format('woff');
}
.font-material-symbols-outlined {
  font-family: 'Material Symbols Outlined';
}
</style>