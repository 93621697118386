import { unref } from 'vue'
import { ValidationRuleExternal, ValidationRuleBasic, ValidatorFn } from "../lib/vuelidate.type";

import { i18n } from "@/plugins/i18n"
import { emailRegex, urlRegex } from '@/constant';

type WithI18nMessageOpt = {
    /**
     * i18n key to build error message translated
     *  ex: 'a.b' with json locale { "a": { "b": "hello {name}" } }
     */
    i18nKey: string | ((props: any) => string),
    /**
     * i18n params to build error message translated
     *  ex: { name: 'John' }
     */
    i18nParams?: Record<string, any>,
} & Pick<ValidationRuleBasic, 'jsonQueries'>

/**
 * @param validator ValidatorFn return true if valid
 */
export function withI18nMessage<VALUE = unknown, PARENT = unknown, ROOT = unknown>(
    validator: ValidationRuleBasic<VALUE, PARENT, ROOT> | ValidatorFn<VALUE, PARENT, ROOT>,
    opt: WithI18nMessageOpt['i18nKey'] | WithI18nMessageOpt
) {
    const i18nParams = (typeof opt === "object") ? opt.i18nParams : undefined
    const jsonQueries = (typeof opt === "object") ? opt.jsonQueries : undefined

    if (typeof validator === 'function') validator = { $validator: validator }

    validator.$message = (props?: any) => {

        let i18nKey = (typeof opt === "object") ? opt.i18nKey : opt

        i18nKey = typeof i18nKey === 'function' ? i18nKey(props) : i18nKey
        const _i18nParams = i18nParams === undefined ? undefined :
            typeof i18nParams !== 'function' ? i18nParams :
                i18nParams({
                    model: props.$model,
                    property: props.$property,
                    pending: props.$pending,
                    invalid: props.$invalid,
                    response: props.$response,
                    validator: props.$validator,
                    propertyPath: props.$propertyPath,
                    ...props.$params
                })
        return i18n.t(i18nKey, _i18nParams)
    }

    if (jsonQueries)
        validator.jsonQueries = jsonQueries;

    if (i18nParams)
        validator.$params = i18nParams;

    return validator as ValidationRuleBasic<VALUE | undefined, PARENT, ROOT>
}

/**
 * Set $invalid to change state of this validator
 * 
 * @param i18nKey i18n key
 * @param i18nParams i18n params
 */
export function externalWithI18nMessage(
    i18nKey: string | ((props: any) => string),
    i18nParams?: Record<string, any> | ((props: any) => Record<string, any>)
) {

    return {
        $external: true,
        $message: (props?: any) => i18n.t(
            typeof i18nKey === 'function' ? i18nKey(props) : i18nKey,
            i18nParams ? typeof i18nParams === 'function' ? i18nParams({
                model: props.$model,
                property: props.$property,
                pending: props.$pending,
                invalid: props.$invalid,
                response: props.$response,
                validator: props.$validator,
                propertyPath: props.$propertyPath,
                ...props.$params
            }) : i18nParams : {})
    } as ValidationRuleExternal
}

/**
 * not valid if:
 *  - empty string
 *  - empty array
 *  - undefined or null
 *  - date invalid
 *  - object empty
 */
export const required = withI18nMessage((value: any) => {
    value = unref(value)
    if (typeof value === 'string') value = value.trim()
    if (Array.isArray(value)) return !!value.length
    if (value === undefined || value === null) return false
    if (value === false) return true
    if (value instanceof Date) return !isNaN(value.getTime())
    if (typeof value === 'object') {
        for (const _ in value) return true
        return false
    }
    return !!String(value).length
}, "validations.required");

export const requiredNumber = withI18nMessage<any>(value => !!value || `${value}` !== '0', 'validations.required');

export const isTrue = withI18nMessage<any>(value => value === undefined || value === true, 'validations.required');

export const email = withI18nMessage((value?: string) => !value || emailRegex.test(value), "validations.email");

export const url = withI18nMessage((value?: string) => !value || urlRegex.test(value), "validations.url");

export const fileMaxSize1Mo = withI18nMessage<File | string>(value => !(value instanceof File) || value.size < 1000000, { i18nKey: 'validations.file_max_size', i18nParams: { size: '1Mo' } });

export const fileIsPicture = withI18nMessage<File | string>(value => !(value instanceof File) || ['image/gif', 'image/jpeg', 'image/png'].indexOf(value.type) !== -1, { i18nKey: 'validations.file_is_picture', i18nParams: { types: 'gif, jpeg, png' } });

export const phone = withI18nMessage((value?: string) => !value || /^[0-9\-+() ]*$/.test(value), { i18nKey: 'validations.caracters_unauthorized', i18nParams: { caracters: "+ ( ) - et les chiffres de 0 à 9" } });

// array
export const minLength = (length: number, i18nKey = "validations.min_length") => withI18nMessage((value?: string) => !value || value.length >= length, { i18nKey, i18nParams: { length } });
export const maxLength = (length: number, i18nKey = "validations.max_length") => withI18nMessage((value?: string) => !value || value.length <= length, { i18nKey, i18nParams: { length } });

// number
export const maxValue = (max: number, i18nKey = "validations.max_value") => withI18nMessage((value?: number) => value === undefined || value === null || +value <= +max, { i18nKey, i18nParams: { max } });
export const minValue = (min: number, i18nKey = "validations.min_value") => withI18nMessage((value?: number) => value === undefined || value === null || +value >= +min, { i18nKey, i18nParams: { min } });

// date
export const minDate = (_minDate: number, i18nParams: { date_label: string; min_date: number }, i18nKey = "validations.min_date") => withI18nMessage<number>((_date) => !_date || _date >= _minDate, { i18nKey, i18nParams })
export const maxDate = (_maxDate: number, i18nParams: { date_label: string; max_date: number }, i18nKey = "validations.max_date") => withI18nMessage<number>((_date) => !_date || _date <= _maxDate, { i18nKey, i18nParams })
export const betweenDate = (_minDate: number, _maxDate: number, i18nParams: { min_label: string; min_date: number; max_label: string; max_date: number }, i18nKey = "validations.between_date") => withI18nMessage<number>((_date) => !_date || (_date >= _minDate && _date <= _maxDate), { i18nKey, i18nParams })

export const sameAs = <T>(val: () => T, message: string) => withI18nMessage((value?: T) => val() === value, message)

export const regexp = (predicate: RegExp, i18nKey: string) => withI18nMessage<string>(value => !value || predicate.test(value), { i18nKey });

type ValidatorRep = { $message: string; $params?: any }

type CtxValidate<T, P, R> = Parameters<ValidatorFn<T, P, R>>[1] & { value?: T }

/**
 * dynamic error message key + param
 * 
 * @param rule 
 * @param params 
 * @returns 
 */
export function validate<T = unknown, P = unknown, R = unknown>(rule: (ctx: CtxValidate<T, P, R>) => (undefined | void | false | string | ValidatorRep), params?: Record<string, any>) {
    let message: string | undefined;

    return {
        $validator(a, ctx) {
            const rep = rule({ value: a, ...ctx } as CtxValidate<T, P, R>)
            if (typeof rep === 'object') {
                message = rep.$message;
                params = rep.$params || params
            } else if (typeof rep === 'string') {
                message = rep
            }
            return !rep
        },
        $message() {
            if (message)
                return params ? i18n.t(message, params) : i18n.t(message)
        }
    } as ValidationRuleBasic<T>
}
