import { FunctionDirective } from "vue"

export const hideLabelDirective: FunctionDirective<HTMLElement> = (el, args) => {
    const input = el.querySelector(`#${args.value}`) as HTMLInputElement

    const labels = el.querySelectorAll(`*[for=${args.value}]`)
    const labelsInverse = el.querySelectorAll(`*[for=not${args.value}]`)

    function hide(_hide: boolean, _els: Element[]) {
        for (const _el of _els) {
            const _elToHide = _el.childElementCount > 1 ? _el.children[0]! : _el
            if (_hide)
                _elToHide.classList.add('hidden-important')
            else
                _elToHide.classList.remove('hidden-important')
        }
    }

    let empty: boolean | undefined = undefined
    function hideLabelsIfEmpty(_value: any) {
        const _empty = !!_value
        if (_empty !== empty) {
            hide(!_empty, [...labels.values()])
            hide(_empty, [...labelsInverse.values()])
        }
        empty = _empty
    }

    hideLabelsIfEmpty(input?.value)

    if (input)
        input.addEventListener('input', (e: any) => hideLabelsIfEmpty(e?.target?.value))
}