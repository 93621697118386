import { ILocalization } from "@tolemac/grpc_web_api/tolemac/pub/model";

import { CURRENCIES_FILTERED, DEFAULT_CURRENCY } from './cst.currency'
import { TIMEZONES, DEFAULT_TIMEZONE } from './cst.timezone'
import { DATEFNS_FORMATS_DATE, DATEFNS_FORMATS_NUMBER, DEFAULT_DATEFNS_LOCALE } from './cst.datefns.locales'

export const DEFAULT_LANG = 'fr' as const
export const AVAILABLE_LANGS = [DEFAULT_LANG] as const
export const NEXT_AVAILABLE_LANGS = ['en'] as const

export type AvailableLang = typeof AVAILABLE_LANGS[number]

export function i18nConf(loc: Partial<ILocalization> = {}) {
    const browserDateFormat = Intl.DateTimeFormat().resolvedOptions()
    const browserNumberFormat = Intl.NumberFormat().resolvedOptions()
    const browserLang = navigator.language.substring(0, 2)

    if (!loc.lang)
        loc.lang = browserLang
    if (!loc.lang || AVAILABLE_LANGS.indexOf(loc.lang as any) === -1)
        loc.lang = DEFAULT_LANG

    if (!loc.currency)
        loc.currency = browserNumberFormat.currency
    if (!loc.currency || CURRENCIES_FILTERED.indexOf(loc.currency) === -1)
        loc.currency = DEFAULT_CURRENCY

    if (!loc.lang)
        loc.lang = browserLang
    if (!loc.lang || AVAILABLE_LANGS.indexOf(loc.lang as any) === -1)
        loc.lang = DEFAULT_LANG

    if (!loc.date)
        loc.date = browserDateFormat.locale
    if (!loc.date || DATEFNS_FORMATS_DATE.indexOf(loc.date as any) === -1)
        if (DATEFNS_FORMATS_DATE.indexOf(loc.date.substring(0, 2) as any) === -1)
            loc.date = DEFAULT_DATEFNS_LOCALE
        else
            loc.date = loc.date.substring(0, 2)

    if (!loc.time)
        loc.time = browserDateFormat.locale
    if (!loc.time || DATEFNS_FORMATS_DATE.indexOf(loc.time as any) === -1)
        if (DATEFNS_FORMATS_DATE.indexOf(loc.time.substring(0, 2) as any) === -1)
            loc.time = DEFAULT_DATEFNS_LOCALE
        else
            loc.time = loc.time.substring(0, 2)

    if (!loc.timezone)
        loc.timezone = browserDateFormat.timeZone
    if (!loc.timezone || TIMEZONES.indexOf(loc.timezone) === -1)
        loc.timezone = DEFAULT_TIMEZONE

    if (!loc.number)
        loc.number = browserNumberFormat.locale
    if (!loc.number || DATEFNS_FORMATS_NUMBER.indexOf(loc.number as any) === -1)
        if (DATEFNS_FORMATS_NUMBER.indexOf(loc.number.substring(0, 2) as any) === -1)
            loc.number = DEFAULT_DATEFNS_LOCALE
        else
            loc.number = loc.number.substring(0, 2)

    return loc as unknown as TLocalization
}

export type DateFnsLocaleKey = typeof DATEFNS_FORMATS_DATE[number];

export type NumberLocal = typeof DATEFNS_FORMATS_NUMBER[number];

export type TOLEMAC_LANGS = typeof AVAILABLE_LANGS[number]

export type TLocalization = {
    /** ex: "fr" */
    lang: TOLEMAC_LANGS
    /** ex: "fr" */
    date: DateFnsLocaleKey;
    /** ex: "fr" */
    time: DateFnsLocaleKey;
    /** ex: "fr" */
    number: NumberLocal;
    /** ex: "EUR" */
    currency: string;
    /** ex: "Europe/Paris" */
    timezone: string;
}
